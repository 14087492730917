import React from "react";
import { Link, useLocation } from "react-router-dom";
import { all_routes } from "../router/all_routes";
import ImageWithBasePath from "../../core/data/img/ImageWithBasePath";

const Header = () => {
  const routes = all_routes;
  const location = useLocation();

  const header = [
    {
      tittle: "Inicio",
      showAsTab: false,
      separateRoute: true,
      routes: routes.home,
      hasSubRoute: false,
      showSubRoute: false,
    },
    // {
    //   tittle: "Listados",
    //   showAsTab: false,
    //   separateRoute: false,
    //   menu: [
    //     {
    //       menuValue: "Lista Grid",
    //       routes: routes.listinggrid,
    //       hasSubRoute: false,
    //       showSubRoute: false,
    //       subMenus: [],
    //     },
    //     {
    //       menuValue: "Lista",
    //       routes: routes.listinglist,
    //       hasSubRoute: false,
    //       showSubRoute: false,
    //       subMenus: [],
    //     },
    //     {
    //       menuValue: "Lista Detalles",
    //       routes: routes.listingdetails,
    //       hasSubRoute: false,
    //       showSubRoute: false,
    //       subMenus: [],
    //     },
    //   ],
    // },
    {
      tittle: "Nosotros",
      showAsTab: false,
      separateRoute: true,
      routes: routes.aboutus,
      hasSubRoute: false,
      showSubRoute: false,
          
    },
    {
      tittle: "Precios",
      showAsTab: false,
      separateRoute: true,
      routes: routes.pricing,
      hasSubRoute: false,
      showSubRoute: false,
          
    },

    // {
    //   tittle: "Paginas",
    //   showAsTab: false,
    //   separateRoute: false,
    //   menu: [
    //     {
    //       menuValue: "Nosotros",
    //       routes: routes.aboutus,
    //       hasSubRoute: false,
    //       showSubRoute: false,
    //       subMenus: [],
    //     },
    //     {
    //       menuValue: "Authenticación",
    //       hasSubRoute: true,
    //       showSubRoute: true,
    //       subMenus: [
    //         {
    //           menuValue: "Signup",
    //           routes: routes.signup,
    //           hasSubRoute: true,
    //           showSubRoute: true,
    //           subMenus: [],
    //         },
    //         {
    //           menuValue: "Signin",
    //           routes: routes.login,
    //           hasSubRoute: true,
    //           showSubRoute: true,
    //           subMenus: [],
    //         },
    //         {
    //           menuValue: "Forgot Password",
    //           routes: routes.forgotpassword,
    //           hasSubRoute: true,
    //           showSubRoute: true,
    //           subMenus: [],
    //         },
    //         {
    //           menuValue: "Resetear Contraseña",
    //           routes: routes.resetpassword,
    //           hasSubRoute: true,
    //           showSubRoute: true,
    //           subMenus: [],
    //         },
    //       ],
    //     },
    //     {
    //       menuValue: "Reservaciones",
    //       hasSubRoute: true,
    //       showSubRoute: false,
    //       subMenus: [
    //         {
    //           menuValue: "Pago de reservas",
    //           routes: routes.bookingcheckout,
    //           hasSubRoute: false,
    //           showSubRoute: false,
    //           subMenus: [],
    //         },
    //         {
    //           menuValue: "Reserva",
    //           routes: routes.booking,
    //           hasSubRoute: false,
    //           showSubRoute: false,
    //           subMenus: [],
    //         },
    //         {
    //           menuValue: "Detalle reserva",
    //           routes: routes.invoice,
    //           hasSubRoute: false,
    //           showSubRoute: false,
    //           subMenus: [],
    //         },
    //       ],
    //     },
    //     {
    //       menuValue: "Página Error ",
    //       hasSubRoute: true,
    //       showSubRoute: false,
    //       subMenus: [
    //         {
    //           menuValue: "404 Error",
    //           routes: routes.error404,
    //           hasSubRoute: false,
    //           showSubRoute: false,
    //           subMenus: [],
    //         },
    //         {
    //           menuValue: "500 Error",
    //           routes: routes.error500,
    //           hasSubRoute: false,
    //           showSubRoute: false,
    //           subMenus: [],
    //         },
    //       ],
    //     },
    //     {
    //       menuValue: "Precios",
    //       routes: routes.pricing,
    //       hasSubRoute: false,
    //       showSubRoute: false,
    //     },
    //     {
    //       menuValue: "Preguntas y respuestas",
    //       routes: routes.faq,
    //       hasSubRoute: false,
    //       showSubRoute: false,
    //     },
    //     {
    //       menuValue: "Galería",
    //       routes: routes.gallery,
    //       hasSubRoute: false,
    //       showSubRoute: false,
    //     },
    //     {
    //       menuValue: "Nuestro",
    //       routes: routes.ourteam,
    //       hasSubRoute: false,
    //       showSubRoute: false,
    //     },
    //     {
    //       menuValue: "Testimonios",
    //       routes: routes.testimonial,
    //       hasSubRoute: false,
    //       showSubRoute: false,
    //     },
    //     {
    //       menuValue: "Términos & Condiciones",
    //       routes: routes.termsconditions,
    //       hasSubRoute: false,
    //       showSubRoute: false,
    //     },
    //     {
    //       menuValue: "Política de privacidad",
    //       routes: routes.privacypolicy,
    //       hasSubRoute: false,
    //       showSubRoute: false,
    //     },
    //     {
    //       menuValue: "Mantenimiento",
    //       routes: routes.maintenance,
    //       hasSubRoute: false,
    //       showSubRoute: false,
    //     },
    //     {
    //       menuValue: "Próximamente",
    //       routes: routes.comingsoon,
    //       hasSubRoute: false,
    //       showSubRoute: false,
    //     },
    //   ],
    // },
    // {
    //   tittle: "Blog",
    //   showAsTab: false,
    //   separateRoute: false,
    //   menu: [
    //     {
    //       menuValue: "Lista",
    //       routes: routes.bloglist,
    //       hasSubRoute: false,
    //       showSubRoute: false,
    //       subMenus: [],
    //     },
    //     {
    //       menuValue: "Cuadricula",
    //       routes: routes.bloggrid,
    //       hasSubRoute: false,
    //       showSubRoute: false,
    //       subMenus: [],
    //     },
    //     {
    //       menuValue: "Detalles",
    //       routes: routes.blogdetails,
    //       hasSubRoute: false,
    //       showSubRoute: false,
    //       subMenus: [],
    //     },
    //   ],
    // },
    {
      tittle: "Contacto",
      showAsTab: false,
      separateRoute: true,
      routes: routes.contact,
      hasSubRoute: false,
      showSubRoute: false,
    },
    {
      tittle: "Servicios",
      showAsTab: false,
      separateRoute: true,
      routes: routes.services,
      hasSubRoute: false,
      showSubRoute: false,
    },
  ];

  const pagesActiveClassArray = [
    "/pages/aboutus",
    "/authentication/register",
    "/authentication/login",
    "/authentication/forgot-password",
    "/authentication/reset-password",
    "/pages/booking-payment",
    "/pages/booking-payment",
    "/pages/invoice-details",
    "/pages/error-404",
    "/pages/error-500",
    "/pages/pricing",
    "/pages/faq",
    "/pages/gallery",
    "/pages/our-team",
    "/pages/testimonial",
    "/pages/terms-condition",
    "/pages/privacy-policy",
    "/pages/maintenance",
    "/pages/coming-soon",
    "/pages/services",
  ];
  return (
    <>
      <header className="header">
        <div className="container-fluid">
          <nav className="header-nav navbar navbar-expand-lg">
            <div className="navbar-header">
              <Link id="mobile_btn" to="#">
                <span className="bar-icon">
                  {/* <span />
                  <span />
                  <span /> */}
                </span>
              </Link>
                <Link to={routes.home} className="logo navbar-brand">
                  <ImageWithBasePath
                    src="logo.png"
                    width={150}
                    height={150}
                    className="img-fluid"
                    alt="Logo"
                  />
                </Link>
              <Link to={routes.home} className="logo-small navbar-brand">
                <ImageWithBasePath
                  src="assets/img/logo-small.png"
                  className="img-fluid"
                  alt="Logo"
                />
              </Link>
            </div>
            <div className="main-menu-wrapper">
              <div className="menu-header">
                <Link to={routes.home} className="menu-logo">
                  <ImageWithBasePath
                    src="assets/img/logo.svg"
                    className="img-fluid"
                    alt="Logo"
                  />
                </Link>
                <Link id="menu_close" className="menu-close" to="#">
                  {" "}
                  <i className="fa-times fas" />
                </Link>
              </div>
              <ul className="main-nav">
                {header.map((mainMenus, mainIndex) => {
                  // const link_array:any = [];
                  // mainMenus?.menu?.map((link) => {
                  //   link_array?.push(link?.routes);
                  //   if (link?.subMenus) {
                  //     link?.subMenus?.map((item) => {
                  //       link_array?.push(item?.routes);
                  //     });
                  //   }
                  //   return link_array;
                  // });
                  // mainMenus.links = link_array;
                  return (
                    <React.Fragment key={mainIndex}>
                      {mainMenus.separateRoute ? (
                        <li
                          key={mainIndex}
                          className={
                            location.pathname.includes(
                              mainMenus.routes || ""
                            ) ||
                            mainMenus?.links?.includes(location.pathname) ||
                            (mainMenus.tittle == "Pages" &&
                              pagesActiveClassArray?.map((name) =>
                                name.includes(location.pathname)
                              ))
                              ? "active"
                              : ""
                          }
                        >
                          {/* {checkActiceClass(mainMenus)} */}
                          <Link to={mainMenus.routes}>{mainMenus.tittle}</Link>
                        </li>
                      ) : (
                        // <li className={`has-submenu ${mainMenus?.menu?.map((item)=> item?.routes).includes(location.pathname)  ? "active":""}`}>
                        <li
                          className={`has-submenu ${mainMenus?.menu?.map((item) => item?.routes).includes(location.pathname) ? "active" : ""}`}
                        >
                          <Link to="#">
                            {mainMenus.tittle}{" "}
                            <i className="fa-chevron-down fas"></i>
                          </Link>
                          <ul
                            className={`submenu ${mainMenus.showAsTab ? "d-block" : ""}`}
                          >
                            {mainMenus.menu?.map((menu, menuIndex) => (
                              <li
                                key={menuIndex}
                                className={`${menu.hasSubRoute ? "has-submenu" : ""} ${menu?.subMenus?.map((item) => item?.routes).includes(location.pathname) ? "active" : ""}`}
                              >
                                {menu.hasSubRoute ? (
                                  <React.Fragment>
                                    <Link href="#">{menu.menuValue}</Link>
                                    <ul
                                      className={`submenu ${menu.showSubRoute ? "d-block" : ""}`}
                                    >
                                      {menu.subMenus?.map(
                                        (subMenu, subMenuIndex) => (
                                          <li key={subMenuIndex}>
                                            <Link to={subMenu.routes}>
                                              {subMenu.menuValue}
                                            </Link>
                                          </li>
                                        )
                                      )}
                                    </ul>
                                  </React.Fragment>
                                ) : (
                                  <li
                                    className={
                                      location.pathname.includes(
                                        menu.routes || ""
                                      )
                                        ? "active"
                                        : ""
                                    }
                                  >
                                    <Link to={menu.routes}>
                                      {menu.menuValue}
                                    </Link>
                                  </li>
                                )}
                              </li>
                            ))}
                          </ul>
                        </li>
                      )}
                    </React.Fragment>
                  );
                })}
              </ul>
            </div>
            <ul className="header-navbar-rht nav">
              <li className="nav-item">
                <Link className="header-login nav-link" to={routes.login}>
                  <span>
                    <i className="fa-regular fa-user" />
                  </span>
                  Iniciar sesión
                </Link>
              </li>
              {/* <li className="nav-item">
                <Link className="header-reg nav-link" to={routes.signup}>
                  <span>
                    <i className="fa-lock fa-solid" />
                  </span>
                  Regístrate
                </Link>
              </li> */}
            </ul>
          </nav>
        </div>
      </header>
    </>
  );
};

export default Header;
