export const testimonial = [
  {
    id: 1,
    author: "Luis Reyes",
    image: "assets/img/profiles/avatar-02.jpg",
    rating: 5.0,
    reviewText:
      "Un excelente experiencia",
  },
  {
    id: 2,
    author: "Valerie L. Ellis",
    image: "assets/img/profiles/avatar-03.jpg",
    rating: 5.0,
    reviewText:
      "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.",
  },
  {
    author: "Laverne Marier",
    image: "assets/img/profiles/avatar-04.jpg",
    rating: 5.0,
    reviewText:
      "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.",
  },
  {
    author: "Courtland Artois",
    image: "assets/img/profiles/avatar-05.jpg",
    rating: 5.0,
    reviewText:
      "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.",
  },
  {
    author: "Sydney Salmons",
    image: "assets/img/profiles/avatar-06.jpg",
    rating: 5.0,
    reviewText:
      "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.",
  },
  {
    author: "Lucas Moquin",
    image: "assets/img/profiles/avatar-07.jpg",
    rating: 5.0,
    reviewText:
      "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.",
  },
  {
    author: "Philippe Tachel",
    image: "assets/img/profiles/avatar-08.jpg",
    rating: 5.0,
    reviewText:
      "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.",
  },
  {
    author: "Dixie Bazin",
    image: "assets/img/profiles/avatar-09.jpg",
    rating: 5.0,
    reviewText:
      "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.",
  },
];
