import React, { useEffect } from "react";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import AOS from "aos";
import "aos/dist/aos.css";

const Services = () => {
  useEffect(() => {
    AOS.init({ duration: 1000, once: true });
  }, []);

  const servicesData = [
    {
      image: "servicio.png",
      title: "Quinta Wolf",
      descriptions: [
        "Los socios pueden reservar la Quinta por $99 dólares o rentar a terceras personas y ganar hasta el 50%"
      ],
    },
    {
      image: "servicio2.png",
      title: "SPA AMELIE",
      descriptions: [
        "Disfruta de habitaciones normales y con jacuzzi con descuentos de hasta un 50%"
      ],
    },
    {
      image: "servicio3.jpeg",
      title: "Vuelo en Parapente",
      descriptions: [
        "Precio para clientes $70",
        "Precio para socios Wolf $45"
      ],
    },
    {
      image: "servicio4.png",
      title: "Lavadora a Vapor",
      descriptions: [
        "Limpia y desinfecta con máquinas a vapor"
      ],
    },
    {
      image: "servicio5.jpeg",
      title: "PARQUE ACUÁTICOD’ CARLOS",
      descriptions: [
        "Disfruta de hasta el 50% de descuento en entradas y hospedaje en nuestro parque acuático."
      ],
    },
    {
      image: "servicio6.jpeg",
      title: "Tiquibuzo Estancia Club",
      descriptions: [
        "Cabañas en medio del bosque"
      ],
    },
    {
      image: "servicio7.jpeg",
      title: "Departamentos Arnb",
      descriptions: [
        "Se Renta departamentos en los edificios más altos de Quito, Guayaquil y Loja"
      ],
    },
    {
      image: "servicio8.jpeg",
      title: "SUMAK PAKARI",
      descriptions: [
        "Para Socios Wolf, descuentos en hospedajes de hasta un 50%"
      ],
    },
  ];

  return (
    <section className="popular-explore" style={{ display: "flex", alignItems: 'center' }}>
      <div className="container">
        <div className="section-heading" data-aos="fade-down">
          <h2>OTROS SERVICIOS</h2>
          <p>
            En esta sección se pueden observar otros servicios con los que contamos
          </p>
        </div>
        <div className="why-choose-group">
          <div className="row">
            {servicesData.map((service, index) => (
              <div
                key={index}
                className="d-flex col-12 col-lg-3 col-md-6"
                data-aos="fade-down"
              >
                <div className="flex-fill card service-card" style={{ backgroundColor: '#F0F0F0 ' }}>
                  <div className="card-body">
                    <div className="choose-img" style={{ 
                      width: '200px', 
                      height: '200px', 
                      margin: '0 auto 20px', 
                      overflow: 'hidden', 
                      borderRadius: '5%', 
                      boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)' 
                    }}>
                      <img
                        src={`${process.env.PUBLIC_URL}/assets/img/servicios/${service.image}`}
                        alt={service.title}
                        style={{ 
                          width: '100%', 
                          height: '100%', 
                          objectFit: 'cover' 
                        }}
                      />
                    </div>
                    <div className="choose-content">
                      <h4>{service.title}</h4>
                      {service.descriptions.map((desc, idx) => (
                        <p key={idx}>{desc}</p>
                      ))}
                    </div>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
    </section>
  );
};

export default Services;
