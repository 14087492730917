export const Pricingdata = [
  {
    id: 1,
    level: "Básico",
    description: "Para lo básico",
    price: "$49",
    billing: "Por usuario por mes",
    message: "Get Starting message",
    extend: "Min 1 month, extend anytime",
    tax: "Rental car prices include tax",
    return: "Extend or return anytime",
    delivery: "Doorstep delivery in 2 days",
    carsystem: "Car system included free of charge.",
    validity: "Min 1 month, extend anytime",
  },
  {
    id: 2,
    level: "Recomendado",
    description: "Para los usuarios",
    price: "$95",
    billing: "Por usuario por mes",
    message: "Get Starting message",
    extend: "Min 1 month, extend anytime",
    tax: "Rental car prices include tax",
    return: "Extend or return anytime",
    delivery: "Doorstep delivery in 2 days",
    carsystem: "Car system included free of charge.",
    validity: "Min 1 month, extend anytime",
  },
  {
    id: 3,
    level: "Pro",
    description: "Para el profesional",
    price: "$154",
    billing: "Por usuario por mes",
    message: "Get Starting message",
    extend: "Min 1 month, extend anytime",
    tax: "Rental car prices include tax",
    return: "Extend or return anytime",
    delivery: "Doorstep delivery in 2 days",
    carsystem: "Car system included free of charge.",
    validity: "Min 1 month, extend anytime",
  },
];
