import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import ImageWithBasePath from "../../core/data/img/ImageWithBasePath";
import { Calendar } from "primereact/calendar";
import CountUp from "react-countup";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Slider from "react-slick";
import AOS from "aos";
import "aos/dist/aos.css";
import { Dayjs } from "dayjs";
import dayjs from "dayjs";
import { TimePicker } from "antd";
import { testimonialsData } from "../../core/data/json/testimonials_data";
import { all_routes } from "../router/all_routes";

const Home = () => {
  const routes = all_routes;
  const testimonials = testimonialsData;
  const [selectedItems, setSelectedItems] = useState(Array(10).fill(false));
  const [date1, setDate1] = useState(null);
  const [date2, setDate2] = useState(null);
  const onChange = (time: Dayjs, timeString: string) => {
    console.log(time, timeString);
  };
  const handleItemClick = (index: number) => {
    setSelectedItems((prevSelectedItems) => {
      const updatedSelectedItems = [...prevSelectedItems];
      updatedSelectedItems[index] = !updatedSelectedItems[index];
      return updatedSelectedItems;
    });
  };
{/*
  const settings = {
    dots: true,
    nav: true,
    infinite: true,
    speed: 500,
    slidesToShow: 4,
    slidesToScroll: 1,
  };
   */}
   {/*
  const one = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 4,
    slidesToScroll: 3,
  };
*/}
  const setting = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 2,
    slidesToScroll: 1,
  };

  useEffect(() => {
    AOS.init({ duration: 1000, once: true });
  }, []);
  return (
    <>
{/* Banner */}
<section>
  <div className="container">
    <div className="home-banner">
      <div className="align-items-center row">
        <div className="col-lg-6" data-aos="fade-down">
          <h1>
            Renta de Vehículos <br />
            <span>Tu Auto de ensueño en alquiler</span>
          </h1>
          <p>
            <span className="text-white">Reserva tu auto en alquiler con nosotros por lo 
              menos 2 días antes de tu viaje para conseguir un 
              precio por debajo del promedio.</span>
          </p>
          <div className="view-all">
            <Link
              to={routes.listinggrid}
              className="d-inline-flex align-items-center btn btn-view"
            >
              Ver todos los autos{" "}
              <span>
                <i className="icon-arrow-right feather ms-2" />
              </span>
            </Link>
          </div>
        </div>
        <div className="col-lg-6" data-aos="fade-down" style={{ marginTop: "150px" }}>
          <div className="banner-imgs">
            <ImageWithBasePath
              src="assets/img/car-right.png"
              className="aos img-fluid"
              alt="bannerimage"
            />
          </div>
        </div>
      </div>
    </div>
  </div>
</section>
{/* /Banner */}


      {/* Search */}
      <div className="section-search">
        <div className="container">
          <div className="search-box-banner">
            <form>
              <ul className="align-items-center">
                <li className="column-group-main">
                  <div className="input-block">
                    <label>Lugar de recogida</label>
                    <div className="group-img">
                      <input
                        type="text"
                        className="form-control"
                        placeholder="Quito, Av. Amazonas"
                      />
                      <span>
                        <i className="icon-map-pin feather" />
                      </span>
                    </div>
                  </div>
                </li>
                <li className="column-group-main">
                  <div className="input-block">
                    <label>Fecha de recogida</label>
                  </div>
                  <div className="input-block-wrapp">
                    <div className="input-block date-widget">
                      <div className="group-img">
                        <Calendar
                          value={date1}
                          onChange={(e) => setDate1(e.value)}
                          placeholder="01/01/2024"
                        />
                        {/* <input type="text" className="form-control datetimepicker" placeholder="04/11/2023" /> */}
                        <span>
                          <i className="feather icon-calendar"></i>
                        </span>
                      </div>
                    </div>
                    <div className="input-block time-widge">
                      <div className="group-img">
                        <TimePicker
                          placeholder="11:00 AM"
                          className="form-control timepicker"
                          onChange={onChange}
                          defaultValue={dayjs("00:00:00", "HH:mm:ss")}
                        />
                        <span>
                          <i className="feather icon-clock"></i>
                        </span>
                      </div>
                    </div>
                  </div>
                </li>
                <li className="column-group-main">
                  <div className="input-block">
                    <label>Fecha de retorno</label>
                  </div>
                  <div className="input-block-wrapp">
                    <div className="input-block date-widge">
                      <div className="group-img">
                        <Calendar
                          value={date2}
                          onChange={(e) => setDate2(e.value)}
                          placeholder="01/01/2024"
                        />
                        <span>
                          <i className="feather icon-calendar" />
                        </span>
                      </div>
                    </div>
                    <div className="input-block time-widge">
                      <div className="group-img">
                        <TimePicker
                          placeholder="11:00 AM"
                          className="form-control timepicker"
                          onChange={onChange}
                          defaultValue={dayjs("00:00:00", "HH:mm:ss")}
                        />
                        <span>
                          <i className="feather icon-clock"></i>
                        </span>
                      </div>
                    </div>
                  </div>
                </li>
                <li className="column-group-last">
                  <div className="input-block">
                    <div className="search-btn">
                      <button className="search-button btn" type="submit">
                        {" "}
                        <i className="fa fa-search" aria-hidden="true" />
                        Buscar
                      </button>
                    </div>
                  </div>
                </li>
              </ul>
            </form>
          </div>
        </div>
      </div>
      {/* /Search */}
      {/* services */}
      <section className="section services">
        <div className="service-right">
          <ImageWithBasePath
            src="assets/img/bg/service-right.svg"
            className="img-fluid"
            alt="services right"
          />
        </div>
        <div className="container">
          {/* Heading title*/}
          <div className="section-heading" data-aos="fade-down">
            <h2>Bienvenidos</h2>
            <p>
              Con Wolf vive la mejor experiencia de renta. 
              Disfruta de nuestras alianzas y ofertas especiales. 
              ponemos a tu disposición una amplia selección de 
              coberturas y servicios opcionales que harán 
              que tu renta sea más seguro y placentero.
            </p>
          </div>
          {/* /Heading title */}
          <div className="services-work">
            <div className="row">
              <div className="col-12 col-lg-4 col-md-4" data-aos="fade-down">
                <div className="services-group">
                  <div className="border-secondary services-icon">
                    <ImageWithBasePath
                      className="bg-secondary icon-img"
                      src="assets/img/icons/services-icon-01.svg"
                      alt="Choose Locations"
                    />
                  </div>
                  <div className="services-content">
                    <h3>1. Eligir ubicación</h3>
                    <p>
                      Wolf pone a disponibilidad los automoviles que neeesitas
                      para mejorar tu experiencia de viaje.
                    </p>
                  </div>
                </div>
              </div>
              <div className="col-12 col-lg-4 col-md-4" data-aos="fade-down">
                <div className="services-group">
                  <div className="border-warning services-icon">
                    <ImageWithBasePath
                      className="bg-warning icon-img"
                      src="assets/img/icons/services-icon-02.svg"
                      alt="Choose Locations"
                    />
                  </div>
                  <div className="services-content">
                    <h3>2. Lugar de recogida</h3>
                    <p>
                      Elige el lugar que estae más cercano y sea
                      fácil para retirar su auto de renta.
                    </p>
                  </div>
                </div>
              </div>
              <div className="col-12 col-lg-4 col-md-4" data-aos="fade-down">
                <div className="services-group">
                  <div className="border-dark services-icon">
                    <ImageWithBasePath
                      className="bg-dark icon-img"
                      src="assets/img/icons/services-icon-03.svg"
                      alt="Choose Locations"
                    />
                  </div>
                  <div className="services-content">
                    <h3>3. Reserve su Auto</h3>
                    <p>
                      Realice la reserva. No solo ofrecemos autos de alta calidad, 
                      sino también beneficios y descuentos diseñados 
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* /services */}
      {/* Popular Services */}
      <section className="popular-explore popular-services section">
        <div className="container">
          {/* Heading title*/}
          <div className="section-heading" data-aos="fade-down">
            <h2>Explorar los autos más populares</h2>
            <p>
            Descubre cómo puedes experimentar la comodidad y 
            el lujo de tener un automóvil sin preocupaciones.
            </p>
          </div>
          {/* /Heading title */}
          <div className="justify-content-center row">
            <div className="col-lg-12" data-aos="fade-down">
              <div className="listing-tabs-group">
                <ul className="gap-3 listing-buttons nav" data-bs-tabs="tabs">
                  <li>
                    <Link
                      className="active"
                      aria-current="true"
                      data-bs-toggle="tab"
                      to="#Carmazda"
                    >
                      <span>
                        <ImageWithBasePath
                          src="assets/img/icons/car-icon-01.svg"
                          alt="Mazda"
                        />
                      </span>
                      Mazda
                    </Link>
                  </li>
                  <li>
                    <Link data-bs-toggle="tab" to="#Caraudi">
                      <span>
                        <ImageWithBasePath
                          src="assets/img/icons/car-icon-02.svg"
                          alt="Audi"
                        />
                      </span>
                      Audi
                    </Link>
                  </li>
                  <li>
                    <Link data-bs-toggle="tab" to="#Carhonda">
                      <span>
                        <ImageWithBasePath
                          src="assets/img/icons/car-icon-03.svg"
                          alt="Honda"
                        />
                      </span>
                      Honda
                    </Link>
                  </li>
                  <li>
                    <Link data-bs-toggle="tab" to="#Cartoyota">
                      <span>
                        <ImageWithBasePath
                          src="assets/img/icons/car-icon-04.svg"
                          alt="Toyota"
                        />
                      </span>
                      Toyota
                    </Link>
                  </li>
                  <li>
                    <Link data-bs-toggle="tab" to="#Caracura">
                      <span>
                        <ImageWithBasePath
                          src="assets/img/icons/car-icon-05.svg"
                          alt="Acura"
                        />
                      </span>
                      Acura
                    </Link>
                  </li>
                  <li>
                    <Link data-bs-toggle="tab" to="#Cartesla">
                      <span>
                        <ImageWithBasePath
                          src="assets/img/icons/car-icon-06.svg"
                          alt="Tesla"
                        />
                      </span>
                      Tesla
                    </Link>
                  </li>
                </ul>
              </div>
            </div>
          </div>
          <div className="tab-content">
            <div className="active tab-pane" id="Carmazda">
              <div className="row">
                {/* col */}
                <div className="col-12 col-lg-4 col-md-6" data-aos="fade-down">
                  <div className="listing-item">
                    <div className="listing-img">
                      <Link to={routes.listingdetails}>
                        <ImageWithBasePath
                          src="assets/img/cars/car-09.webp"
                          className="img-fluid"
                          alt="Toyota"
                        />
                      </Link>
                      <div
                        className="fav-item"
                        key={1}
                        onClick={() => handleItemClick(1)}
                      >
                        <span className="featured-text" >Toyota</span>
                        <Link
                          to="#"
                          className={`fav-icon ${
                            selectedItems[1] ? "selected" : ""
                          }`}
                        >
                          <i className="feather icon-heart" />
                        </Link>
                      </div>
                    </div>
                    <div className="listing-content">
                      <div className="listing-features">
                        <Link to="#" className="author-img">
                          <ImageWithBasePath
                            src="assets/img/profiles/avatar-0.jpg"
                            alt="author"
                          />
                        </Link>
                        <h3 className="listing-title">
                          <Link to={routes.listingdetails}>
                            Toyota Camry SE 350
                          </Link>
                        </h3>
                        <div className="list-rating">
                          <i className="fa-star fas filled" />
                          <i className="fa-star fas filled" />
                          <i className="fa-star fas filled" />
                          <i className="fa-star fas filled" />
                          <i className="fa-star fas filled" />
                          <span>(5.0)</span>
                        </div>
                      </div>
                      <div className="listing-details-group">
                        <ul>
                          <li>
                            <span>
                              <ImageWithBasePath
                                src="assets/img/icons/car-parts-01.svg"
                                alt="Auto"
                              />
                            </span>
                            <p>Auto</p>
                          </li>
                          <li>
                            <span>
                              <ImageWithBasePath
                                src="assets/img/icons/car-parts-02.svg"
                                alt="10 KM"
                              />
                            </span>
                            <p>10 KM</p>
                          </li>
                          <li>
                            <span>
                              <ImageWithBasePath
                                src="assets/img/icons/car-parts-03.svg"
                                alt="Gasolina"
                              />
                            </span>
                            <p>Gasolina</p>
                          </li>
                        </ul>
                        <ul>
                          <li>
                            <span>
                              <ImageWithBasePath
                                src="assets/img/icons/car-parts-04.svg"
                                alt="Power"
                              />
                            </span>
                            <p>Fuerza</p>
                          </li>
                          <li>
                            <span>
                              <ImageWithBasePath
                                src="assets/img/icons/car-parts-05.svg"
                                alt=""
                              />
                            </span>
                            <p>2024</p>
                          </li>
                          <li>
                            <span>
                              <ImageWithBasePath
                                src="assets/img/icons/car-parts-06.svg"
                                alt="Personas"
                              />
                            </span>
                            <p>5 Personas</p>
                          </li>
                        </ul>
                      </div>
                      <div className="listing-location-details">
                        <div className="listing-price">
                          <span>
                            <i className="icon-map-pin feather" />
                          </span>
                          Ecuador
                        </div>
                        <div className="listing-price">
                          <h6>
                            $400 <span>/ Dia</span>
                          </h6>
                        </div>
                      </div>
                      <div className="listing-button">
                        <Link
                          to={routes.listingdetails}
                          className="btn btn-order"
                        >
                          <span>
                            <i className="feather icon-calendar me-2" />
                          </span>
                          Rentar Ahora
                        </Link>
                      </div>
                    </div>
                  </div>
                </div>
                {/* /col */}
                {/* col */}
                <div className="col-12 col-lg-4 col-md-6" data-aos="fade-down">
                  <div className="listing-item">
                    <div className="listing-img">
                      <Link to={routes.listingdetails}>
                        <ImageWithBasePath
                          src="assets/img/cars/car-02.png"
                          className="img-fluid"
                          alt="KIA"
                        />
                      </Link>
                      <div
                        className="fav-item"
                        key={2}
                        onClick={() => handleItemClick(2)}
                      >
                        <span className="featured-text">KIA</span>
                        <Link
                          to="#"
                          className={`fav-icon ${
                            selectedItems[2] ? "selected" : ""
                          }`}
                        >
                          <i className="feather icon-heart" />
                        </Link>
                      </div>
                    </div>
                    <div className="listing-content">
                      <div className="listing-features">
                        <Link to="#" className="author-img">
                          <ImageWithBasePath
                            src="assets/img/profiles/avatar-02.jpg"
                            alt="author"
                          />
                        </Link>
                        <h3 className="listing-title">
                          <Link to={routes.listingdetails}>Kia Soul 2016</Link>
                        </h3>
                        <div className="list-rating">
                          <i className="fa-star fas filled" />
                          <i className="fa-star fas filled" />
                          <i className="fa-star fas filled" />
                          <i className="fa-star fas filled" />
                          <i className="fa-star fas filled" />
                          <span>(5.0)</span>
                        </div>
                      </div>
                      <div className="listing-details-group">
                        <ul>
                          <li>
                            <span>
                              <ImageWithBasePath
                                src="assets/img/icons/car-parts-01.svg"
                                alt="Auto"
                              />
                            </span>
                            <p>Auto</p>
                          </li>
                          <li>
                            <span>
                              <ImageWithBasePath
                                src="assets/img/icons/car-parts-02.svg"
                                alt="22 KM"
                              />
                            </span>
                            <p>22 KM</p>
                          </li>
                          <li>
                            <span>
                              <ImageWithBasePath
                                src="assets/img/icons/car-parts-03.svg"
                                alt="Gasolina"
                              />
                            </span>
                            <p>Gasolina</p>
                          </li>
                        </ul>
                        <ul>
                          <li>
                            <span>
                              <ImageWithBasePath
                                src="assets/img/icons/car-parts-04.svg"
                                alt="Diesel"
                              />
                            </span>
                            <p>Diesel</p>
                          </li>
                          <li>
                            <span>
                              <ImageWithBasePath
                                src="assets/img/icons/car-parts-05.svg"
                                alt=""
                              />
                            </span>
                            <p>2016</p>
                          </li>
                          <li>
                            <span>
                              <ImageWithBasePath
                                src="assets/img/icons/car-parts-06.svg"
                                alt="Personas"
                              />
                            </span>
                            <p>5 Personas</p>
                          </li>
                        </ul>
                      </div>
                      <div className="listing-location-details">
                        <div className="listing-price">
                          <span>
                            <i className="icon-map-pin feather" />
                          </span>
                          Belgium
                        </div>
                        <div className="listing-price">
                          <h6>
                            $80 <span>/ Dia</span>
                          </h6>
                        </div>
                      </div>
                      <div className="listing-button">
                        <Link
                          to={routes.listingdetails}
                          className="btn btn-order"
                        >
                          <span>
                            <i className="feather icon-calendar me-2" />
                          </span>
                          Rentar Ahora
                        </Link>
                      </div>
                    </div>
                  </div>
                </div>
                {/* /col */}
                {/* col */}
                <div className="col-12 col-lg-4 col-md-6" data-aos="fade-down">
                  <div className="listing-item">
                    <div className="listing-img">
                      <Link to={routes.listingdetails}>
                        <ImageWithBasePath
                          src="assets/img/cars/car-03.png"
                          className="img-fluid"
                          alt="Audi"
                        />
                      </Link>
                      <div
                        className="fav-item"
                        key={3}
                        onClick={() => handleItemClick(3)}
                      >
                        <span className="featured-text">Audi</span>
                        <Link
                          to="#"
                          className={`fav-icon ${
                            selectedItems[3] ? "selected" : ""
                          }`}
                        >
                          <i className="feather icon-heart" />
                        </Link>
                      </div>
                    </div>
                    <div className="listing-content">
                      <div className="listing-features">
                        <Link to="#" className="author-img">
                          <ImageWithBasePath
                            src="assets/img/profiles/avatar-03.jpg"
                            alt="author"
                          />
                        </Link>
                        <h3 className="listing-title">
                          <Link to={routes.listingdetails}>
                            Audi A3 2019 nuevo
                          </Link>
                        </h3>
                        <div className="list-rating">
                          <i className="fa-star fas filled" />
                          <i className="fa-star fas filled" />
                          <i className="fa-star fas filled" />
                          <i className="fa-star fas filled" />
                          <i className="fa-star fas filled" />
                          <span>(5.0)</span>
                        </div>
                      </div>
                      <div className="listing-details-group">
                        <ul>
                          <li>
                            <span>
                              <ImageWithBasePath
                                src="assets/img/icons/car-parts-05.svg"
                                alt="Manual"
                              />
                            </span>
                            <p>Manual</p>
                          </li>
                          <li>
                            <span>
                              <ImageWithBasePath
                                src="assets/img/icons/car-parts-02.svg"
                                alt="10 KM"
                              />
                            </span>
                            <p>10 KM</p>
                          </li>
                          <li>
                            <span>
                              <ImageWithBasePath
                                src="assets/img/icons/car-parts-03.svg"
                                alt="Gasolina"
                              />
                            </span>
                            <p>Gasolina</p>
                          </li>
                        </ul>
                        <ul>
                          <li>
                            <span>
                              <ImageWithBasePath
                                src="assets/img/icons/car-parts-04.svg"
                                alt="Power"
                              />
                            </span>
                            <p>Fuerza</p>
                          </li>
                          <li>
                            <span>
                              <ImageWithBasePath
                                src="assets/img/icons/car-parts-05.svg"
                                alt=""
                              />
                            </span>
                            <p>2019</p>
                          </li>
                          <li>
                            <span>
                              <ImageWithBasePath
                                src="assets/img/icons/car-parts-06.svg"
                                alt="Personas"
                              />
                            </span>
                            <p>4 Personas</p>
                          </li>
                        </ul>
                      </div>
                      <div className="listing-location-details">
                        <div className="listing-price">
                          <span>
                            <i className="icon-map-pin feather" />
                          </span>
                          Quito, ECU
                        </div>
                        <div className="listing-price">
                          <h6>
                            $45 <span>/ Dia</span>
                          </h6>
                        </div>
                      </div>
                      <div className="listing-button">
                        <Link
                          to={routes.listingdetails}
                          className="btn btn-order"
                        >
                          <span>
                            <i className="feather icon-calendar me-2" />
                          </span>
                          Rentar Ahora
                        </Link>
                      </div>
                    </div>
                  </div>
                </div>
                {/* /col */}
                {/* col */}
                {/* 
                <div className="col-12 col-lg-4 col-md-6" data-aos="fade-down">
                  <div className="listing-item">
                    <div className="listing-img">
                      <Link to={routes.listingdetails}>
                        <ImageWithBasePath
                          src="assets/img/cars/car-04.png"
                          className="img-fluid"
                          alt="Audi"
                        />
                      </Link>
                      <div
                        className="fav-item"
                        key={4}
                        onClick={() => handleItemClick(4)}
                      >
                        <span className="featured-text">Ferrai</span>
                        <Link
                          to="#"
                          className={`fav-icon ${
                            selectedItems[4] ? "selected" : ""
                          }`}
                        >
                          <i className="feather icon-heart" />
                        </Link>
                      </div>
                    </div>
                    <div className="listing-content">
                      <div className="listing-features">
                        <Link to="#" className="author-img">
                          <ImageWithBasePath
                            src="assets/img/profiles/avatar-04.jpg"
                            alt="author"
                          />
                        </Link>
                        <h3 className="listing-title">
                          <Link to={routes.listingdetails}>
                            Ferrari 458 MM Especial
                          </Link>
                        </h3>
                        <div className="list-rating">
                          <i className="fa-star fas filled" />
                          <i className="fa-star fas filled" />
                          <i className="fa-star fas filled" />
                          <i className="fa-star fas filled" />
                          <i className="fa-star fas filled" />
                          <span>(5.0)</span>
                        </div>
                      </div>
                      <div className="listing-details-group">
                        <ul>
                          <li>
                            <span>
                              <ImageWithBasePath
                                src="assets/img/icons/car-parts-05.svg"
                                alt="Manual"
                              />
                            </span>
                            <p>Manual</p>
                          </li>
                          <li>
                            <span>
                              <ImageWithBasePath
                                src="assets/img/icons/car-parts-02.svg"
                                alt="14 KM"
                              />
                            </span>
                            <p>14 KM</p>
                          </li>
                          <li>
                            <span>
                              <ImageWithBasePath
                                src="assets/img/icons/car-parts-03.svg"
                                alt="Diesel"
                              />
                            </span>
                            <p>Diesel</p>
                          </li>
                        </ul>
                        <ul>
                          <li>
                            <span>
                              <ImageWithBasePath
                                src="assets/img/icons/car-parts-04.svg"
                                alt="Básico"
                              />
                            </span>
                            <p>Básico</p>
                          </li>
                          <li>
                            <span>
                              <ImageWithBasePath
                                alt=""
                                src="assets/img/icons/car-parts-05.svg"
                              />
                            </span>
                            <p>2022</p>
                          </li>
                          <li>
                            <span>
                              <ImageWithBasePath
                                src="assets/img/icons/car-parts-06.svg"
                                alt="Personas"
                              />
                            </span>
                            <p>5 Personas</p>
                          </li>
                        </ul>
                      </div>
                      <div className="listing-location-details">
                        <div className="listing-price">
                          <span>
                            <i className="icon-map-pin feather" />
                          </span>
                          Quito, ECU
                        </div>
                        <div className="listing-price">
                          <h6>
                            $160 <span>/ Dia</span>
                          </h6>
                        </div>
                      </div>
                      <div className="listing-button">
                        <Link
                          to={routes.listingdetails}
                          className="btn btn-order"
                        >
                          <span>
                            <i className="feather icon-calendar me-2" />
                          </span>
                          Rentar ahora
                        </Link>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-12 col-lg-4 col-md-6" data-aos="fade-down">
                  <div className="listing-item">
                    <div className="listing-img">
                      <Link to={routes.listingdetails}>
                        <ImageWithBasePath
                          src="assets/img/cars/car-05.webp"
                          className="img-fluid"
                          alt="Audi"
                        />
                      </Link>
                      <div
                        className="fav-item"
                        key={5}
                        onClick={() => handleItemClick(5)}
                      >
                        <span className="featured-text">Chevrolet</span>
                        <Link
                          to="#"
                          className={`fav-icon ${
                            selectedItems[5] ? "selected" : ""
                          }`}
                        >
                          <i className="feather icon-heart" />
                        </Link>
                      </div>
                    </div>
                    <div className="listing-content">
                      <div className="listing-features">
                        <Link to="#" className="author-img">
                          <ImageWithBasePath
                            src="assets/img/profiles/avatar-05.jpg"
                            alt="author"
                          />
                        </Link>
                        <h3 className="listing-title">
                          <Link to={routes.listingdetails}>
                            2018 Chevrolet Camaro
                          </Link>
                        </h3>
                        <div className="list-rating">
                          <i className="fa-star fas filled" />
                          <i className="fa-star fas filled" />
                          <i className="fa-star fas filled" />
                          <i className="fa-star fas filled" />
                          <i className="fa-star fas filled" />
                          <span>(5.0)</span>
                        </div>
                      </div>
                      <div className="listing-details-group">
                        <ul>
                          <li>
                            <span>
                              <ImageWithBasePath
                                src="assets/img/icons/car-parts-05.svg"
                                alt="Manual"
                              />
                            </span>
                            <p>Manual</p>
                          </li>
                          <li>
                            <span>
                              <ImageWithBasePath
                                src="assets/img/icons/car-parts-02.svg"
                                alt="18 KM"
                              />
                            </span>
                            <p>18 KM</p>
                          </li>
                          <li>
                            <span>
                              <ImageWithBasePath
                                src="assets/img/icons/car-parts-03.svg"
                                alt="Diesel"
                              />
                            </span>
                            <p>Diesel</p>
                          </li>
                        </ul>
                        <ul>
                          <li>
                            <span>
                              <ImageWithBasePath
                                src="assets/img/icons/car-parts-04.svg"
                                alt="Power"
                              />
                            </span>
                            <p>Fuerza</p>
                          </li>
                          <li>
                            <span>
                              <ImageWithBasePath
                                src="assets/img/icons/car-parts-05.svg"
                                alt=""
                              />
                            </span>
                            <p>2018</p>
                          </li>
                          <li>
                            <span>
                              <ImageWithBasePath
                                src="assets/img/icons/car-parts-06.svg"
                                alt="Personas"
                              />
                            </span>
                            <p>4 Personas</p>
                          </li>
                        </ul>
                      </div>
                      <div className="listing-location-details">
                        <div className="listing-price">
                          <span>
                            <i className="icon-map-pin feather" />
                          </span>
                          Ecuador
                        </div>
                        <div className="listing-price">
                          <h6>
                            $36 <span>/ Dia</span>
                          </h6>
                        </div>
                      </div>
                      <div className="listing-button">
                        <Link
                          to={routes.listingdetails}
                          className="btn btn-order"
                        >
                          <span>
                            <i className="feather icon-calendar me-2" />
                          </span>
                          Rentar ahora
                        </Link>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-12 col-lg-4 col-md-6" data-aos="fade-down">
                  <div className="listing-item">
                    <div className="listing-img">
                      <Link to={routes.listingdetails}>
                        <ImageWithBasePath
                          src="assets/img/cars/car-06.webp"
                          className="img-fluid"
                          alt="Audi"
                        />
                      </Link>
                      <div
                        className="fav-item"
                        key={6}
                        onClick={() => handleItemClick(6)}
                      >
                        <span className="featured-text">Acura</span>
                        <Link
                          to="#"
                          className={`fav-icon ${
                            selectedItems[6] ? "selected" : ""
                          }`}
                        >
                          <i className="feather icon-heart" />
                        </Link>
                      </div>
                    </div>
                    <div className="listing-content">
                      <div className="listing-features">
                        <Link to="#" className="author-img">
                          <ImageWithBasePath
                            src="assets/img/profiles/avatar-06.jpg"
                            alt="author"
                          />
                        </Link>
                        <h3 className="listing-title">
                          <Link to={routes.listingdetails}>
                            Acura Sport
                          </Link>
                        </h3>
                        <div className="list-rating">
                          <i className="fa-star fas filled" />
                          <i className="fa-star fas filled" />
                          <i className="fa-star fas filled" />
                          <i className="fa-star fas filled" />
                          <i className="fa-star fas filled" />
                          <span>(5.0)</span>
                        </div>
                      </div>
                      <div className="listing-details-group">
                        <ul>
                          <li>
                            <span>
                              <ImageWithBasePath
                                src="assets/img/icons/car-parts-01.svg"
                                alt="Auto"
                              />
                            </span>
                            <p>Auto</p>
                          </li>
                          <li>
                            <span>
                              <ImageWithBasePath
                                src="assets/img/icons/car-parts-02.svg"
                                alt="12 KM"
                              />
                            </span>
                            <p>12 KM</p>
                          </li>
                          <li>
                            <span>
                              <ImageWithBasePath
                                src="assets/img/icons/car-parts-03.svg"
                                alt="Diesel"
                              />
                            </span>
                            <p>Diesel</p>
                          </li>
                        </ul>
                        <ul>
                          <li>
                            <span>
                              <ImageWithBasePath
                                src="assets/img/icons/car-parts-04.svg"
                                alt="Power"
                              />
                            </span>
                            <p>Fuerza</p>
                          </li>
                          <li>
                            <span>
                              <ImageWithBasePath
                                src="assets/img/icons/car-parts-05.svg"
                                alt=""
                              />
                            </span>
                            <p>2013</p>
                          </li>
                          <li>
                            <span>
                              <ImageWithBasePath
                                src="assets/img/icons/car-parts-06.svg"
                                alt="Personas"
                              />
                            </span>
                            <p>5 Personas</p>
                          </li>
                        </ul>
                      </div>
                      <div className="listing-location-details">
                        <div className="listing-price">
                          <span>
                            <i className="icon-map-pin feather" />
                          </span>
                          Quito, ECU
                        </div>
                        <div className="listing-price">
                          <h6>
                            $30 <span>/ Dia</span>
                          </h6>
                        </div>
                      </div>
                      <div className="listing-button">
                        <Link
                          to={routes.listingdetails}
                          className="btn btn-order"
                        >
                          <span>
                            <i className="feather icon-calendar me-2" />
                          </span>
                          Rentar ahora
                        </Link>
                      </div>
                    </div>
                  </div>
                </div>
          
                {/*
                <div className="col-12 col-lg-4 col-md-6" data-aos="fade-down">
                  <div className="listing-item">
                    <div className="listing-img">
                      <Link to={routes.listingdetails}>
                        <ImageWithBasePath
                          src="assets/img/cars/car-07.png"
                          className="img-fluid"
                          alt="Audi"
                        />
                      </Link>
                      <div
                        className="fav-item"
                        key={7}
                        onClick={() => handleItemClick(7)}
                      >
                        <span className="featured-text">Chevrolet</span>
                        <Link
                          to="#"
                          className={`fav-icon ${
                            selectedItems[7] ? "selected" : ""
                          }`}
                        >
                          <i className="feather icon-heart" />
                        </Link>
                      </div>
                    </div>
                    <div className="listing-content">
                      <div className="listing-features">
                        <Link to="#" className="author-img">
                          <ImageWithBasePath
                            src="assets/img/profiles/avatar-07.jpg"
                            alt="author"
                          />
                        </Link>
                        <h3 className="listing-title">
                          <Link to={routes.listingdetails}>
                            Chevrolet Pick Truck 3.5L
                          </Link>
                        </h3>
                        <div className="list-rating">
                          <i className="fa-star fas filled" />
                          <i className="fa-star fas filled" />
                          <i className="fa-star fas filled" />
                          <i className="fa-star fas filled" />
                          <i className="fa-star fas filled" />
                          <span>(5.0)</span>
                        </div>
                      </div>
                      <div className="listing-details-group">
                        <ul>
                          <li>
                            <span>
                              <ImageWithBasePath
                                src="assets/img/icons/car-parts-05.svg"
                                alt="Manual"
                              />
                            </span>
                            <p>Manual</p>
                          </li>
                          <li>
                            <span>
                              <ImageWithBasePath
                                src="assets/img/icons/car-parts-02.svg"
                                alt="10 KM"
                              />
                            </span>
                            <p>10 KM</p>
                          </li>
                          <li>
                            <span>
                              <ImageWithBasePath
                                src="assets/img/icons/car-parts-03.svg"
                                alt="Gasolina"
                              />
                            </span>
                            <p>Gasolina</p>
                          </li>
                        </ul>
                        <ul>
                          <li>
                            <span>
                              <ImageWithBasePath
                                src="assets/img/icons/car-parts-04.svg"
                                alt="Power"
                              />
                            </span>
                            <p>Fuerza</p>
                          </li>
                          <li>
                            <span>
                              <ImageWithBasePath
                                src="assets/img/icons/car-parts-05.svg"
                                alt=""
                              />
                            </span>
                            <p>2012</p>
                          </li>
                          <li>
                            <span>
                              <ImageWithBasePath
                                src="assets/img/icons/car-parts-06.svg"
                                alt="Personas"
                              />
                            </span>
                            <p>5 Personas</p>
                          </li>
                        </ul>
                      </div>
                      <div className="listing-location-details">
                        <div className="listing-price">
                          <span>
                            <i className="icon-map-pin feather" />
                          </span>
                          Ecuador
                        </div>
                        <div className="listing-price">
                          <h6>
                            $77 <span>/ Dia</span>
                          </h6>
                        </div>
                      </div>
                      <div className="listing-button">
                        <Link
                          to={routes.listingdetails}
                          className="btn btn-order"
                        >
                          <span>
                            <i className="feather icon-calendar me-2" />
                          </span>
                          Rentar ahora
                        </Link>
                      </div>
                    </div>
                  </div>
                </div>
               */}
                {/* 
                <div className="col-12 col-lg-4 col-md-6" data-aos="fade-down">
                  <div className="listing-item">
                    <div className="listing-img">
                      <Link to={routes.listingdetails}>
                        <ImageWithBasePath
                          src="assets/img/cars/car-08.png"
                          className="img-fluid"
                          alt="Toyota"
                        />
                      </Link>
                      <div
                        className="fav-item"
                        key={8}
                        onClick={() => handleItemClick(8)}
                      >
                        <span className="featured-text">Toyota</span>
                        <Link
                          to="#"
                          className={`fav-icon ${
                            selectedItems[8] ? "selected" : ""
                          }`}
                        >
                          <i className="feather icon-heart" />
                        </Link>
                      </div>
                    </div>
                    <div className="listing-content">
                      <div className="listing-features">
                        <Link to="#" className="author-img">
                          <ImageWithBasePath
                            src="assets/img/profiles/avatar-08.jpg"
                            alt="author"
                          />
                        </Link>
                        <h3 className="listing-title">
                          <Link to={routes.listingdetails}>
                            Toyota Tacoma 4WD
                          </Link>
                        </h3>
                        <div className="list-rating">
                          <i className="fa-star fas filled" />
                          <i className="fa-star fas filled" />
                          <i className="fa-star fas filled" />
                          <i className="fa-star fas filled" />
                          <i className="fa-star fas filled" />
                          <span>(5.0)</span>
                        </div>
                      </div>
                      <div className="listing-details-group">
                        <ul>
                          <li>
                            <span>
                              <ImageWithBasePath
                                src="assets/img/icons/car-parts-01.svg"
                                alt="Auto"
                              />
                            </span>
                            <p>Auto</p>
                          </li>
                          <li>
                            <span>
                              <ImageWithBasePath
                                src="assets/img/icons/car-parts-02.svg"
                                alt="22 miles"
                              />
                            </span>
                            <p>22 miles</p>
                          </li>
                          <li>
                            <span>
                              <ImageWithBasePath
                                src="assets/img/icons/car-parts-03.svg"
                                alt="Diesel"
                              />
                            </span>
                            <p>Diesel</p>
                          </li>
                        </ul>
                        <ul>
                          <li>
                            <span>
                              <ImageWithBasePath
                                src="assets/img/icons/car-parts-04.svg"
                                alt="Power"
                              />
                            </span>
                            <p>Fuerza</p>
                          </li>
                          <li>
                            <span>
                              <ImageWithBasePath
                                src="assets/img/icons/car-parts-05.svg"
                                alt=""
                              />
                            </span>
                            <p>2019</p>
                          </li>
                          <li>
                            <span>
                              <ImageWithBasePath
                                src="assets/img/icons/car-parts-06.svg"
                                alt="Personas"
                              />
                            </span>
                            <p>5 Personas</p>
                          </li>
                        </ul>
                      </div>
                      <div className="listing-location-details">
                        <div className="listing-price">
                          <span>
                            <i className="icon-map-pin feather" />
                          </span>
                          Quito, ECU
                        </div>
                        <div className="listing-price">
                          <h6>
                            $30 <span>/ Dia</span>
                          </h6>
                        </div>
                      </div>
                      <div className="listing-button">
                        <Link
                          to={routes.listingdetails}
                          className="btn btn-order"
                        >
                          <span>
                            <i className="feather icon-calendar me-2" />
                          </span>
                          Rentar ahora
                        </Link>
                      </div>
                    </div>
                  </div>
                </div>
               */}
                {/*
                <div className="col-12 col-lg-4 col-md-6" data-aos="fade-down">
                  <div className="listing-item">
                    <div className="listing-img">
                      <Link to={routes.listingdetails}>
                        <ImageWithBasePath
                          src="assets/img/cars/car-09.webp"
                          className="img-fluid"
                          alt="Toyota"
                        />
                      </Link>
                      <div
                        className="fav-item"
                        key={9}
                        onClick={() => handleItemClick(9)}
                      >
                        <span className="featured-text">Accura</span>
                        <Link
                          to="#"
                          className={`fav-icon ${
                            selectedItems[9] ? "selected" : ""
                          }`}
                        >
                          <i className="feather icon-heart" />
                        </Link>
                      </div>
                    </div>
                    <div className="listing-content">
                      <div className="listing-features">
                        <Link to="#" className="author-img">
                          <ImageWithBasePath
                            src="assets/img/profiles/avatar-10.jpg"
                            alt="author"
                          />
                        </Link>
                        <h3 className="listing-title">
                          <Link to={routes.listingdetails}>Acura RDX FWD</Link>
                        </h3>
                        <div className="list-rating">
                          <i className="fa-star fas filled" />
                          <i className="fa-star fas filled" />
                          <i className="fa-star fas filled" />
                          <i className="fa-star fas filled" />
                          <i className="fa-star fas filled" />
                          <span>(5.0)</span>
                        </div>
                      </div>
                      <div className="listing-details-group">
                        <ul>
                          <li>
                            <span>
                              <ImageWithBasePath
                                src="assets/img/icons/car-parts-01.svg"
                                alt="Auto"
                              />
                            </span>
                            <p>Auto</p>
                          </li>
                          <li>
                            <span>
                              <ImageWithBasePath
                                src="assets/img/icons/car-parts-02.svg"
                                alt="22 miles"
                              />
                            </span>
                            <p>42 miles</p>
                          </li>
                          <li>
                            <span>
                              <ImageWithBasePath
                                src="assets/img/icons/car-parts-03.svg"
                                alt="Gasolina"
                              />
                            </span>
                            <p>Gasolina</p>
                          </li>
                        </ul>
                        <ul>
                          <li>
                            <span>
                              <ImageWithBasePath
                                src="assets/img/icons/car-parts-04.svg"
                                alt="Power"
                              />
                            </span>
                            <p>Fuerza</p>
                          </li>
                          <li>
                            <span>
                              <ImageWithBasePath
                                src="assets/img/icons/car-parts-05.svg"
                                alt=""
                              />
                            </span>
                            <p>2021</p>
                          </li>
                          <li>
                            <span>
                              <ImageWithBasePath
                                src="assets/img/icons/car-parts-06.svg"
                                alt="Personas"
                              />
                            </span>
                            <p>5 Personas</p>
                          </li>
                        </ul>
                      </div>
                      <div className="listing-location-details">
                        <div className="listing-price">
                          <span>
                            <i className="icon-map-pin feather" />
                          </span>
                          Quito, ECU
                        </div>
                        <div className="listing-price">
                          <h6>
                            $80 <span>/ Dia</span>
                          </h6>
                        </div>
                      </div>
                      <div className="listing-button">
                        <Link
                          to={routes.listingdetails}
                          className="btn btn-order"
                        >
                          <span>
                            <i className="feather icon-calendar me-2" />
                          </span>
                          Rentar ahora
                        </Link>
                      </div>
                    </div>
                  </div>
                </div>
              */}
                {/* /col */}
              </div>
            </div>
            <div className="fade tab-pane" id="Caraudi">
              <div className="row">
                {/* col */}
                <div className="col-12 col-lg-4 col-md-6" data-aos="fade-down">
                  <div className="listing-item">
                    <div className="listing-img">
                      <Link to={routes.listingdetails}>
                        <ImageWithBasePath
                          src="assets/img/cars/car-03.png"
                          className="img-fluid"
                          alt="Audi"
                        />
                      </Link>
                      <div
                        className="fav-item"
                        key={10}
                        onClick={() => handleItemClick(10)}
                      >
                        <span className="featured-text">Audi</span>
                        <Link
                          to="#"
                          className={`fav-icon ${
                            selectedItems[10] ? "selected" : ""
                          }`}
                        >
                          <i className="feather icon-heart" />
                        </Link>
                      </div>
                    </div>
                    <div className="listing-content">
                      <div className="listing-features">
                        <Link to="#" className="author-img">
                          <ImageWithBasePath
                            src="assets/img/profiles/avatar-02.jpg"
                            alt="author"
                          />
                        </Link>
                        <h3 className="listing-title">
                          <Link to={routes.listingdetails}>
                            Audi A3 2019 new
                          </Link>
                        </h3>
                        <div className="list-rating">
                          <i className="fa-star fas filled" />
                          <i className="fa-star fas filled" />
                          <i className="fa-star fas filled" />
                          <i className="fa-star fas filled" />
                          <i className="fa-star fas filled" />
                          <span>(5.0)</span>
                        </div>
                      </div>
                      <div className="listing-details-group">
                        <ul>
                          <li>
                            <span>
                              <ImageWithBasePath
                                src="assets/img/icons/car-parts-05.svg"
                                alt="Manual"
                              />
                            </span>
                            <p>Manual</p>
                          </li>
                          <li>
                            <span>
                              <ImageWithBasePath
                                src="assets/img/icons/car-parts-02.svg"
                                alt="10 KM"
                              />
                            </span>
                            <p>10 KM</p>
                          </li>
                          <li>
                            <span>
                              <ImageWithBasePath
                                src="assets/img/icons/car-parts-03.svg"
                                alt="Gasolina"
                              />
                            </span>
                            <p>Gasolina</p>
                          </li>
                        </ul>
                        <ul>
                          <li>
                            <span>
                              <ImageWithBasePath
                                src="assets/img/icons/car-parts-04.svg"
                                alt="Power"
                              />
                            </span>
                            <p>Fuerza</p>
                          </li>
                          <li>
                            <span>
                              <ImageWithBasePath
                                src="assets/img/icons/car-parts-05.svg"
                                alt=""
                              />
                            </span>
                            <p>2019</p>
                          </li>
                          <li>
                            <span>
                              <ImageWithBasePath
                                src="assets/img/icons/car-parts-06.svg"
                                alt="Personas"
                              />
                            </span>
                            <p>4 Personas</p>
                          </li>
                        </ul>
                      </div>
                      <div className="listing-location-details">
                        <div className="listing-price">
                          <span>
                            <i className="icon-map-pin feather" />
                          </span>
                          Quito, ECU
                        </div>
                        <div className="listing-price">
                          <h6>
                            $45 <span>/ Dia</span>
                          </h6>
                        </div>
                      </div>
                      <div className="listing-button">
                        <Link
                          to={routes.listingdetails}
                          className="btn btn-order"
                        >
                          <span>
                            <i className="feather icon-calendar me-2" />
                          </span>
                          Rentar ahora
                        </Link>
                      </div>
                    </div>
                  </div>
                </div>
                {/* /col */}
                {/* col */}
                <div className="col-12 col-lg-4 col-md-6" data-aos="fade-down">
                  <div className="listing-item">
                    <div className="listing-img">
                      <Link to={routes.listingdetails}>
                        <ImageWithBasePath
                          src="assets/img/cars/car-09.webp"
                          className="img-fluid"
                          alt="Audi"
                        />
                      </Link>
                      <div
                        className="fav-item"
                        key={11}
                        onClick={() => handleItemClick(11)}
                      >
                        <span className="featured-text">Ferrai</span>
                        <Link
                          to="#"
                          className={`fav-icon ${
                            selectedItems[11] ? "selected" : ""
                          }`}
                        >
                          <i className="feather icon-heart" />
                        </Link>
                      </div>
                    </div>
                    <div className="listing-content">
                      <div className="listing-features">
                        <Link to="#" className="author-img">
                          <ImageWithBasePath
                            src="assets/img/profiles/avatar-04.jpg"
                            alt="author"
                          />
                        </Link>
                        <h3 className="listing-title">
                          <Link to={routes.listingdetails}>
                            Ferrari 458 MM Special
                          </Link>
                        </h3>
                        <div className="list-rating">
                          <i className="fa-star fas filled" />
                          <i className="fa-star fas filled" />
                          <i className="fa-star fas filled" />
                          <i className="fa-star fas filled" />
                          <i className="fa-star fas filled" />
                          <span>(5.0)</span>
                        </div>
                      </div>
                      <div className="listing-details-group">
                        <ul>
                          <li>
                            <span>
                              <ImageWithBasePath
                                src="assets/img/icons/car-parts-05.svg"
                                alt="Manual"
                              />
                            </span>
                            <p>Manual</p>
                          </li>
                          <li>
                            <span>
                              <ImageWithBasePath
                                src="assets/img/icons/car-parts-02.svg"
                                alt="14 KM"
                              />
                            </span>
                            <p>14 KM</p>
                          </li>
                          <li>
                            <span>
                              <ImageWithBasePath
                                src="assets/img/icons/car-parts-03.svg"
                                alt="Diesel"
                              />
                            </span>
                            <p>Diesel</p>
                          </li>
                        </ul>
                        <ul>
                          <li>
                            <span>
                              <ImageWithBasePath
                                src="assets/img/icons/car-parts-04.svg"
                                alt="Básico"
                              />
                            </span>
                            <p>Básico</p>
                          </li>
                          <li>
                            <span>
                              <ImageWithBasePath
                                src="assets/img/icons/car-parts-05.svg"
                                alt=""
                              />
                            </span>
                            <p>2022</p>
                          </li>
                          <li>
                            <span>
                              <ImageWithBasePath
                                src="assets/img/icons/car-parts-06.svg"
                                alt="Personas"
                              />
                            </span>
                            <p>5 Personas</p>
                          </li>
                        </ul>
                      </div>
                      <div className="listing-location-details">
                        <div className="listing-price">
                          <span>
                            <i className="icon-map-pin feather" />
                          </span>
                          Quito, ECU
                        </div>
                        <div className="listing-price">
                          <h6>
                            $160 <span>/ Dia</span>
                          </h6>
                        </div>
                      </div>
                      <div className="listing-button">
                        <Link
                          to={routes.listingdetails}
                          className="btn btn-order"
                        >
                          <span>
                            <i className="feather icon-calendar me-2" />
                          </span>
                          Rentar ahora
                        </Link>
                      </div>
                    </div>
                  </div>
                </div>
                {/* /col */}
                {/* col */}
                <div className="col-12 col-lg-4 col-md-6" data-aos="fade-down">
                  <div className="listing-item">
                    <div className="listing-img">
                      <Link to={routes.listingdetails}>
                        <ImageWithBasePath
                          src="assets/img/cars/car-09.webp"
                          className="img-fluid"
                          alt="Audi"
                        />
                      </Link>
                      <div
                        className="fav-item"
                        key={12}
                        onClick={() => handleItemClick(12)}
                      >
                        <span className="featured-text">Chevrolet</span>
                        <Link
                          to="#"
                          className={`fav-icon ${
                            selectedItems[12] ? "selected" : ""
                          }`}
                        >
                          <i className="feather icon-heart" />
                        </Link>
                      </div>
                    </div>
                    <div className="listing-content">
                      <div className="listing-features">
                        <Link to="#" className="author-img">
                          <ImageWithBasePath
                            src="assets/img/profiles/avatar-05.jpg"
                            alt="author"
                          />
                        </Link>
                        <h3 className="listing-title">
                          <Link to={routes.listingdetails}>
                            2018 Chevrolet Camaro
                          </Link>
                        </h3>
                        <div className="list-rating">
                          <i className="fa-star fas filled" />
                          <i className="fa-star fas filled" />
                          <i className="fa-star fas filled" />
                          <i className="fa-star fas filled" />
                          <i className="fa-star fas filled" />
                          <span>(5.0)</span>
                        </div>
                      </div>
                      <div className="listing-details-group">
                        <ul>
                          <li>
                            <span>
                              <ImageWithBasePath
                                src="assets/img/icons/car-parts-05.svg"
                                alt="Manual"
                              />
                            </span>
                            <p>Manual</p>
                          </li>
                          <li>
                            <span>
                              <ImageWithBasePath
                                src="assets/img/icons/car-parts-02.svg"
                                alt="18 KM"
                              />
                            </span>
                            <p>18 KM</p>
                          </li>
                          <li>
                            <span>
                              <ImageWithBasePath
                                src="assets/img/icons/car-parts-03.svg"
                                alt="Diesel"
                              />
                            </span>
                            <p>Diesel</p>
                          </li>
                        </ul>
                        <ul>
                          <li>
                            <span>
                              <ImageWithBasePath
                                src="assets/img/icons/car-parts-04.svg"
                                alt="Power"
                              />
                            </span>
                            <p>Fuerza</p>
                          </li>
                          <li>
                            <span>
                              <ImageWithBasePath
                                src="assets/img/icons/car-parts-05.svg"
                                alt=""
                              />
                            </span>
                            <p>2018</p>
                          </li>
                          <li>
                            <span>
                              <ImageWithBasePath
                                src="assets/img/icons/car-parts-06.svg"
                                alt="Personas"
                              />
                            </span>
                            <p>4 Personas</p>
                          </li>
                        </ul>
                      </div>
                      <div className="listing-location-details">
                        <div className="listing-price">
                          <span>
                            <i className="icon-map-pin feather" />
                          </span>
                          Ecuador
                        </div>
                        <div className="listing-price">
                          <h6>
                            $36 <span>/ Dia</span>
                          </h6>
                        </div>
                      </div>
                      <div className="listing-button">
                        <Link
                          to={routes.listingdetails}
                          className="btn btn-order"
                        >
                          <span>
                            <i className="feather icon-calendar me-2" />
                          </span>
                          Rentar ahora
                        </Link>
                      </div>
                    </div>
                  </div>
                </div>
                {/* 
                <div className="col-12 col-lg-4 col-md-6" data-aos="fade-down">
                  <div className="listing-item">
                    <div className="listing-img">
                      <Link to={routes.listingdetails}>
                        <ImageWithBasePath
                          src="assets/img/cars/car-06.jpg"
                          className="img-fluid"
                          alt="Audi"
                        />
                      </Link>
                      <div
                        className="fav-item"
                        key={13}
                        onClick={() => handleItemClick(13)}
                      >
                        <span className="featured-text">Acura</span>
                        <Link
                          to="#"
                          className={`fav-icon ${
                            selectedItems[13] ? "selected" : ""
                          }`}
                        >
                          <i className="feather icon-heart" />
                        </Link>
                      </div>
                    </div>
                    <div className="listing-content">
                      <div className="listing-features">
                        <Link to="#" className="author-img">
                          <ImageWithBasePath
                            src="assets/img/profiles/avatar-06.jpg"
                            alt="author"
                          />
                        </Link>
                        <h3 className="listing-title">
                          <Link to={routes.listingdetails}>
                            Acura Sport Version
                          </Link>
                        </h3>
                        <div className="list-rating">
                          <i className="fa-star fas filled" />
                          <i className="fa-star fas filled" />
                          <i className="fa-star fas filled" />
                          <i className="fa-star fas filled" />
                          <i className="fa-star fas filled" />
                          <span>(5.0)</span>
                        </div>
                      </div>
                      <div className="listing-details-group">
                        <ul>
                          <li>
                            <span>
                              <ImageWithBasePath
                                src="assets/img/icons/car-parts-01.svg"
                                alt="Auto"
                              />
                            </span>
                            <p>Auto</p>
                          </li>
                          <li>
                            <span>
                              <ImageWithBasePath
                                src="assets/img/icons/car-parts-02.svg"
                                alt="12 KM"
                              />
                            </span>
                            <p>12 KM</p>
                          </li>
                          <li>
                            <span>
                              <ImageWithBasePath
                                src="assets/img/icons/car-parts-03.svg"
                                alt="Diesel"
                              />
                            </span>
                            <p>Diesel</p>
                          </li>
                        </ul>
                        <ul>
                          <li>
                            <span>
                              <ImageWithBasePath
                                src="assets/img/icons/car-parts-04.svg"
                                alt="Power"
                              />
                            </span>
                            <p>Fuerza</p>
                          </li>
                          <li>
                            <span>
                              <ImageWithBasePath
                                src="assets/img/icons/car-parts-05.svg"
                                alt=""
                              />
                            </span>
                            <p>2013</p>
                          </li>
                          <li>
                            <span>
                              <ImageWithBasePath
                                src="assets/img/icons/car-parts-06.svg"
                                alt="Personas"
                              />
                            </span>
                            <p>5 Personas</p>
                          </li>
                        </ul>
                      </div>
                      <div className="listing-location-details">
                        <div className="listing-price">
                          <span>
                            <i className="icon-map-pin feather" />
                          </span>
                          Quito, ECU
                        </div>
                        <div className="listing-price">
                          <h6>
                            $30 <span>/ Dia</span>
                          </h6>
                        </div>
                      </div>
                      <div className="listing-button">
                        <Link
                          to={routes.listingdetails}
                          className="btn btn-order"
                        >
                          <span>
                            <i className="feather icon-calendar me-2" />
                          </span>
                          Rentar ahora
                        </Link>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-12 col-lg-4 col-md-6" data-aos="fade-down">
                  <div className="listing-item">
                    <div className="listing-img">
                      <Link to={routes.listingdetails}>
                        <ImageWithBasePath
                          src="assets/img/cars/car-07.jpg"
                          className="img-fluid"
                          alt="Audi"
                        />
                      </Link>
                      <div
                        className="fav-item"
                        key={14}
                        onClick={() => handleItemClick(14)}
                      >
                        <span className="featured-text">Chevrolet</span>
                        <Link
                          to="#"
                          className={`fav-icon ${
                            selectedItems[14] ? "selected" : ""
                          }`}
                        >
                          <i className="feather icon-heart" />
                        </Link>
                      </div>
                    </div>
                    <div className="listing-content">
                      <div className="listing-features">
                        <Link to="#" className="author-img">
                          <ImageWithBasePath
                            src="assets/img/profiles/avatar-07.jpg"
                            alt="author"
                          />
                        </Link>
                        <h3 className="listing-title">
                          <Link to={routes.listingdetails}>
                            Chevrolet Pick Truck 3.5L
                          </Link>
                        </h3>
                        <div className="list-rating">
                          <i className="fa-star fas filled" />
                          <i className="fa-star fas filled" />
                          <i className="fa-star fas filled" />
                          <i className="fa-star fas filled" />
                          <i className="fa-star fas filled" />
                          <span>(5.0)</span>
                        </div>
                      </div>
                      <div className="listing-details-group">
                        <ul>
                          <li>
                            <span>
                              <ImageWithBasePath
                                src="assets/img/icons/car-parts-05.svg"
                                alt="Manual"
                              />
                            </span>
                            <p>Manual</p>
                          </li>
                          <li>
                            <span>
                              <ImageWithBasePath
                                src="assets/img/icons/car-parts-02.svg"
                                alt="10 KM"
                              />
                            </span>
                            <p>10 KM</p>
                          </li>
                          <li>
                            <span>
                              <ImageWithBasePath
                                src="assets/img/icons/car-parts-03.svg"
                                alt="Gasolina"
                              />
                            </span>
                            <p>Gasolina</p>
                          </li>
                        </ul>
                        <ul>
                          <li>
                            <span>
                              <ImageWithBasePath
                                src="assets/img/icons/car-parts-04.svg"
                                alt="Power"
                              />
                            </span>
                            <p>Fuerza</p>
                          </li>
                          <li>
                            <span>
                              <ImageWithBasePath
                                src="assets/img/icons/car-parts-05.svg"
                                alt=""
                              />
                            </span>
                            <p>2012</p>
                          </li>
                          <li>
                            <span>
                              <ImageWithBasePath
                                src="assets/img/icons/car-parts-06.svg"
                                alt="Personas"
                              />
                            </span>
                            <p>5 Personas</p>
                          </li>
                        </ul>
                      </div>
                      <div className="listing-location-details">
                        <div className="listing-price">
                          <span>
                            <i className="icon-map-pin feather" />
                          </span>
                          Ecuador
                        </div>
                        <div className="listing-price">
                          <h6>
                            $77 <span>/ Dia</span>
                          </h6>
                        </div>
                      </div>
                      <div className="listing-button">
                        <Link
                          to={routes.listingdetails}
                          className="btn btn-order"
                        >
                          <span>
                            <i className="feather icon-calendar me-2" />
                          </span>
                          Rentar ahora
                        </Link>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-12 col-lg-4 col-md-6" data-aos="fade-down">
                  <div className="listing-item">
                    <div className="listing-img">
                      <Link to={routes.listingdetails}>
                        <ImageWithBasePath
                          src="assets/img/cars/car-08.jpg"
                          className="img-fluid"
                          alt="Toyota"
                        />
                      </Link>
                      <div
                        className="fav-item"
                        key={15}
                        onClick={() => handleItemClick(15)}
                      >
                        <span className="featured-text">Toyota</span>
                        <Link
                          to="#"
                          className={`fav-icon ${
                            selectedItems[15] ? "selected" : ""
                          }`}
                        >
                          <i className="feather icon-heart" />
                        </Link>
                      </div>
                    </div>
                    <div className="listing-content">
                      <div className="listing-features">
                        <Link to="#" className="author-img">
                          <ImageWithBasePath
                            src="assets/img/profiles/avatar-08.jpg"
                            alt="author"
                          />
                        </Link>
                        <h3 className="listing-title">
                          <Link to={routes.listingdetails}>
                            Toyota Tacoma 4WD
                          </Link>
                        </h3>
                        <div className="list-rating">
                          <i className="fa-star fas filled" />
                          <i className="fa-star fas filled" />
                          <i className="fa-star fas filled" />
                          <i className="fa-star fas filled" />
                          <i className="fa-star fas filled" />
                          <span>(5.0)</span>
                        </div>
                      </div>
                      <div className="listing-details-group">
                        <ul>
                          <li>
                            <span>
                              <ImageWithBasePath
                                src="assets/img/icons/car-parts-01.svg"
                                alt="Auto"
                              />
                            </span>
                            <p>Auto</p>
                          </li>
                          <li>
                            <span>
                              <ImageWithBasePath
                                src="assets/img/icons/car-parts-02.svg"
                                alt="22 miles"
                              />
                            </span>
                            <p>22 Km</p>
                          </li>
                          <li>
                            <span>
                              <ImageWithBasePath
                                src="assets/img/icons/car-parts-03.svg"
                                alt="Diesel"
                              />
                            </span>
                            <p>Diesel</p>
                          </li>
                        </ul>
                        <ul>
                          <li>
                            <span>
                              <ImageWithBasePath
                                src="assets/img/icons/car-parts-04.svg"
                                alt="Power"
                              />
                            </span>
                            <p>Fuerza</p>
                          </li>
                          <li>
                            <span>
                              <ImageWithBasePath
                                src="assets/img/icons/car-parts-05.svg"
                                alt=""
                              />
                            </span>
                            <p>2019</p>
                          </li>
                          <li>
                            <span>
                              <ImageWithBasePath
                                src="assets/img/icons/car-parts-06.svg"
                                alt="Personas"
                              />
                            </span>
                            <p>5 Personas</p>
                          </li>
                        </ul>
                      </div>
                      <div className="listing-location-details">
                        <div className="listing-price">
                          <span>
                            <i className="icon-map-pin feather" />
                          </span>
                          Quito, ECU
                        </div>
                        <div className="listing-price">
                          <h6>
                            $30 <span>/ Dia</span>
                          </h6>
                        </div>
                      </div>
                      <div className="listing-button">
                        <Link
                          to={routes.listingdetails}
                          className="btn btn-order"
                        >
                          <span>
                            <i className="feather icon-calendar me-2" />
                          </span>
                          Rentar ahora
                        </Link>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-12 col-lg-4 col-md-6" data-aos="fade-down">
                  <div className="listing-item">
                    <div className="listing-img">
                      <Link to={routes.listingdetails}>
                        <ImageWithBasePath
                          src="assets/img/cars/car-01.jpg"
                          className="img-fluid"
                          alt="Toyota"
                        />
                      </Link>
                      <div
                        className="fav-item"
                        key={16}
                        onClick={() => handleItemClick(16)}
                      >
                        <span className="featured-text">Toyota</span>
                        <Link
                          to="#"
                          className={`fav-icon ${
                            selectedItems[16] ? "selected" : ""
                          }`}
                        >
                          <i className="feather icon-heart" />
                        </Link>
                      </div>
                    </div>
                    <div className="listing-content">
                      <div className="listing-features">
                        <Link to="#" className="author-img">
                          <ImageWithBasePath
                            src="assets/img/profiles/avatar-0.jpg"
                            alt="author"
                          />
                        </Link>
                        <h3 className="listing-title">
                          <Link to={routes.listingdetails}>
                            Toyota Camry SE 350
                          </Link>
                        </h3>
                        <div className="list-rating">
                          <i className="fa-star fas filled" />
                          <i className="fa-star fas filled" />
                          <i className="fa-star fas filled" />
                          <i className="fa-star fas filled" />
                          <i className="fa-star fas filled" />
                          <span>(5.0)</span>
                        </div>
                      </div>
                      <div className="listing-details-group">
                        <ul>
                          <li>
                            <span>
                              <ImageWithBasePath
                                src="assets/img/icons/car-parts-01.svg"
                                alt="Auto"
                              />
                            </span>
                            <p>Auto</p>
                          </li>
                          <li>
                            <span>
                              <ImageWithBasePath
                                src="assets/img/icons/car-parts-02.svg"
                                alt="10 KM"
                              />
                            </span>
                            <p>10 KM</p>
                          </li>
                          <li>
                            <span>
                              <ImageWithBasePath
                                src="assets/img/icons/car-parts-03.svg"
                                alt="Gasolina"
                              />
                            </span>
                            <p>Gasolina</p>
                          </li>
                        </ul>
                        <ul>
                          <li>
                            <span>
                              <ImageWithBasePath
                                src="assets/img/icons/car-parts-04.svg"
                                alt="Power"
                              />
                            </span>
                            <p>Fuerza</p>
                          </li>
                          <li>
                            <span>
                              <ImageWithBasePath
                                src="assets/img/icons/car-parts-05.svg"
                                alt=""
                              />
                            </span>
                            <p>2018</p>
                          </li>
                          <li>
                            <span>
                              <ImageWithBasePath
                                src="assets/img/icons/car-parts-06.svg"
                                alt="Personas"
                              />
                            </span>
                            <p>5 Personas</p>
                          </li>
                        </ul>
                      </div>
                      <div className="listing-location-details">
                        <div className="listing-price">
                          <span>
                            <i className="icon-map-pin feather" />
                          </span>
                          Ecuador
                        </div>
                        <div className="listing-price">
                          <h6>
                            $400 <span>/ Dia</span>
                          </h6>
                        </div>
                      </div>
                      <div className="listing-button">
                        <Link
                          to={routes.listingdetails}
                          className="btn btn-order"
                        >
                          <span>
                            <i className="feather icon-calendar me-2" />
                          </span>
                          Rentar Ahora
                        </Link>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-12 col-lg-4 col-md-6" data-aos="fade-down">
                  <div className="listing-item">
                    <div className="listing-img">
                      <Link to={routes.listingdetails}>
                        <ImageWithBasePath
                          src="assets/img/cars/car-02.jpg"
                          className="img-fluid"
                          alt="KIA"
                        />
                      </Link>
                      <div
                        className="fav-item"
                        key={17}
                        onClick={() => handleItemClick(17)}
                      >
                        <span className="featured-text">KIA</span>
                        <Link
                          to="#"
                          className={`fav-icon ${
                            selectedItems[17] ? "selected" : ""
                          }`}
                        >
                          <i className="feather icon-heart" />
                        </Link>
                      </div>
                    </div>
                    <div className="listing-content">
                      <div className="listing-features">
                        <Link to="#" className="author-img">
                          <ImageWithBasePath
                            src="assets/img/profiles/avatar-02.jpg"
                            alt="author"
                          />
                        </Link>
                        <h3 className="listing-title">
                          <Link to={routes.listingdetails}>Kia Soul 2016</Link>
                        </h3>
                        <div className="list-rating">
                          <i className="fa-star fas filled" />
                          <i className="fa-star fas filled" />
                          <i className="fa-star fas filled" />
                          <i className="fa-star fas filled" />
                          <i className="fa-star fas filled" />
                          <span>(5.0)</span>
                        </div>
                      </div>
                      <div className="listing-details-group">
                        <ul>
                          <li>
                            <span>
                              <ImageWithBasePath
                                src="assets/img/icons/car-parts-01.svg"
                                alt="Auto"
                              />
                            </span>
                            <p>Auto</p>
                          </li>
                          <li>
                            <span>
                              <ImageWithBasePath
                                src="assets/img/icons/car-parts-02.svg"
                                alt="22 KM"
                              />
                            </span>
                            <p>22 KM</p>
                          </li>
                          <li>
                            <span>
                              <ImageWithBasePath
                                src="assets/img/icons/car-parts-03.svg"
                                alt="Gasolina"
                              />
                            </span>
                            <p>Gasolina</p>
                          </li>
                        </ul>
                        <ul>
                          <li>
                            <span>
                              <ImageWithBasePath
                                src="assets/img/icons/car-parts-04.svg"
                                alt="Diesel"
                              />
                            </span>
                            <p>Diesel</p>
                          </li>
                          <li>
                            <span>
                              <ImageWithBasePath
                                src="assets/img/icons/car-parts-05.svg"
                                alt=""
                              />
                            </span>
                            <p>2016</p>
                          </li>
                          <li>
                            <span>
                              <ImageWithBasePath
                                src="assets/img/icons/car-parts-06.svg"
                                alt="Personas"
                              />
                            </span>
                            <p>5 Personas</p>
                          </li>
                        </ul>
                      </div>
                      <div className="listing-location-details">
                        <div className="listing-price">
                          <span>
                            <i className="icon-map-pin feather" />
                          </span>
                          Ecuador
                        </div>
                        <div className="listing-price">
                          <h6>
                            $80 <span>/ Dia</span>
                          </h6>
                        </div>
                      </div>
                      <div className="listing-button">
                        <Link
                          to={routes.listingdetails}
                          className="btn btn-order"
                        >
                          <span>
                            <i className="feather icon-calendar me-2" />
                          </span>
                          Rentar Ahora
                        </Link>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-12 col-lg-4 col-md-6" data-aos="fade-down">
                  <div className="listing-item">
                    <div className="listing-img">
                      <Link to={routes.listingdetails}>
                        <ImageWithBasePath
                          src="assets/img/cars/car-09.jpg"
                          className="img-fluid"
                          alt="Toyota"
                        />
                      </Link>
                      <div
                        className="fav-item"
                        key={18}
                        onClick={() => handleItemClick(18)}
                      >
                        <span className="featured-text">Accura</span>
                        <Link
                          to="#"
                          className={`fav-icon ${
                            selectedItems[18] ? "selected" : ""
                          }`}
                        >
                          <i className="feather icon-heart" />
                        </Link>
                      </div>
                    </div>
                    <div className="listing-content">
                      <div className="listing-features">
                        <Link to="#" className="author-img">
                          <ImageWithBasePath
                            src="assets/img/profiles/avatar-10.jpg"
                            alt="author"
                          />
                        </Link>
                        <h3 className="listing-title">
                          <Link to={routes.listingdetails}>Acura RDX FWD</Link>
                        </h3>
                        <div className="list-rating">
                          <i className="fa-star fas filled" />
                          <i className="fa-star fas filled" />
                          <i className="fa-star fas filled" />
                          <i className="fa-star fas filled" />
                          <i className="fa-star fas filled" />
                          <span>(5.0)</span>
                        </div>
                      </div>
                      <div className="listing-details-group">
                        <ul>
                          <li>
                            <span>
                              <ImageWithBasePath
                                src="assets/img/icons/car-parts-01.svg"
                                alt="Auto"
                              />
                            </span>
                            <p>Auto</p>
                          </li>
                          <li>
                            <span>
                              <ImageWithBasePath
                                src="assets/img/icons/car-parts-02.svg"
                                alt="22 miles"
                              />
                            </span>
                            <p>42 Km</p>
                          </li>
                          <li>
                            <span>
                              <ImageWithBasePath
                                src="assets/img/icons/car-parts-03.svg"
                                alt="Gasolina"
                              />
                            </span>
                            <p>Gasolina</p>
                          </li>
                        </ul>
                        <ul>
                          <li>
                            <span>
                              <ImageWithBasePath
                                src="assets/img/icons/car-parts-04.svg"
                                alt="Power"
                              />
                            </span>
                            <p>Fuerza</p>
                          </li>
                          <li>
                            <span>
                              <ImageWithBasePath
                                src="assets/img/icons/car-parts-05.svg"
                                alt=""
                              />
                            </span>
                            <p>2021</p>
                          </li>
                          <li>
                            <span>
                              <ImageWithBasePath
                                src="assets/img/icons/car-parts-06.svg"
                                alt="Personas"
                              />
                            </span>
                            <p>5 Personas</p>
                          </li>
                        </ul>
                      </div>
                      <div className="listing-location-details">
                        <div className="listing-price">
                          <span>
                            <i className="icon-map-pin feather" />
                          </span>
                          Quito, ECU
                        </div>
                        <div className="listing-price">
                          <h6>
                            $80 <span>/ Dia</span>
                          </h6>
                        </div>
                      </div>
                      <div className="listing-button">
                        <Link
                          to={routes.listingdetails}
                          className="btn btn-order"
                        >
                          <span>
                            <i className="feather icon-calendar me-2" />
                          </span>
                          Rentar Ahora
                        </Link>
                      </div>
                    </div>
                  </div>
                </div>
                {/* /col */}
              </div>
            </div>
            <div className="fade tab-pane" id="Carhonda">
              <div className="row">
                {/* col */}
                <div className="col-12 col-lg-4 col-md-6" data-aos="fade-down">
                  <div className="listing-item">
                    <div className="listing-img">
                      <Link to={routes.listingdetails}>
                        <ImageWithBasePath
                          src="assets/img/cars/car-03.png"
                          className="img-fluid"
                          alt="Toyota"
                        />
                      </Link>
                      <div
                        className="fav-item"
                        key={19}
                        onClick={() => handleItemClick(19)}
                      >
                        <span className="featured-text">Toyota</span>
                        <Link
                          to="#"
                          className={`fav-icon ${
                            selectedItems[19] ? "selected" : ""
                          }`}
                        >
                          <i className="feather icon-heart" />
                        </Link>
                      </div>
                    </div>
                    <div className="listing-content">
                      <div className="listing-features">
                        <Link to="#" className="author-img">
                          <ImageWithBasePath
                            src="assets/img/profiles/avatar-08.jpg"
                            alt="author"
                          />
                        </Link>
                        <h3 className="listing-title">
                          <Link to={routes.listingdetails}>
                            Toyota Tacoma 4WD
                          </Link>
                        </h3>
                        <div className="list-rating">
                          <i className="fa-star fas filled" />
                          <i className="fa-star fas filled" />
                          <i className="fa-star fas filled" />
                          <i className="fa-star fas filled" />
                          <i className="fa-star fas filled" />
                          <span>(5.0)</span>
                        </div>
                      </div>
                      <div className="listing-details-group">
                        <ul>
                          <li>
                            <span>
                              <ImageWithBasePath
                                src="assets/img/icons/car-parts-01.svg"
                                alt="Auto"
                              />
                            </span>
                            <p>Auto</p>
                          </li>
                          <li>
                            <span>
                              <ImageWithBasePath
                                src="assets/img/icons/car-parts-02.svg"
                                alt="22 miles"
                              />
                            </span>
                            <p>22 kM</p>
                          </li>
                          <li>
                            <span>
                              <ImageWithBasePath
                                src="assets/img/icons/car-parts-03.svg"
                                alt="Diesel"
                              />
                            </span>
                            <p>Diesel</p>
                          </li>
                        </ul>
                        <ul>
                          <li>
                            <span>
                              <ImageWithBasePath
                                src="assets/img/icons/car-parts-04.svg"
                                alt="Power"
                              />
                            </span>
                            <p>Fuerza</p>
                          </li>
                          <li>
                            <span>
                              <ImageWithBasePath
                                src="assets/img/icons/car-parts-05.svg"
                                alt=""
                              />
                            </span>
                            <p>2019</p>
                          </li>
                          <li>
                            <span>
                              <ImageWithBasePath
                                src="assets/img/icons/car-parts-06.svg"
                                alt="Personas"
                              />
                            </span>
                            <p>5 Personas</p>
                          </li>
                        </ul>
                      </div>
                      <div className="listing-location-details">
                        <div className="listing-price">
                          <span>
                            <i className="icon-map-pin feather" />
                          </span>
                          Quito, ECU
                        </div>
                        <div className="listing-price">
                          <h6>
                            $30 <span>/ Dia</span>
                          </h6>
                        </div>
                      </div>
                      <div className="listing-button">
                        <Link
                          to={routes.listingdetails}
                          className="btn btn-order"
                        >
                          <span>
                            <i className="feather icon-calendar me-2" />
                          </span>
                          Rentar Ahora
                        </Link>
                      </div>
                    </div>
                  </div>
                </div>
                {/* /col */}
                {/* col */}
                <div className="col-12 col-lg-4 col-md-6" data-aos="fade-down">
                  <div className="listing-item">
                    <div className="listing-img">
                      <Link to={routes.listingdetails}>
                        <ImageWithBasePath
                          src="assets/img/cars/car-02.png"
                          className="img-fluid"
                          alt="Toyota"
                        />
                      </Link>
                      <div
                        className="fav-item"
                        key={20}
                        onClick={() => handleItemClick(20)}
                      >
                        <span className="featured-text">Toyota</span>
                        <Link
                          to="#"
                          className={`fav-icon ${
                            selectedItems[20] ? "selected" : ""
                          }`}
                        >
                          <i className="feather icon-heart" />
                        </Link>
                      </div>
                    </div>
                    <div className="listing-content">
                      <div className="listing-features">
                        <Link to="#" className="author-img">
                          <ImageWithBasePath
                            src="assets/img/profiles/avatar-0.jpg"
                            alt="author"
                          />
                        </Link>
                        <h3 className="listing-title">
                          <Link to={routes.listingdetails}>
                            Toyota Camry SE 350
                          </Link>
                        </h3>
                        <div className="list-rating">
                          <i className="fa-star fas filled" />
                          <i className="fa-star fas filled" />
                          <i className="fa-star fas filled" />
                          <i className="fa-star fas filled" />
                          <i className="fa-star fas filled" />
                          <span>(5.0)</span>
                        </div>
                      </div>
                      <div className="listing-details-group">
                        <ul>
                          <li>
                            <span>
                              <ImageWithBasePath
                                src="assets/img/icons/car-parts-01.svg"
                                alt="Auto"
                              />
                            </span>
                            <p>Auto</p>
                          </li>
                          <li>
                            <span>
                              <ImageWithBasePath
                                src="assets/img/icons/car-parts-02.svg"
                                alt="10 KM"
                              />
                            </span>
                            <p>10 KM</p>
                          </li>
                          <li>
                            <span>
                              <ImageWithBasePath
                                src="assets/img/icons/car-parts-03.svg"
                                alt="Gasolina"
                              />
                            </span>
                            <p>Gasolina</p>
                          </li>
                        </ul>
                        <ul>
                          <li>
                            <span>
                              <ImageWithBasePath
                                src="assets/img/icons/car-parts-04.svg"
                                alt="Power"
                              />
                            </span>
                            <p>Fuerza</p>
                          </li>
                          <li>
                            <span>
                              <ImageWithBasePath
                                src="assets/img/icons/car-parts-05.svg"
                                alt=""
                              />
                            </span>
                            <p>2018</p>
                          </li>
                          <li>
                            <span>
                              <ImageWithBasePath
                                src="assets/img/icons/car-parts-06.svg"
                                alt="Personas"
                              />
                            </span>
                            <p>5 Personas</p>
                          </li>
                        </ul>
                      </div>
                      <div className="listing-location-details">
                        <div className="listing-price">
                          <span>
                            <i className="icon-map-pin feather" />
                          </span>
                          Ecuador
                        </div>
                        <div className="listing-price">
                          <h6>
                            $400 <span>/ Dia</span>
                          </h6>
                        </div>
                      </div>
                      <div className="listing-button">
                        <Link
                          to={routes.listingdetails}
                          className="btn btn-order"
                        >
                          <span>
                            <i className="feather icon-calendar me-2" />
                          </span>
                          Rentar Ahora
                        </Link>
                      </div>
                    </div>
                  </div>
                </div>
                {/* /col */}
                {/* col */}
                <div className="col-12 col-lg-4 col-md-6" data-aos="fade-down">
                  <div className="listing-item">
                    <div className="listing-img">
                      <Link to={routes.listingdetails}>
                        <ImageWithBasePath
                          src="assets/img/cars/car-09.webp"
                          className="img-fluid"
                          alt="KIA"
                        />
                      </Link>
                      <div
                        className="fav-item"
                        key={21}
                        onClick={() => handleItemClick(21)}
                      >
                        <span className="featured-text">KIA</span>
                        <Link
                          to="#"
                          className={`fav-icon ${
                            selectedItems[21] ? "selected" : ""
                          }`}
                        >
                          <i className="feather icon-heart" />
                        </Link>
                      </div>
                    </div>
                    <div className="listing-content">
                      <div className="listing-features">
                        <Link to="#" className="author-img">
                          <ImageWithBasePath
                            src="assets/img/profiles/avatar-02.jpg"
                            alt="author"
                          />
                        </Link>
                        <h3 className="listing-title">
                          <Link to={routes.listingdetails}>Kia Soul 2016</Link>
                        </h3>
                        <div className="list-rating">
                          <i className="fa-star fas filled" />
                          <i className="fa-star fas filled" />
                          <i className="fa-star fas filled" />
                          <i className="fa-star fas filled" />
                          <i className="fa-star fas filled" />
                          <span>(5.0)</span>
                        </div>
                      </div>
                      <div className="listing-details-group">
                        <ul>
                          <li>
                            <span>
                              <ImageWithBasePath
                                src="assets/img/icons/car-parts-01.svg"
                                alt="Auto"
                              />
                            </span>
                            <p>Auto</p>
                          </li>
                          <li>
                            <span>
                              <ImageWithBasePath
                                src="assets/img/icons/car-parts-02.svg"
                                alt="22 KM"
                              />
                            </span>
                            <p>22 KM</p>
                          </li>
                          <li>
                            <span>
                              <ImageWithBasePath
                                src="assets/img/icons/car-parts-03.svg"
                                alt="Gasolina"
                              />
                            </span>
                            <p>Gasolina</p>
                          </li>
                        </ul>
                        <ul>
                          <li>
                            <span>
                              <ImageWithBasePath
                                src="assets/img/icons/car-parts-04.svg"
                                alt="Diesel"
                              />
                            </span>
                            <p>Diesel</p>
                          </li>
                          <li>
                            <span>
                              <ImageWithBasePath
                                src="assets/img/icons/car-parts-05.svg"
                                alt=""
                              />
                            </span>
                            <p>2016</p>
                          </li>
                          <li>
                            <span>
                              <ImageWithBasePath
                                src="assets/img/icons/car-parts-06.svg"
                                alt="Personas"
                              />
                            </span>
                            <p>5 Personas</p>
                          </li>
                        </ul>
                      </div>
                      <div className="listing-location-details">
                        <div className="listing-price">
                          <span>
                            <i className="icon-map-pin feather" />
                          </span>
                          Ecuador
                        </div>
                        <div className="listing-price">
                          <h6>
                            $80 <span>/ Dia</span>
                          </h6>
                        </div>
                      </div>
                      <div className="listing-button">
                        <Link
                          to={routes.listingdetails}
                          className="btn btn-order"
                        >
                          <span>
                            <i className="feather icon-calendar me-2" />
                          </span>
                          Rentar Ahora
                        </Link>
                      </div>
                    </div>
                  </div>
                </div>
                {/*
                <div className="col-12 col-lg-4 col-md-6" data-aos="fade-down">
                  <div className="listing-item">
                    <div className="listing-img">
                      <Link to={routes.listingdetails}>
                        <ImageWithBasePath
                          src="assets/img/cars/car-03.jpg"
                          className="img-fluid"
                          alt="Audi"
                        />
                      </Link>
                      <div
                        className="fav-item"
                        key={22}
                        onClick={() => handleItemClick(22)}
                      >
                        <span className="featured-text">Audi</span>
                        <Link
                          to="#"
                          className={`fav-icon ${
                            selectedItems[22] ? "selected" : ""
                          }`}
                        >
                          <i className="feather icon-heart" />
                        </Link>
                      </div>
                    </div>
                    <div className="listing-content">
                      <div className="listing-features">
                        <Link to="#" className="author-img">
                          <ImageWithBasePath
                            src="assets/img/profiles/avatar-03.jpg"
                            alt="author"
                          />
                        </Link>
                        <h3 className="listing-title">
                          <Link to={routes.listingdetails}>
                            Audi A3 2019 new
                          </Link>
                        </h3>
                        <div className="list-rating">
                          <i className="fa-star fas filled" />
                          <i className="fa-star fas filled" />
                          <i className="fa-star fas filled" />
                          <i className="fa-star fas filled" />
                          <i className="fa-star fas filled" />
                          <span>(5.0)</span>
                        </div>
                      </div>
                      <div className="listing-details-group">
                        <ul>
                          <li>
                            <span>
                              <ImageWithBasePath
                                src="assets/img/icons/car-parts-05.svg"
                                alt="Manual"
                              />
                            </span>
                            <p>Manual</p>
                          </li>
                          <li>
                            <span>
                              <ImageWithBasePath
                                src="assets/img/icons/car-parts-02.svg"
                                alt="10 KM"
                              />
                            </span>
                            <p>10 KM</p>
                          </li>
                          <li>
                            <span>
                              <ImageWithBasePath
                                src="assets/img/icons/car-parts-03.svg"
                                alt="Gasolina"
                              />
                            </span>
                            <p>Gasolina</p>
                          </li>
                        </ul>
                        <ul>
                          <li>
                            <span>
                              <ImageWithBasePath
                                src="assets/img/icons/car-parts-04.svg"
                                alt="Power"
                              />
                            </span>
                            <p>Fuerza</p>
                          </li>
                          <li>
                            <span>
                              <ImageWithBasePath
                                src="assets/img/icons/car-parts-05.svg"
                                alt=""
                              />
                            </span>
                            <p>2019</p>
                          </li>
                          <li>
                            <span>
                              <ImageWithBasePath
                                src="assets/img/icons/car-parts-06.svg"
                                alt="Personas"
                              />
                            </span>
                            <p>4 Personas</p>
                          </li>
                        </ul>
                      </div>
                      <div className="listing-location-details">
                        <div className="listing-price">
                          <span>
                            <i className="icon-map-pin feather" />
                          </span>
                          Quito, ECU
                        </div>
                        <div className="listing-price">
                          <h6>
                            $45 <span>/ Dia</span>
                          </h6>
                        </div>
                      </div>
                      <div className="listing-button">
                        <Link
                          to={routes.listingdetails}
                          className="btn btn-order"
                        >
                          <span>
                            <i className="feather icon-calendar me-2" />
                          </span>
                          Rentar Ahora
                        </Link>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-12 col-lg-4 col-md-6" data-aos="fade-down">
                  <div className="listing-item">
                    <div className="listing-img">
                      <Link to={routes.listingdetails}>
                        <ImageWithBasePath
                          src="assets/img/cars/car-04.jpg"
                          className="img-fluid"
                          alt="Audi"
                        />
                      </Link>
                      <div
                        className="fav-item"
                        key={23}
                        onClick={() => handleItemClick(23)}
                      >
                        <span className="featured-text">Ferrai</span>
                        <Link
                          to="#"
                          className={`fav-icon ${
                            selectedItems[23] ? "selected" : ""
                          }`}
                        >
                          <i className="feather icon-heart" />
                        </Link>
                      </div>
                    </div>
                    <div className="listing-content">
                      <div className="listing-features">
                        <Link to="#" className="author-img">
                          <ImageWithBasePath
                            src="assets/img/profiles/avatar-04.jpg"
                            alt="author"
                          />
                        </Link>
                        <h3 className="listing-title">
                          <Link to={routes.listingdetails}>
                            Ferrari 458 MM Special
                          </Link>
                        </h3>
                        <div className="list-rating">
                          <i className="fa-star fas filled" />
                          <i className="fa-star fas filled" />
                          <i className="fa-star fas filled" />
                          <i className="fa-star fas filled" />
                          <i className="fa-star fas filled" />
                          <span>(5.0)</span>
                        </div>
                      </div>
                      <div className="listing-details-group">
                        <ul>
                          <li>
                            <span>
                              <ImageWithBasePath
                                src="assets/img/icons/car-parts-05.svg"
                                alt="Manual"
                              />
                            </span>
                            <p>Manual</p>
                          </li>
                          <li>
                            <span>
                              <ImageWithBasePath
                                src="assets/img/icons/car-parts-02.svg"
                                alt="14 KM"
                              />
                            </span>
                            <p>14 KM</p>
                          </li>
                          <li>
                            <span>
                              <ImageWithBasePath
                                src="assets/img/icons/car-parts-03.svg"
                                alt="Diesel"
                              />
                            </span>
                            <p>Diesel</p>
                          </li>
                        </ul>
                        <ul>
                          <li>
                            <span>
                              <ImageWithBasePath
                                src="assets/img/icons/car-parts-04.svg"
                                alt="Básico"
                              />
                            </span>
                            <p>Básico</p>
                          </li>
                          <li>
                            <span>
                              <ImageWithBasePath
                                src="assets/img/icons/car-parts-05.svg"
                                alt=""
                              />
                            </span>
                            <p>2022</p>
                          </li>
                          <li>
                            <span>
                              <ImageWithBasePath
                                src="assets/img/icons/car-parts-06.svg"
                                alt="Personas"
                              />
                            </span>
                            <p>5 Personas</p>
                          </li>
                        </ul>
                      </div>
                      <div className="listing-location-details">
                        <div className="listing-price">
                          <span>
                            <i className="icon-map-pin feather" />
                          </span>
                          Quito, ECU
                        </div>
                        <div className="listing-price">
                          <h6>
                            $160 <span>/ Dia</span>
                          </h6>
                        </div>
                      </div>
                      <div className="listing-button">
                        <Link
                          to={routes.listingdetails}
                          className="btn btn-order"
                        >
                          <span>
                            <i className="feather icon-calendar me-2" />
                          </span>
                          Rentar Ahora
                        </Link>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-12 col-lg-4 col-md-6" data-aos="fade-down">
                  <div className="listing-item">
                    <div className="listing-img">
                      <Link to={routes.listingdetails}>
                        <ImageWithBasePath
                          src="assets/img/cars/car-05.jpg"
                          className="img-fluid"
                          alt="Audi"
                        />
                      </Link>
                      <div
                        className="fav-item"
                        key={24}
                        onClick={() => handleItemClick(24)}
                      >
                        <span className="featured-text">Chevrolet</span>
                        <Link
                          to="#"
                          className={`fav-icon ${
                            selectedItems[24] ? "selected" : ""
                          }`}
                        >
                          <i className="feather icon-heart" />
                        </Link>
                      </div>
                    </div>
                    <div className="listing-content">
                      <div className="listing-features">
                        <Link to="#" className="author-img">
                          <ImageWithBasePath
                            src="assets/img/profiles/avatar-05.jpg"
                            alt="author"
                          />
                        </Link>
                        <h3 className="listing-title">
                          <Link to={routes.listingdetails}>
                            2018 Chevrolet Camaro
                          </Link>
                        </h3>
                        <div className="list-rating">
                          <i className="fa-star fas filled" />
                          <i className="fa-star fas filled" />
                          <i className="fa-star fas filled" />
                          <i className="fa-star fas filled" />
                          <i className="fa-star fas filled" />
                          <span>(5.0)</span>
                        </div>
                      </div>
                      <div className="listing-details-group">
                        <ul>
                          <li>
                            <span>
                              <ImageWithBasePath
                                src="assets/img/icons/car-parts-05.svg"
                                alt="Manual"
                              />
                            </span>
                            <p>Manual</p>
                          </li>
                          <li>
                            <span>
                              <ImageWithBasePath
                                src="assets/img/icons/car-parts-02.svg"
                                alt="18 KM"
                              />
                            </span>
                            <p>18 KM</p>
                          </li>
                          <li>
                            <span>
                              <ImageWithBasePath
                                src="assets/img/icons/car-parts-03.svg"
                                alt="Diesel"
                              />
                            </span>
                            <p>Diesel</p>
                          </li>
                        </ul>
                        <ul>
                          <li>
                            <span>
                              <ImageWithBasePath
                                src="assets/img/icons/car-parts-04.svg"
                                alt="Power"
                              />
                            </span>
                            <p>Fuerza</p>
                          </li>
                          <li>
                            <span>
                              <ImageWithBasePath
                                src="assets/img/icons/car-parts-05.svg"
                                alt=""
                              />
                            </span>
                            <p>2018</p>
                          </li>
                          <li>
                            <span>
                              <ImageWithBasePath
                                src="assets/img/icons/car-parts-06.svg"
                                alt="Personas"
                              />
                            </span>
                            <p>4 Personas</p>
                          </li>
                        </ul>
                      </div>
                      <div className="listing-location-details">
                        <div className="listing-price">
                          <span>
                            <i className="icon-map-pin feather" />
                          </span>
                          Ecuador
                        </div>
                        <div className="listing-price">
                          <h6>
                            $36 <span>/ Dia</span>
                          </h6>
                        </div>
                      </div>
                      <div className="listing-button">
                        <Link
                          to={routes.listingdetails}
                          className="btn btn-order"
                        >
                          <span>
                            <i className="feather icon-calendar me-2" />
                          </span>
                          Rentar Ahora
                        </Link>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-12 col-lg-4 col-md-6" data-aos="fade-down">
                  <div className="listing-item">
                    <div className="listing-img">
                      <Link to={routes.listingdetails}>
                        <ImageWithBasePath
                          src="assets/img/cars/car-06.jpg"
                          className="img-fluid"
                          alt="Audi"
                        />
                      </Link>
                      <div
                        className="fav-item"
                        key={25}
                        onClick={() => handleItemClick(25)}
                      >
                        <span className="featured-text">Acura</span>
                        <Link
                          to="#"
                          className={`fav-icon ${
                            selectedItems[25] ? "selected" : ""
                          }`}
                        >
                          <i className="feather icon-heart" />
                        </Link>
                      </div>
                    </div>
                    <div className="listing-content">
                      <div className="listing-features">
                        <Link to="#" className="author-img">
                          <ImageWithBasePath
                            src="assets/img/profiles/avatar-06.jpg"
                            alt="author"
                          />
                        </Link>
                        <h3 className="listing-title">
                          <Link to={routes.listingdetails}>
                            Acura Sport Version
                          </Link>
                        </h3>
                        <div className="list-rating">
                          <i className="fa-star fas filled" />
                          <i className="fa-star fas filled" />
                          <i className="fa-star fas filled" />
                          <i className="fa-star fas filled" />
                          <i className="fa-star fas filled" />
                          <span>(5.0)</span>
                        </div>
                      </div>
                      <div className="listing-details-group">
                        <ul>
                          <li>
                            <span>
                              <ImageWithBasePath
                                src="assets/img/icons/car-parts-01.svg"
                                alt="Auto"
                              />
                            </span>
                            <p>Auto</p>
                          </li>
                          <li>
                            <span>
                              <ImageWithBasePath
                                src="assets/img/icons/car-parts-02.svg"
                                alt="12 KM"
                              />
                            </span>
                            <p>12 KM</p>
                          </li>
                          <li>
                            <span>
                              <ImageWithBasePath
                                src="assets/img/icons/car-parts-03.svg"
                                alt="Diesel"
                              />
                            </span>
                            <p>Diesel</p>
                          </li>
                        </ul>
                        <ul>
                          <li>
                            <span>
                              <ImageWithBasePath
                                src="assets/img/icons/car-parts-04.svg"
                                alt="Power"
                              />
                            </span>
                            <p>Fuerza</p>
                          </li>
                          <li>
                            <span>
                              <ImageWithBasePath
                                src="assets/img/icons/car-parts-05.svg"
                                alt=""
                              />
                            </span>
                            <p>2013</p>
                          </li>
                          <li>
                            <span>
                              <ImageWithBasePath
                                src="assets/img/icons/car-parts-06.svg"
                                alt="Personas"
                              />
                            </span>
                            <p>5 Personas</p>
                          </li>
                        </ul>
                      </div>
                      <div className="listing-location-details">
                        <div className="listing-price">
                          <span>
                            <i className="icon-map-pin feather" />
                          </span>
                          Quito, ECU
                        </div>
                        <div className="listing-price">
                          <h6>
                            $30 <span>/ Dia</span>
                          </h6>
                        </div>
                      </div>
                      <div className="listing-button">
                        <Link
                          to={routes.listingdetails}
                          className="btn btn-order"
                        >
                          <span>
                            <i className="feather icon-calendar me-2" />
                          </span>
                          Rentar Ahora
                        </Link>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-12 col-lg-4 col-md-6" data-aos="fade-down">
                  <div className="listing-item">
                    <div className="listing-img">
                      <Link to={routes.listingdetails}>
                        <ImageWithBasePath
                          src="assets/img/cars/car-07.jpg"
                          className="img-fluid"
                          alt="Audi"
                        />
                      </Link>
                      <div
                        className="fav-item"
                        key={26}
                        onClick={() => handleItemClick(26)}
                      >
                        <span className="featured-text">Chevrolet</span>
                        <Link
                          to="#"
                          className={`fav-icon ${
                            selectedItems[26] ? "selected" : ""
                          }`}
                        >
                          <i className="feather icon-heart" />
                        </Link>
                      </div>
                    </div>
                    <div className="listing-content">
                      <div className="listing-features">
                        <Link to="#" className="author-img">
                          <ImageWithBasePath
                            src="assets/img/profiles/avatar-07.jpg"
                            alt="author"
                          />
                        </Link>
                        <h3 className="listing-title">
                          <Link to={routes.listingdetails}>
                            Chevrolet Pick Truck 3.5L
                          </Link>
                        </h3>
                        <div className="list-rating">
                          <i className="fa-star fas filled" />
                          <i className="fa-star fas filled" />
                          <i className="fa-star fas filled" />
                          <i className="fa-star fas filled" />
                          <i className="fa-star fas filled" />
                          <span>(5.0)</span>
                        </div>
                      </div>
                      <div className="listing-details-group">
                        <ul>
                          <li>
                            <span>
                              <ImageWithBasePath
                                src="assets/img/icons/car-parts-05.svg"
                                alt="Manual"
                              />
                            </span>
                            <p>Manual</p>
                          </li>
                          <li>
                            <span>
                              <ImageWithBasePath
                                src="assets/img/icons/car-parts-02.svg"
                                alt="10 KM"
                              />
                            </span>
                            <p>10 KM</p>
                          </li>
                          <li>
                            <span>
                              <ImageWithBasePath
                                src="assets/img/icons/car-parts-03.svg"
                                alt="Gasolina"
                              />
                            </span>
                            <p>Gasolina</p>
                          </li>
                        </ul>
                        <ul>
                          <li>
                            <span>
                              <ImageWithBasePath
                                src="assets/img/icons/car-parts-04.svg"
                                alt="Power"
                              />
                            </span>
                            <p>Fuerza</p>
                          </li>
                          <li>
                            <span>
                              <ImageWithBasePath
                                src="assets/img/icons/car-parts-05.svg"
                                alt=""
                              />
                            </span>
                            <p>2012</p>
                          </li>
                          <li>
                            <span>
                              <ImageWithBasePath
                                src="assets/img/icons/car-parts-06.svg"
                                alt="Personas"
                              />
                            </span>
                            <p>5 Personas</p>
                          </li>
                        </ul>
                      </div>
                      <div className="listing-location-details">
                        <div className="listing-price">
                          <span>
                            <i className="icon-map-pin feather" />
                          </span>
                          Spain
                        </div>
                        <div className="listing-price">
                          <h6>
                            $77 <span>/ Dia</span>
                          </h6>
                        </div>
                      </div>
                      <div className="listing-button">
                        <Link
                          to={routes.listingdetails}
                          className="btn btn-order"
                        >
                          <span>
                            <i className="feather icon-calendar me-2" />
                          </span>
                          Rentar Ahora
                        </Link>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-12 col-lg-4 col-md-6" data-aos="fade-down">
                  <div className="listing-item">
                    <div className="listing-img">
                      <Link to={routes.listingdetails}>
                        <ImageWithBasePath
                          src="assets/img/cars/car-09.jpg"
                          className="img-fluid"
                          alt="Toyota"
                        />
                      </Link>
                      <div
                        className="fav-item"
                        key={27}
                        onClick={() => handleItemClick(27)}
                      >
                        <span className="featured-text">Accura</span>
                        <Link
                          to="#"
                          className={`fav-icon ${
                            selectedItems[27] ? "selected" : ""
                          }`}
                        >
                          <i className="feather icon-heart" />
                        </Link>
                      </div>
                    </div>
                    <div className="listing-content">
                      <div className="listing-features">
                        <Link to="#" className="author-img">
                          <ImageWithBasePath
                            src="assets/img/profiles/avatar-08.jpg"
                            alt="author"
                          />
                        </Link>
                        <h3 className="listing-title">
                          <Link to={routes.listingdetails}>Acura RDX FWD</Link>
                        </h3>
                        <div className="list-rating">
                          <i className="fa-star fas filled" />
                          <i className="fa-star fas filled" />
                          <i className="fa-star fas filled" />
                          <i className="fa-star fas filled" />
                          <i className="fa-star fas filled" />
                          <span>(5.0)</span>
                        </div>
                      </div>
                      <div className="listing-details-group">
                        <ul>
                          <li>
                            <span>
                              <ImageWithBasePath
                                src="assets/img/icons/car-parts-01.svg"
                                alt="Auto"
                              />
                            </span>
                            <p>Auto</p>
                          </li>
                          <li>
                            <span>
                              <ImageWithBasePath
                                src="assets/img/icons/car-parts-02.svg"
                                alt="22 miles"
                              />
                            </span>
                            <p>42 miles</p>
                          </li>
                          <li>
                            <span>
                              <ImageWithBasePath
                                src="assets/img/icons/car-parts-03.svg"
                                alt="Gasolina"
                              />
                            </span>
                            <p>Gasolina</p>
                          </li>
                        </ul>
                        <ul>
                          <li>
                            <span>
                              <ImageWithBasePath
                                src="assets/img/icons/car-parts-04.svg"
                                alt="Power"
                              />
                            </span>
                            <p>Fuerza</p>
                          </li>
                          <li>
                            <span>
                              <ImageWithBasePath
                                src="assets/img/icons/car-parts-05.svg"
                                alt=""
                              />
                            </span>
                            <p>2021</p>
                          </li>
                          <li>
                            <span>
                              <ImageWithBasePath
                                src="assets/img/icons/car-parts-06.svg"
                                alt="Personas"
                              />
                            </span>
                            <p>5 Personas</p>
                          </li>
                        </ul>
                      </div>
                      <div className="listing-location-details">
                        <div className="listing-price">
                          <span>
                            <i className="icon-map-pin feather" />
                          </span>
                          Dallas, USA
                        </div>
                        <div className="listing-price">
                          <h6>
                            $80 <span>/ Dia</span>
                          </h6>
                        </div>
                      </div>
                      <div className="listing-button">
                        <Link
                          to={routes.listingdetails}
                          className="btn btn-order"
                        >
                          <span>
                            <i className="feather icon-calendar me-2" />
                          </span>
                          Rentar Ahora
                        </Link>
                      </div>
                    </div>
                  </div>
                </div>
                {/* /col */}
              </div>
            </div>
            <div className="fade tab-pane" id="Cartoyota">
              <div className="row">
                {/* col */}
                <div className="col-12 col-lg-4 col-md-6" data-aos="fade-down">
                  <div className="listing-item">
                    <div className="listing-img">
                      <Link to={routes.listingdetails}>
                        <ImageWithBasePath
                          src="assets/img/cars/car-02.png"
                          className="img-fluid"
                          alt="Toyota"
                        />
                      </Link>
                      <div
                        className="fav-item"
                        key={28}
                        onClick={() => handleItemClick(28)}
                      >
                        <span className="featured-text">Toyota</span>
                        <Link
                          to="#"
                          className={`fav-icon ${
                            selectedItems[28] ? "selected" : ""
                          }`}
                        >
                          <i className="feather icon-heart" />
                        </Link>
                      </div>
                    </div>
                    <div className="listing-content">
                      <div className="listing-features">
                        <Link to="#" className="author-img">
                          <ImageWithBasePath
                            src="assets/img/profiles/avatar-0.jpg"
                            alt="author"
                          />
                        </Link>
                        <h3 className="listing-title">
                          <Link to={routes.listingdetails}>
                            Toyota Camry SE 350
                          </Link>
                        </h3>
                        <div className="list-rating">
                          <i className="fa-star fas filled" />
                          <i className="fa-star fas filled" />
                          <i className="fa-star fas filled" />
                          <i className="fa-star fas filled" />
                          <i className="fa-star fas filled" />
                          <span>(5.0)</span>
                        </div>
                      </div>
                      <div className="listing-details-group">
                        <ul>
                          <li>
                            <span>
                              <ImageWithBasePath
                                src="assets/img/icons/car-parts-01.svg"
                                alt="Auto"
                              />
                            </span>
                            <p>Auto</p>
                          </li>
                          <li>
                            <span>
                              <ImageWithBasePath
                                src="assets/img/icons/car-parts-02.svg"
                                alt="10 KM"
                              />
                            </span>
                            <p>10 KM</p>
                          </li>
                          <li>
                            <span>
                              <ImageWithBasePath
                                src="assets/img/icons/car-parts-03.svg"
                                alt="Gasolina"
                              />
                            </span>
                            <p>Gasolina</p>
                          </li>
                        </ul>
                        <ul>
                          <li>
                            <span>
                              <ImageWithBasePath
                                src="assets/img/icons/car-parts-04.svg"
                                alt="Power"
                              />
                            </span>
                            <p>Fuerza</p>
                          </li>
                          <li>
                            <span>
                              <ImageWithBasePath
                                src="assets/img/icons/car-parts-05.svg"
                                alt=""
                              />
                            </span>
                            <p>2018</p>
                          </li>
                          <li>
                            <span>
                              <ImageWithBasePath
                                src="assets/img/icons/car-parts-06.svg"
                                alt="Personas"
                              />
                            </span>
                            <p>5 Personas</p>
                          </li>
                        </ul>
                      </div>
                      <div className="listing-location-details">
                        <div className="listing-price">
                          <span>
                            <i className="icon-map-pin feather" />
                          </span>
                          Ecuador
                        </div>
                        <div className="listing-price">
                          <h6>
                            $400 <span>/ Dia</span>
                          </h6>
                        </div>
                      </div>
                      <div className="listing-button">
                        <Link
                          to={routes.listingdetails}
                          className="btn btn-order"
                        >
                          <span>
                            <i className="feather icon-calendar me-2" />
                          </span>
                          Rentar Ahora
                        </Link>
                      </div>
                    </div>
                  </div>
                </div>
                {/* /col */}
                {/* col */}
                <div className="col-12 col-lg-4 col-md-6" data-aos="fade-down">
                  <div className="listing-item">
                    <div className="listing-img">
                      <Link to={routes.listingdetails}>
                        <ImageWithBasePath
                          src="assets/img/cars/car-09.webp"
                          className="img-fluid"
                          alt="KIA"
                        />
                      </Link>
                      <div
                        className="fav-item"
                        key={29}
                        onClick={() => handleItemClick(29)}
                      >
                        <span className="featured-text">KIA</span>
                        <Link
                          to="#"
                          className={`fav-icon ${
                            selectedItems[29] ? "selected" : ""
                          }`}
                        >
                          <i className="feather icon-heart" />
                        </Link>
                      </div>
                    </div>
                    <div className="listing-content">
                      <div className="listing-features">
                        <Link to="#" className="author-img">
                          <ImageWithBasePath
                            src="assets/img/profiles/avatar-02.jpg"
                            alt="author"
                          />
                        </Link>
                        <h3 className="listing-title">
                          <Link to={routes.listingdetails}>Kia Soul 2016</Link>
                        </h3>
                        <div className="list-rating">
                          <i className="fa-star fas filled" />
                          <i className="fa-star fas filled" />
                          <i className="fa-star fas filled" />
                          <i className="fa-star fas filled" />
                          <i className="fa-star fas filled" />
                          <span>(5.0)</span>
                        </div>
                      </div>
                      <div className="listing-details-group">
                        <ul>
                          <li>
                            <span>
                              <ImageWithBasePath
                                src="assets/img/icons/car-parts-01.svg"
                                alt="Auto"
                              />
                            </span>
                            <p>Auto</p>
                          </li>
                          <li>
                            <span>
                              <ImageWithBasePath
                                src="assets/img/icons/car-parts-02.svg"
                                alt="22 KM"
                              />
                            </span>
                            <p>22 KM</p>
                          </li>
                          <li>
                            <span>
                              <ImageWithBasePath
                                src="assets/img/icons/car-parts-03.svg"
                                alt="Gasolina"
                              />
                            </span>
                            <p>Gasolina</p>
                          </li>
                        </ul>
                        <ul>
                          <li>
                            <span>
                              <ImageWithBasePath
                                src="assets/img/icons/car-parts-04.svg"
                                alt="Diesel"
                              />
                            </span>
                            <p>Diesel</p>
                          </li>
                          <li>
                            <span>
                              <ImageWithBasePath
                                src="assets/img/icons/car-parts-05.svg"
                                alt=""
                              />
                            </span>
                            <p>2016</p>
                          </li>
                          <li>
                            <span>
                              <ImageWithBasePath
                                src="assets/img/icons/car-parts-06.svg"
                                alt="Personas"
                              />
                            </span>
                            <p>5 Personas</p>
                          </li>
                        </ul>
                      </div>
                      <div className="listing-location-details">
                        <div className="listing-price">
                          <span>
                            <i className="icon-map-pin feather" />
                          </span>
                          Belgium
                        </div>
                        <div className="listing-price">
                          <h6>
                            $80 <span>/ Dia</span>
                          </h6>
                        </div>
                      </div>
                      <div className="listing-button">
                        <Link
                          to={routes.listingdetails}
                          className="btn btn-order"
                        >
                          <span>
                            <i className="feather icon-calendar me-2" />
                          </span>
                          Rentar Ahora
                        </Link>
                      </div>
                    </div>
                  </div>
                </div>
                {/* /col */}
                {/* col */}
                <div className="col-12 col-lg-4 col-md-6" data-aos="fade-down">
                  <div className="listing-item">
                    <div className="listing-img">
                      <Link to={routes.listingdetails}>
                        <ImageWithBasePath
                          src="assets/img/cars/car-02.png"
                          className="img-fluid"
                          alt="Audi"
                        />
                      </Link>
                      <div
                        className="fav-item"
                        key={30}
                        onClick={() => handleItemClick(30)}
                      >
                        <span className="featured-text">Audi</span>
                        <Link
                          to="#"
                          className={`fav-icon ${
                            selectedItems[30] ? "selected" : ""
                          }`}
                        >
                          <i className="feather icon-heart" />
                        </Link>
                      </div>
                    </div>
                    <div className="listing-content">
                      <div className="listing-features">
                        <Link to="#" className="author-img">
                          <ImageWithBasePath
                            src="assets/img/profiles/avatar-03.jpg"
                            alt="author"
                          />
                        </Link>
                        <h3 className="listing-title">
                          <Link to={routes.listingdetails}>
                            Audi A3 2019 new
                          </Link>
                        </h3>
                        <div className="list-rating">
                          <i className="fa-star fas filled" />
                          <i className="fa-star fas filled" />
                          <i className="fa-star fas filled" />
                          <i className="fa-star fas filled" />
                          <i className="fa-star fas filled" />
                          <span>(5.0)</span>
                        </div>
                      </div>
                      <div className="listing-details-group">
                        <ul>
                          <li>
                            <span>
                              <ImageWithBasePath
                                src="assets/img/icons/car-parts-05.svg"
                                alt="Manual"
                              />
                            </span>
                            <p>Manual</p>
                          </li>
                          <li>
                            <span>
                              <ImageWithBasePath
                                src="assets/img/icons/car-parts-02.svg"
                                alt="10 KM"
                              />
                            </span>
                            <p>10 KM</p>
                          </li>
                          <li>
                            <span>
                              <ImageWithBasePath
                                src="assets/img/icons/car-parts-03.svg"
                                alt="Gasolina"
                              />
                            </span>
                            <p>Gasolina</p>
                          </li>
                        </ul>
                        <ul>
                          <li>
                            <span>
                              <ImageWithBasePath
                                src="assets/img/icons/car-parts-04.svg"
                                alt="Power"
                              />
                            </span>
                            <p>Fuerza</p>
                          </li>
                          <li>
                            <span>
                              <ImageWithBasePath
                                src="assets/img/icons/car-parts-05.svg"
                                alt=""
                              />
                            </span>
                            <p>2019</p>
                          </li>
                          <li>
                            <span>
                              <ImageWithBasePath
                                src="assets/img/icons/car-parts-06.svg"
                                alt="Personas"
                              />
                            </span>
                            <p>4 Personas</p>
                          </li>
                        </ul>
                      </div>
                      <div className="listing-location-details">
                        <div className="listing-price">
                          <span>
                            <i className="icon-map-pin feather" />
                          </span>
                          Quito, ECU
                        </div>
                        <div className="listing-price">
                          <h6>
                            $45 <span>/ Dia</span>
                          </h6>
                        </div>
                      </div>
                      <div className="listing-button">
                        <Link
                          to={routes.listingdetails}
                          className="btn btn-order"
                        >
                          <span>
                            <i className="feather icon-calendar me-2" />
                          </span>
                          Rentar Ahora
                        </Link>
                      </div>
                    </div>
                  </div>
                </div>
                {/*
                <div className="col-12 col-lg-4 col-md-6" data-aos="fade-down">
                  <div className="listing-item">
                    <div className="listing-img">
                      <Link to={routes.listingdetails}>
                        <ImageWithBasePath
                          src="assets/img/cars/car-04.jpg"
                          className="img-fluid"
                          alt="Audi"
                        />
                      </Link>
                      <div
                        className="fav-item"
                        key={31}
                        onClick={() => handleItemClick(31)}
                      >
                        <span className="featured-text">Ferrai</span>
                        <Link
                          to="#"
                          className={`fav-icon ${
                            selectedItems[31] ? "selected" : ""
                          }`}
                        >
                          <i className="feather icon-heart" />
                        </Link>
                      </div>
                    </div>
                    <div className="listing-content">
                      <div className="listing-features">
                        <Link to="#" className="author-img">
                          <ImageWithBasePath
                            src="assets/img/profiles/avatar-04.jpg"
                            alt="author"
                          />
                        </Link>
                        <h3 className="listing-title">
                          <Link to={routes.listingdetails}>
                            Ferrari 458 MM Special
                          </Link>
                        </h3>
                        <div className="list-rating">
                          <i className="fa-star fas filled" />
                          <i className="fa-star fas filled" />
                          <i className="fa-star fas filled" />
                          <i className="fa-star fas filled" />
                          <i className="fa-star fas filled" />
                          <span>(5.0)</span>
                        </div>
                      </div>
                      <div className="listing-details-group">
                        <ul>
                          <li>
                            <span>
                              <ImageWithBasePath
                                src="assets/img/icons/car-parts-05.svg"
                                alt="Manual"
                              />
                            </span>
                            <p>Manual</p>
                          </li>
                          <li>
                            <span>
                              <ImageWithBasePath
                                src="assets/img/icons/car-parts-02.svg"
                                alt="14 KM"
                              />
                            </span>
                            <p>14 KM</p>
                          </li>
                          <li>
                            <span>
                              <ImageWithBasePath
                                src="assets/img/icons/car-parts-03.svg"
                                alt="Diesel"
                              />
                            </span>
                            <p>Diesel</p>
                          </li>
                        </ul>
                        <ul>
                          <li>
                            <span>
                              <ImageWithBasePath
                                src="assets/img/icons/car-parts-04.svg"
                                alt="Básico"
                              />
                            </span>
                            <p>Básico</p>
                          </li>
                          <li>
                            <span>
                              <ImageWithBasePath
                                src="assets/img/icons/car-parts-05.svg"
                                alt=""
                              />
                            </span>
                            <p>2022</p>
                          </li>
                          <li>
                            <span>
                              <ImageWithBasePath
                                src="assets/img/icons/car-parts-06.svg"
                                alt="Personas"
                              />
                            </span>
                            <p>5 Personas</p>
                          </li>
                        </ul>
                      </div>
                      <div className="listing-location-details">
                        <div className="listing-price">
                          <span>
                            <i className="icon-map-pin feather" />
                          </span>
                          Quito, ECU
                        </div>
                        <div className="listing-price">
                          <h6>
                            $160 <span>/ Dia</span>
                          </h6>
                        </div>
                      </div>
                      <div className="listing-button">
                        <Link
                          to={routes.listingdetails}
                          className="btn btn-order"
                        >
                          <span>
                            <i className="feather icon-calendar me-2" />
                          </span>
                          Rentar Ahora
                        </Link>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-12 col-lg-4 col-md-6" data-aos="fade-down">
                  <div className="listing-item">
                    <div className="listing-img">
                      <Link to={routes.listingdetails}>
                        <ImageWithBasePath
                          src="assets/img/cars/car-05.jpg"
                          className="img-fluid"
                          alt="Audi"
                        />
                      </Link>
                      <div
                        className="fav-item"
                        key={32}
                        onClick={() => handleItemClick(32)}
                      >
                        <span className="featured-text">Chevrolet</span>
                        <Link
                          to="#"
                          className={`fav-icon ${
                            selectedItems[32] ? "selected" : ""
                          }`}
                        >
                          <i className="feather icon-heart" />
                        </Link>
                      </div>
                    </div>
                    <div className="listing-content">
                      <div className="listing-features">
                        <Link to="#" className="author-img">
                          <ImageWithBasePath
                            src="assets/img/profiles/avatar-05.jpg"
                            alt="author"
                          />
                        </Link>
                        <h3 className="listing-title">
                          <Link to={routes.listingdetails}>
                            2018 Chevrolet Camaro
                          </Link>
                        </h3>
                        <div className="list-rating">
                          <i className="fa-star fas filled" />
                          <i className="fa-star fas filled" />
                          <i className="fa-star fas filled" />
                          <i className="fa-star fas filled" />
                          <i className="fa-star fas filled" />
                          <span>(5.0)</span>
                        </div>
                      </div>
                      <div className="listing-details-group">
                        <ul>
                          <li>
                            <span>
                              <ImageWithBasePath
                                src="assets/img/icons/car-parts-05.svg"
                                alt="Manual"
                              />
                            </span>
                            <p>Manual</p>
                          </li>
                          <li>
                            <span>
                              <ImageWithBasePath
                                src="assets/img/icons/car-parts-02.svg"
                                alt="18 KM"
                              />
                            </span>
                            <p>18 KM</p>
                          </li>
                          <li>
                            <span>
                              <ImageWithBasePath
                                src="assets/img/icons/car-parts-03.svg"
                                alt="Diesel"
                              />
                            </span>
                            <p>Diesel</p>
                          </li>
                        </ul>
                        <ul>
                          <li>
                            <span>
                              <ImageWithBasePath
                                src="assets/img/icons/car-parts-04.svg"
                                alt="Power"
                              />
                            </span>
                            <p>Fuerza</p>
                          </li>
                          <li>
                            <span>
                              <ImageWithBasePath
                                src="assets/img/icons/car-parts-05.svg"
                                alt=""
                              />
                            </span>
                            <p>2018</p>
                          </li>
                          <li>
                            <span>
                              <ImageWithBasePath
                                src="assets/img/icons/car-parts-06.svg"
                                alt="Personas"
                              />
                            </span>
                            <p>4 Personas</p>
                          </li>
                        </ul>
                      </div>
                      <div className="listing-location-details">
                        <div className="listing-price">
                          <span>
                            <i className="icon-map-pin feather" />
                          </span>
                          Ecuador
                        </div>
                        <div className="listing-price">
                          <h6>
                            $36 <span>/ Dia</span>
                          </h6>
                        </div>
                      </div>
                      <div className="listing-button">
                        <Link
                          to={routes.listingdetails}
                          className="btn btn-order"
                        >
                          <span>
                            <i className="feather icon-calendar me-2" />
                          </span>
                          Rentar Ahora
                        </Link>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-12 col-lg-4 col-md-6" data-aos="fade-down">
                  <div className="listing-item">
                    <div className="listing-img">
                      <Link to={routes.listingdetails}>
                        <ImageWithBasePath
                          src="assets/img/cars/car-06.jpg"
                          className="img-fluid"
                          alt="Audi"
                        />
                      </Link>
                      <div
                        className="fav-item"
                        key={33}
                        onClick={() => handleItemClick(33)}
                      >
                        <span className="featured-text">Acura</span>
                        <Link
                          to="#"
                          className={`fav-icon ${
                            selectedItems[33] ? "selected" : ""
                          }`}
                        >
                          <i className="feather icon-heart" />
                        </Link>
                      </div>
                    </div>
                    <div className="listing-content">
                      <div className="listing-features">
                        <Link to="#" className="author-img">
                          <ImageWithBasePath
                            src="assets/img/profiles/avatar-06.jpg"
                            alt="author"
                          />
                        </Link>
                        <h3 className="listing-title">
                          <Link to={routes.listingdetails}>
                            Acura Sport Version
                          </Link>
                        </h3>
                        <div className="list-rating">
                          <i className="fa-star fas filled" />
                          <i className="fa-star fas filled" />
                          <i className="fa-star fas filled" />
                          <i className="fa-star fas filled" />
                          <i className="fa-star fas filled" />
                          <span>(5.0)</span>
                        </div>
                      </div>
                      <div className="listing-details-group">
                        <ul>
                          <li>
                            <span>
                              <ImageWithBasePath
                                src="assets/img/icons/car-parts-01.svg"
                                alt="Auto"
                              />
                            </span>
                            <p>Auto</p>
                          </li>
                          <li>
                            <span>
                              <ImageWithBasePath
                                src="assets/img/icons/car-parts-02.svg"
                                alt="12 KM"
                              />
                            </span>
                            <p>12 KM</p>
                          </li>
                          <li>
                            <span>
                              <ImageWithBasePath
                                src="assets/img/icons/car-parts-03.svg"
                                alt="Diesel"
                              />
                            </span>
                            <p>Diesel</p>
                          </li>
                        </ul>
                        <ul>
                          <li>
                            <span>
                              <ImageWithBasePath
                                src="assets/img/icons/car-parts-04.svg"
                                alt="Power"
                              />
                            </span>
                            <p>Fuerza</p>
                          </li>
                          <li>
                            <span>
                              <ImageWithBasePath
                                src="assets/img/icons/car-parts-05.svg"
                                alt=""
                              />
                            </span>
                            <p>2013</p>
                          </li>
                          <li>
                            <span>
                              <ImageWithBasePath
                                src="assets/img/icons/car-parts-06.svg"
                                alt="Personas"
                              />
                            </span>
                            <p>5 Personas</p>
                          </li>
                        </ul>
                      </div>
                      <div className="listing-location-details">
                        <div className="listing-price">
                          <span>
                            <i className="icon-map-pin feather" />
                          </span>
                          Quito, ECU
                        </div>
                        <div className="listing-price">
                          <h6>
                            $30 <span>/ Dia</span>
                          </h6>
                        </div>
                      </div>
                      <div className="listing-button">
                        <Link
                          to={routes.listingdetails}
                          className="btn btn-order"
                        >
                          <span>
                            <i className="feather icon-calendar me-2" />
                          </span>
                          Rentar Ahora
                        </Link>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-12 col-lg-4 col-md-6" data-aos="fade-down">
                  <div className="listing-item">
                    <div className="listing-img">
                      <Link to={routes.listingdetails}>
                        <ImageWithBasePath
                          src="assets/img/cars/car-07.jpg"
                          className="img-fluid"
                          alt="Audi"
                        />
                      </Link>
                      <div
                        className="fav-item"
                        key={34}
                        onClick={() => handleItemClick(34)}
                      >
                        <span className="featured-text">Chevrolet</span>
                        <Link
                          to="#"
                          className={`fav-icon ${
                            selectedItems[34] ? "selected" : ""
                          }`}
                        >
                          <i className="feather icon-heart" />
                        </Link>
                      </div>
                    </div>
                    <div className="listing-content">
                      <div className="listing-features">
                        <Link to="#" className="author-img">
                          <ImageWithBasePath
                            src="assets/img/profiles/avatar-07.jpg"
                            alt="author"
                          />
                        </Link>
                        <h3 className="listing-title">
                          <Link to={routes.listingdetails}>
                            Chevrolet Pick Truck 3.5L
                          </Link>
                        </h3>
                        <div className="list-rating">
                          <i className="fa-star fas filled" />
                          <i className="fa-star fas filled" />
                          <i className="fa-star fas filled" />
                          <i className="fa-star fas filled" />
                          <i className="fa-star fas filled" />
                          <span>(5.0)</span>
                        </div>
                      </div>
                      <div className="listing-details-group">
                        <ul>
                          <li>
                            <span>
                              <ImageWithBasePath
                                src="assets/img/icons/car-parts-05.svg"
                                alt="Manual"
                              />
                            </span>
                            <p>Manual</p>
                          </li>
                          <li>
                            <span>
                              <ImageWithBasePath
                                src="assets/img/icons/car-parts-02.svg"
                                alt="10 KM"
                              />
                            </span>
                            <p>10 KM</p>
                          </li>
                          <li>
                            <span>
                              <ImageWithBasePath
                                src="assets/img/icons/car-parts-03.svg"
                                alt="Gasolina"
                              />
                            </span>
                            <p>Gasolina</p>
                          </li>
                        </ul>
                        <ul>
                          <li>
                            <span>
                              <ImageWithBasePath
                                src="assets/img/icons/car-parts-04.svg"
                                alt="Power"
                              />
                            </span>
                            <p>Fuerza</p>
                          </li>
                          <li>
                            <span>
                              <ImageWithBasePath
                                src="assets/img/icons/car-parts-05.svg"
                                alt=""
                              />
                            </span>
                            <p>2012</p>
                          </li>
                          <li>
                            <span>
                              <ImageWithBasePath
                                src="assets/img/icons/car-parts-06.svg"
                                alt="Personas"
                              />
                            </span>
                            <p>5 Personas</p>
                          </li>
                        </ul>
                      </div>
                      <div className="listing-location-details">
                        <div className="listing-price">
                          <span>
                            <i className="icon-map-pin feather" />
                          </span>
                          Spain
                        </div>
                        <div className="listing-price">
                          <h6>
                            $77 <span>/ Dia</span>
                          </h6>
                        </div>
                      </div>
                      <div className="listing-button">
                        <Link
                          to={routes.listingdetails}
                          className="btn btn-order"
                        >
                          <span>
                            <i className="feather icon-calendar me-2" />
                          </span>
                          Rentar Ahora
                        </Link>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-12 col-lg-4 col-md-6" data-aos="fade-down">
                  <div className="listing-item">
                    <div className="listing-img">
                      <Link to={routes.listingdetails}>
                        <ImageWithBasePath
                          src="assets/img/cars/car-08.jpg"
                          className="img-fluid"
                          alt="Toyota"
                        />
                      </Link>
                      <div
                        className="fav-item"
                        key={35}
                        onClick={() => handleItemClick(35)}
                      >
                        <span className="featured-text">Toyota</span>
                        <Link
                          to="#"
                          className={`fav-icon ${
                            selectedItems[35] ? "selected" : ""
                          }`}
                        >
                          <i className="feather icon-heart" />
                        </Link>
                      </div>
                    </div>
                    <div className="listing-content">
                      <div className="listing-features">
                        <Link to="#" className="author-img">
                          <ImageWithBasePath
                            src="assets/img/profiles/avatar-08.jpg"
                            alt="author"
                          />
                        </Link>
                        <h3 className="listing-title">
                          <Link to={routes.listingdetails}>
                            Toyota Tacoma 4WD
                          </Link>
                        </h3>
                        <div className="list-rating">
                          <i className="fa-star fas filled" />
                          <i className="fa-star fas filled" />
                          <i className="fa-star fas filled" />
                          <i className="fa-star fas filled" />
                          <i className="fa-star fas filled" />
                          <span>(5.0)</span>
                        </div>
                      </div>
                      <div className="listing-details-group">
                        <ul>
                          <li>
                            <span>
                              <ImageWithBasePath
                                src="assets/img/icons/car-parts-01.svg"
                                alt="Auto"
                              />
                            </span>
                            <p>Auto</p>
                          </li>
                          <li>
                            <span>
                              <ImageWithBasePath
                                src="assets/img/icons/car-parts-02.svg"
                                alt="22 miles"
                              />
                            </span>
                            <p>22 miles</p>
                          </li>
                          <li>
                            <span>
                              <ImageWithBasePath
                                src="assets/img/icons/car-parts-03.svg"
                                alt="Diesel"
                              />
                            </span>
                            <p>Diesel</p>
                          </li>
                        </ul>
                        <ul>
                          <li>
                            <span>
                              <ImageWithBasePath
                                src="assets/img/icons/car-parts-04.svg"
                                alt="Power"
                              />
                            </span>
                            <p>Fuerza</p>
                          </li>
                          <li>
                            <span>
                              <ImageWithBasePath
                                src="assets/img/icons/car-parts-05.svg"
                                alt=""
                              />
                            </span>
                            <p>2019</p>
                          </li>
                          <li>
                            <span>
                              <ImageWithBasePath
                                src="assets/img/icons/car-parts-06.svg"
                                alt="Personas"
                              />
                            </span>
                            <p>5 Personas</p>
                          </li>
                        </ul>
                      </div>
                      <div className="listing-location-details">
                        <div className="listing-price">
                          <span>
                            <i className="icon-map-pin feather" />
                          </span>
                          Dallas, USA
                        </div>
                        <div className="listing-price">
                          <h6>
                            $30 <span>/ Dia</span>
                          </h6>
                        </div>
                      </div>
                      <div className="listing-button">
                        <Link
                          to={routes.listingdetails}
                          className="btn btn-order"
                        >
                          <span>
                            <i className="feather icon-calendar me-2" />
                          </span>
                          Rentar Ahora
                        </Link>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-12 col-lg-4 col-md-6" data-aos="fade-down">
                  <div className="listing-item">
                    <div className="listing-img">
                      <Link to={routes.listingdetails}>
                        <ImageWithBasePath
                          src="assets/img/cars/car-09.jpg"
                          className="img-fluid"
                          alt="Toyota"
                        />
                      </Link>
                      <div
                        className="fav-item"
                        key={36}
                        onClick={() => handleItemClick(36)}
                      >
                        <span className="featured-text">Accura</span>
                        <Link
                          to="#"
                          className={`fav-icon ${
                            selectedItems[36] ? "selected" : ""
                          }`}
                        >
                          <i className="feather icon-heart" />
                        </Link>
                      </div>
                    </div>
                    <div className="listing-content">
                      <div className="listing-features">
                        <Link to="#" className="author-img">
                          <ImageWithBasePath
                            src="assets/img/profiles/avatar-10.jpg"
                            alt="author"
                          />
                        </Link>
                        <h3 className="listing-title">
                          <Link to={routes.listingdetails}>Acura RDX FWD</Link>
                        </h3>
                        <div className="list-rating">
                          <i className="fa-star fas filled" />
                          <i className="fa-star fas filled" />
                          <i className="fa-star fas filled" />
                          <i className="fa-star fas filled" />
                          <i className="fa-star fas filled" />
                          <span>(5.0)</span>
                        </div>
                      </div>
                      <div className="listing-details-group">
                        <ul>
                          <li>
                            <span>
                              <ImageWithBasePath
                                src="assets/img/icons/car-parts-01.svg"
                                alt="Auto"
                              />
                            </span>
                            <p>Auto</p>
                          </li>
                          <li>
                            <span>
                              <ImageWithBasePath
                                src="assets/img/icons/car-parts-02.svg"
                                alt="22 miles"
                              />
                            </span>
                            <p>42 miles</p>
                          </li>
                          <li>
                            <span>
                              <ImageWithBasePath
                                src="assets/img/icons/car-parts-03.svg"
                                alt="Gasolina"
                              />
                            </span>
                            <p>Gasolina</p>
                          </li>
                        </ul>
                        <ul>
                          <li>
                            <span>
                              <ImageWithBasePath
                                src="assets/img/icons/car-parts-04.svg"
                                alt="Power"
                              />
                            </span>
                            <p>Fuerza</p>
                          </li>
                          <li>
                            <span>
                              <ImageWithBasePath
                                src="assets/img/icons/car-parts-05.svg"
                                alt=""
                              />
                            </span>
                            <p>2021</p>
                          </li>
                          <li>
                            <span>
                              <ImageWithBasePath
                                src="assets/img/icons/car-parts-06.svg"
                                alt="Personas"
                              />
                            </span>
                            <p>5 Personas</p>
                          </li>
                        </ul>
                      </div>
                      <div className="listing-location-details">
                        <div className="listing-price">
                          <span>
                            <i className="icon-map-pin feather" />
                          </span>
                          Dallas, USA
                        </div>
                        <div className="listing-price">
                          <h6>
                            $80 <span>/ Dia</span>
                          </h6>
                        </div>
                      </div>
                      <div className="listing-button">
                        <Link
                          to={routes.listingdetails}
                          className="btn btn-order"
                        >
                          <span>
                            <i className="feather icon-calendar me-2" />
                          </span>
                          Rentar Ahora
                        </Link>
                      </div>
                    </div>
                  </div>
                </div>
                {/* /col */}
              </div>
            </div>
            <div className="fade tab-pane" id="Caracura">
              <div className="row">
                {/* col */}
                <div className="col-12 col-lg-4 col-md-6" data-aos="fade-down">
                  <div className="listing-item">
                    <div className="listing-img">
                      <Link to={routes.listingdetails}>
                        <ImageWithBasePath
                          src="assets/img/cars/car-09.webp"
                          className="img-fluid"
                          alt="Toyota"
                        />
                      </Link>
                      <div
                        className="fav-item"
                        key={37}
                        onClick={() => handleItemClick(37)}
                      >
                        <span className="featured-text">Toyota</span>
                        <Link
                          to="#"
                          className={`fav-icon ${
                            selectedItems[37] ? "selected" : ""
                          }`}
                        >
                          <i className="feather icon-heart" />
                        </Link>
                      </div>
                    </div>
                    <div className="listing-content">
                      <div className="listing-features">
                        <Link to="#" className="author-img">
                          <ImageWithBasePath
                            src="assets/img/profiles/avatar-0.jpg"
                            alt="author"
                          />
                        </Link>
                        <h3 className="listing-title">
                          <Link to={routes.listingdetails}>
                            Toyota Camry SE 350
                          </Link>
                        </h3>
                        <div className="list-rating">
                          <i className="fa-star fas filled" />
                          <i className="fa-star fas filled" />
                          <i className="fa-star fas filled" />
                          <i className="fa-star fas filled" />
                          <i className="fa-star fas filled" />
                          <span>(5.0)</span>
                        </div>
                      </div>
                      <div className="listing-details-group">
                        <ul>
                          <li>
                            <span>
                              <ImageWithBasePath
                                src="assets/img/icons/car-parts-01.svg"
                                alt="Auto"
                              />
                            </span>
                            <p>Auto</p>
                          </li>
                          <li>
                            <span>
                              <ImageWithBasePath
                                src="assets/img/icons/car-parts-02.svg"
                                alt="10 KM"
                              />
                            </span>
                            <p>10 KM</p>
                          </li>
                          <li>
                            <span>
                              <ImageWithBasePath
                                src="assets/img/icons/car-parts-03.svg"
                                alt="Gasolina"
                              />
                            </span>
                            <p>Gasolina</p>
                          </li>
                        </ul>
                        <ul>
                          <li>
                            <span>
                              <ImageWithBasePath
                                src="assets/img/icons/car-parts-04.svg"
                                alt="Power"
                              />
                            </span>
                            <p>Fuerza</p>
                          </li>
                          <li>
                            <span>
                              <ImageWithBasePath
                                src="assets/img/icons/car-parts-05.svg"
                                alt=""
                              />
                            </span>
                            <p>2018</p>
                          </li>
                          <li>
                            <span>
                              <ImageWithBasePath
                                src="assets/img/icons/car-parts-06.svg"
                                alt="Personas"
                              />
                            </span>
                            <p>5 Personas</p>
                          </li>
                        </ul>
                      </div>
                      <div className="listing-location-details">
                        <div className="listing-price">
                          <span>
                            <i className="icon-map-pin feather" />
                          </span>
                          Ecuador
                        </div>
                        <div className="listing-price">
                          <h6>
                            $400 <span>/ Dia</span>
                          </h6>
                        </div>
                      </div>
                      <div className="listing-button">
                        <Link
                          to={routes.listingdetails}
                          className="btn btn-order"
                        >
                          <span>
                            <i className="feather icon-calendar me-2" />
                          </span>
                          Rentar Ahora
                        </Link>
                      </div>
                    </div>
                  </div>
                </div>
                {/* /col */}
                {/* col */}
                <div className="col-12 col-lg-4 col-md-6" data-aos="fade-down">
                  <div className="listing-item">
                    <div className="listing-img">
                      <Link to={routes.listingdetails}>
                        <ImageWithBasePath
                          src="assets/img/cars/car-02.png"
                          className="img-fluid"
                          alt="KIA"
                        />
                      </Link>
                      <div
                        className="fav-item"
                        key={38}
                        onClick={() => handleItemClick(38)}
                      >
                        <span className="featured-text">KIA</span>
                        <Link
                          to="#"
                          className={`fav-icon ${
                            selectedItems[38] ? "selected" : ""
                          }`}
                        >
                          <i className="feather icon-heart" />
                        </Link>
                      </div>
                    </div>
                    <div className="listing-content">
                      <div className="listing-features">
                        <Link to="#" className="author-img">
                          <ImageWithBasePath
                            src="assets/img/profiles/avatar-02.jpg"
                            alt="author"
                          />
                        </Link>
                        <h3 className="listing-title">
                          <Link to={routes.listingdetails}>Kia Soul 2016</Link>
                        </h3>
                        <div className="list-rating">
                          <i className="fa-star fas filled" />
                          <i className="fa-star fas filled" />
                          <i className="fa-star fas filled" />
                          <i className="fa-star fas filled" />
                          <i className="fa-star fas filled" />
                          <span>(5.0)</span>
                        </div>
                      </div>
                      <div className="listing-details-group">
                        <ul>
                          <li>
                            <span>
                              <ImageWithBasePath
                                src="assets/img/icons/car-parts-01.svg"
                                alt="Auto"
                              />
                            </span>
                            <p>Auto</p>
                          </li>
                          <li>
                            <span>
                              <ImageWithBasePath
                                src="assets/img/icons/car-parts-02.svg"
                                alt="22 KM"
                              />
                            </span>
                            <p>22 KM</p>
                          </li>
                          <li>
                            <span>
                              <ImageWithBasePath
                                src="assets/img/icons/car-parts-03.svg"
                                alt="Gasolina"
                              />
                            </span>
                            <p>Gasolina</p>
                          </li>
                        </ul>
                        <ul>
                          <li>
                            <span>
                              <ImageWithBasePath
                                src="assets/img/icons/car-parts-04.svg"
                                alt="Diesel"
                              />
                            </span>
                            <p>Diesel</p>
                          </li>
                          <li>
                            <span>
                              <ImageWithBasePath
                                src="assets/img/icons/car-parts-05.svg"
                                alt=""
                              />
                            </span>
                            <p>2016</p>
                          </li>
                          <li>
                            <span>
                              <ImageWithBasePath
                                src="assets/img/icons/car-parts-06.svg"
                                alt="Personas"
                              />
                            </span>
                            <p>5 Personas</p>
                          </li>
                        </ul>
                      </div>
                      <div className="listing-location-details">
                        <div className="listing-price">
                          <span>
                            <i className="icon-map-pin feather" />
                          </span>
                          Belgium
                        </div>
                        <div className="listing-price">
                          <h6>
                            $80 <span>/ Dia</span>
                          </h6>
                        </div>
                      </div>
                      <div className="listing-button">
                        <Link
                          to={routes.listingdetails}
                          className="btn btn-order"
                        >
                          <span>
                            <i className="feather icon-calendar me-2" />
                          </span>
                          Rentar Ahora
                        </Link>
                      </div>
                    </div>
                  </div>
                </div>
                {/* /col */}
                {/* col */}
                <div className="col-12 col-lg-4 col-md-6" data-aos="fade-down">
                  <div className="listing-item">
                    <div className="listing-img">
                      <Link to={routes.listingdetails}>
                        <ImageWithBasePath
                          src="assets/img/cars/car-03.png"
                          className="img-fluid"
                          alt="Audi"
                        />
                      </Link>
                      <div
                        className="fav-item"
                        key={39}
                        onClick={() => handleItemClick(39)}
                      >
                        <span className="featured-text">Audi</span>
                        <Link
                          to="#"
                          className={`fav-icon ${
                            selectedItems[39] ? "selected" : ""
                          }`}
                        >
                          <i className="feather icon-heart" />
                        </Link>
                      </div>
                    </div>
                    <div className="listing-content">
                      <div className="listing-features">
                        <Link to="#" className="author-img">
                          <ImageWithBasePath
                            src="assets/img/profiles/avatar-03.jpg"
                            alt="author"
                          />
                        </Link>
                        <h3 className="listing-title">
                          <Link to={routes.listingdetails}>
                            Audi A3 2019 new
                          </Link>
                        </h3>
                        <div className="list-rating">
                          <i className="fa-star fas filled" />
                          <i className="fa-star fas filled" />
                          <i className="fa-star fas filled" />
                          <i className="fa-star fas filled" />
                          <i className="fa-star fas filled" />
                          <span>(5.0)</span>
                        </div>
                      </div>
                      <div className="listing-details-group">
                        <ul>
                          <li>
                            <span>
                              <ImageWithBasePath
                                src="assets/img/icons/car-parts-05.svg"
                                alt="Manual"
                              />
                            </span>
                            <p>Manual</p>
                          </li>
                          <li>
                            <span>
                              <ImageWithBasePath
                                src="assets/img/icons/car-parts-02.svg"
                                alt="10 KM"
                              />
                            </span>
                            <p>10 KM</p>
                          </li>
                          <li>
                            <span>
                              <ImageWithBasePath
                                src="assets/img/icons/car-parts-03.svg"
                                alt="Gasolina"
                              />
                            </span>
                            <p>Gasolina</p>
                          </li>
                        </ul>
                        <ul>
                          <li>
                            <span>
                              <ImageWithBasePath
                                src="assets/img/icons/car-parts-04.svg"
                                alt="Power"
                              />
                            </span>
                            <p>Fuerza</p>
                          </li>
                          <li>
                            <span>
                              <ImageWithBasePath
                                src="assets/img/icons/car-parts-05.svg"
                                alt=""
                              />
                            </span>
                            <p>2019</p>
                          </li>
                          <li>
                            <span>
                              <ImageWithBasePath
                                src="assets/img/icons/car-parts-06.svg"
                                alt="Personas"
                              />
                            </span>
                            <p>4 Personas</p>
                          </li>
                        </ul>
                      </div>
                      <div className="listing-location-details">
                        <div className="listing-price">
                          <span>
                            <i className="icon-map-pin feather" />
                          </span>
                          Quito, ECU
                        </div>
                        <div className="listing-price">
                          <h6>
                            $45 <span>/ Dia</span>
                          </h6>
                        </div>
                      </div>
                      <div className="listing-button">
                        <Link
                          to={routes.listingdetails}
                          className="btn btn-order"
                        >
                          <span>
                            <i className="feather icon-calendar me-2" />
                          </span>
                          Rentar Ahora
                        </Link>
                      </div>
                    </div>
                  </div>
                </div>
                {/* 
                <div className="col-12 col-lg-4 col-md-6" data-aos="fade-down">
                  <div className="listing-item">
                    <div className="listing-img">
                      <Link to={routes.listingdetails}>
                        <ImageWithBasePath
                          src="assets/img/cars/car-04.jpg"
                          className="img-fluid"
                          alt="Audi"
                        />
                      </Link>
                      <div
                        className="fav-item"
                        key={40}
                        onClick={() => handleItemClick(40)}
                      >
                        <span className="featured-text">Ferrai</span>
                        <Link
                          to="#"
                          className={`fav-icon ${
                            selectedItems[40] ? "selected" : ""
                          }`}
                        >
                          <i className="feather icon-heart" />
                        </Link>
                      </div>
                    </div>
                    <div className="listing-content">
                      <div className="listing-features">
                        <Link to="#" className="author-img">
                          <ImageWithBasePath
                            src="assets/img/profiles/avatar-04.jpg"
                            alt="author"
                          />
                        </Link>
                        <h3 className="listing-title">
                          <Link to={routes.listingdetails}>
                            Ferrari 458 MM Special
                          </Link>
                        </h3>
                        <div className="list-rating">
                          <i className="fa-star fas filled" />
                          <i className="fa-star fas filled" />
                          <i className="fa-star fas filled" />
                          <i className="fa-star fas filled" />
                          <i className="fa-star fas filled" />
                          <span>(5.0)</span>
                        </div>
                      </div>
                      <div className="listing-details-group">
                        <ul>
                          <li>
                            <span>
                              <ImageWithBasePath
                                src="assets/img/icons/car-parts-05.svg"
                                alt="Manual"
                              />
                            </span>
                            <p>Manual</p>
                          </li>
                          <li>
                            <span>
                              <ImageWithBasePath
                                src="assets/img/icons/car-parts-02.svg"
                                alt="14 KM"
                              />
                            </span>
                            <p>14 KM</p>
                          </li>
                          <li>
                            <span>
                              <ImageWithBasePath
                                src="assets/img/icons/car-parts-03.svg"
                                alt="Diesel"
                              />
                            </span>
                            <p>Diesel</p>
                          </li>
                        </ul>
                        <ul>
                          <li>
                            <span>
                              <ImageWithBasePath
                                src="assets/img/icons/car-parts-04.svg"
                                alt="Básico"
                              />
                            </span>
                            <p>Básico</p>
                          </li>
                          <li>
                            <span>
                              <ImageWithBasePath
                                src="assets/img/icons/car-parts-05.svg"
                                alt=""
                              />
                            </span>
                            <p>2022</p>
                          </li>
                          <li>
                            <span>
                              <ImageWithBasePath
                                src="assets/img/icons/car-parts-06.svg"
                                alt="Personas"
                              />
                            </span>
                            <p>5 Personas</p>
                          </li>
                        </ul>
                      </div>
                      <div className="listing-location-details">
                        <div className="listing-price">
                          <span>
                            <i className="icon-map-pin feather" />
                          </span>
                          Quito, ECU
                        </div>
                        <div className="listing-price">
                          <h6>
                            $160 <span>/ Dia</span>
                          </h6>
                        </div>
                      </div>
                      <div className="listing-button">
                        <Link
                          to={routes.listingdetails}
                          className="btn btn-order"
                        >
                          <span>
                            <i className="feather icon-calendar me-2" />
                          </span>
                          Rentar Ahora
                        </Link>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-12 col-lg-4 col-md-6" data-aos="fade-down">
                  <div className="listing-item">
                    <div className="listing-img">
                      <Link to={routes.listingdetails}>
                        <ImageWithBasePath
                          src="assets/img/cars/car-05.jpg"
                          className="img-fluid"
                          alt="Audi"
                        />
                      </Link>
                      <div
                        className="fav-item"
                        key={41}
                        onClick={() => handleItemClick(41)}
                      >
                        <span className="featured-text">Chevrolet</span>
                        <Link
                          to="#"
                          className={`fav-icon ${
                            selectedItems[41] ? "selected" : ""
                          }`}
                        >
                          <i className="feather icon-heart" />
                        </Link>
                      </div>
                    </div>
                    <div className="listing-content">
                      <div className="listing-features">
                        <Link to="#" className="author-img">
                          <ImageWithBasePath
                            src="assets/img/profiles/avatar-05.jpg"
                            alt="author"
                          />
                        </Link>
                        <h3 className="listing-title">
                          <Link to={routes.listingdetails}>
                            2018 Chevrolet Camaro
                          </Link>
                        </h3>
                        <div className="list-rating">
                          <i className="fa-star fas filled" />
                          <i className="fa-star fas filled" />
                          <i className="fa-star fas filled" />
                          <i className="fa-star fas filled" />
                          <i className="fa-star fas filled" />
                          <span>(5.0)</span>
                        </div>
                      </div>
                      <div className="listing-details-group">
                        <ul>
                          <li>
                            <span>
                              <ImageWithBasePath
                                src="assets/img/icons/car-parts-05.svg"
                                alt="Manual"
                              />
                            </span>
                            <p>Manual</p>
                          </li>
                          <li>
                            <span>
                              <ImageWithBasePath
                                src="assets/img/icons/car-parts-02.svg"
                                alt="18 KM"
                              />
                            </span>
                            <p>18 KM</p>
                          </li>
                          <li>
                            <span>
                              <ImageWithBasePath
                                src="assets/img/icons/car-parts-03.svg"
                                alt="Diesel"
                              />
                            </span>
                            <p>Diesel</p>
                          </li>
                        </ul>
                        <ul>
                          <li>
                            <span>
                              <ImageWithBasePath
                                src="assets/img/icons/car-parts-04.svg"
                                alt="Power"
                              />
                            </span>
                            <p>Fuerza</p>
                          </li>
                          <li>
                            <span>
                              <ImageWithBasePath
                                src="assets/img/icons/car-parts-05.svg"
                                alt=""
                              />
                            </span>
                            <p>2018</p>
                          </li>
                          <li>
                            <span>
                              <ImageWithBasePath
                                src="assets/img/icons/car-parts-06.svg"
                                alt="Personas"
                              />
                            </span>
                            <p>4 Personas</p>
                          </li>
                        </ul>
                      </div>
                      <div className="listing-location-details">
                        <div className="listing-price">
                          <span>
                            <i className="icon-map-pin feather" />
                          </span>
                          Ecuador
                        </div>
                        <div className="listing-price">
                          <h6>
                            $36 <span>/ Dia</span>
                          </h6>
                        </div>
                      </div>
                      <div className="listing-button">
                        <Link
                          to={routes.listingdetails}
                          className="btn btn-order"
                        >
                          <span>
                            <i className="feather icon-calendar me-2" />
                          </span>
                          Rentar Ahora
                        </Link>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-12 col-lg-4 col-md-6" data-aos="fade-down">
                  <div className="listing-item">
                    <div className="listing-img">
                      <Link to={routes.listingdetails}>
                        <ImageWithBasePath
                          src="assets/img/cars/car-06.jpg"
                          className="img-fluid"
                          alt="Audi"
                        />
                      </Link>
                      <div
                        className="fav-item"
                        key={42}
                        onClick={() => handleItemClick(42)}
                      >
                        <span className="featured-text">Acura</span>
                        <Link
                          to="#"
                          className={`fav-icon ${
                            selectedItems[42] ? "selected" : ""
                          }`}
                        >
                          <i className="feather icon-heart" />
                        </Link>
                      </div>
                    </div>
                    <div className="listing-content">
                      <div className="listing-features">
                        <Link to="#" className="author-img">
                          <ImageWithBasePath
                            src="assets/img/profiles/avatar-06.jpg"
                            alt="author"
                          />
                        </Link>
                        <h3 className="listing-title">
                          <Link to={routes.listingdetails}>
                            Acura Sport Version
                          </Link>
                        </h3>
                        <div className="list-rating">
                          <i className="fa-star fas filled" />
                          <i className="fa-star fas filled" />
                          <i className="fa-star fas filled" />
                          <i className="fa-star fas filled" />
                          <i className="fa-star fas filled" />
                          <span>(5.0)</span>
                        </div>
                      </div>
                      <div className="listing-details-group">
                        <ul>
                          <li>
                            <span>
                              <ImageWithBasePath
                                src="assets/img/icons/car-parts-01.svg"
                                alt="Auto"
                              />
                            </span>
                            <p>Auto</p>
                          </li>
                          <li>
                            <span>
                              <ImageWithBasePath
                                src="assets/img/icons/car-parts-02.svg"
                                alt="12 KM"
                              />
                            </span>
                            <p>12 KM</p>
                          </li>
                          <li>
                            <span>
                              <ImageWithBasePath
                                src="assets/img/icons/car-parts-03.svg"
                                alt="Diesel"
                              />
                            </span>
                            <p>Diesel</p>
                          </li>
                        </ul>
                        <ul>
                          <li>
                            <span>
                              <ImageWithBasePath
                                src="assets/img/icons/car-parts-04.svg"
                                alt="Power"
                              />
                            </span>
                            <p>Fuerza</p>
                          </li>
                          <li>
                            <span>
                              <ImageWithBasePath
                                src="assets/img/icons/car-parts-05.svg"
                                alt=""
                              />
                            </span>
                            <p>2013</p>
                          </li>
                          <li>
                            <span>
                              <ImageWithBasePath
                                src="assets/img/icons/car-parts-06.svg"
                                alt="Personas"
                              />
                            </span>
                            <p>5 Personas</p>
                          </li>
                        </ul>
                      </div>
                      <div className="listing-location-details">
                        <div className="listing-price">
                          <span>
                            <i className="icon-map-pin feather" />
                          </span>
                          Quito, ECU
                        </div>
                        <div className="listing-price">
                          <h6>
                            $30 <span>/ Dia</span>
                          </h6>
                        </div>
                      </div>
                      <div className="listing-button">
                        <Link
                          to={routes.listingdetails}
                          className="btn btn-order"
                        >
                          <span>
                            <i className="feather icon-calendar me-2" />
                          </span>
                          Rentar Ahora
                        </Link>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-12 col-lg-4 col-md-6" data-aos="fade-down">
                  <div className="listing-item">
                    <div className="listing-img">
                      <Link to={routes.listingdetails}>
                        <ImageWithBasePath
                          src="assets/img/cars/car-07.jpg"
                          className="img-fluid"
                          alt="Audi"
                        />
                      </Link>
                      <div
                        className="fav-item"
                        key={43}
                        onClick={() => handleItemClick(43)}
                      >
                        <span className="featured-text">Chevrolet</span>
                        <Link
                          to="#"
                          className={`fav-icon ${
                            selectedItems[43] ? "selected" : ""
                          }`}
                        >
                          <i className="feather icon-heart" />
                        </Link>
                      </div>
                    </div>
                    <div className="listing-content">
                      <div className="listing-features">
                        <Link to="#" className="author-img">
                          <ImageWithBasePath
                            src="assets/img/profiles/avatar-07.jpg"
                            alt="author"
                          />
                        </Link>
                        <h3 className="listing-title">
                          <Link to={routes.listingdetails}>
                            Chevrolet Pick Truck 3.5L
                          </Link>
                        </h3>
                        <div className="list-rating">
                          <i className="fa-star fas filled" />
                          <i className="fa-star fas filled" />
                          <i className="fa-star fas filled" />
                          <i className="fa-star fas filled" />
                          <i className="fa-star fas filled" />
                          <span>(5.0)</span>
                        </div>
                      </div>
                      <div className="listing-details-group">
                        <ul>
                          <li>
                            <span>
                              <ImageWithBasePath
                                src="assets/img/icons/car-parts-05.svg"
                                alt="Manual"
                              />
                            </span>
                            <p>Manual</p>
                          </li>
                          <li>
                            <span>
                              <ImageWithBasePath
                                src="assets/img/icons/car-parts-02.svg"
                                alt="10 KM"
                              />
                            </span>
                            <p>10 KM</p>
                          </li>
                          <li>
                            <span>
                              <ImageWithBasePath
                                src="assets/img/icons/car-parts-03.svg"
                                alt="Gasolina"
                              />
                            </span>
                            <p>Gasolina</p>
                          </li>
                        </ul>
                        <ul>
                          <li>
                            <span>
                              <ImageWithBasePath
                                src="assets/img/icons/car-parts-04.svg"
                                alt="Power"
                              />
                            </span>
                            <p>Fuerza</p>
                          </li>
                          <li>
                            <span>
                              <ImageWithBasePath
                                src="assets/img/icons/car-parts-05.svg"
                                alt=""
                              />
                            </span>
                            <p>2012</p>
                          </li>
                          <li>
                            <span>
                              <ImageWithBasePath
                                src="assets/img/icons/car-parts-06.svg"
                                alt="Personas"
                              />
                            </span>
                            <p>5 Personas</p>
                          </li>
                        </ul>
                      </div>
                      <div className="listing-location-details">
                        <div className="listing-price">
                          <span>
                            <i className="icon-map-pin feather" />
                          </span>
                          Spain
                        </div>
                        <div className="listing-price">
                          <h6>
                            $77 <span>/ Dia</span>
                          </h6>
                        </div>
                      </div>
                      <div className="listing-button">
                        <Link
                          to={routes.listingdetails}
                          className="btn btn-order"
                        >
                          <span>
                            <i className="feather icon-calendar me-2" />
                          </span>
                          Rentar Ahora
                        </Link>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-12 col-lg-4 col-md-6" data-aos="fade-down">
                  <div className="listing-item">
                    <div className="listing-img">
                      <Link to={routes.listingdetails}>
                        <ImageWithBasePath
                          src="assets/img/cars/car-08.jpg"
                          className="img-fluid"
                          alt="Toyota"
                        />
                      </Link>
                      <div
                        className="fav-item"
                        key={44}
                        onClick={() => handleItemClick(44)}
                      >
                        <span className="featured-text">Toyota</span>
                        <Link
                          to="#"
                          className={`fav-icon ${
                            selectedItems[44] ? "selected" : ""
                          }`}
                        >
                          <i className="feather icon-heart" />
                        </Link>
                      </div>
                    </div>
                    <div className="listing-content">
                      <div className="listing-features">
                        <Link to="#" className="author-img">
                          <ImageWithBasePath
                            src="assets/img/profiles/avatar-08.jpg"
                            alt="author"
                          />
                        </Link>
                        <h3 className="listing-title">
                          <Link to={routes.listingdetails}>
                            Toyota Tacoma 4WD
                          </Link>
                        </h3>
                        <div className="list-rating">
                          <i className="fa-star fas filled" />
                          <i className="fa-star fas filled" />
                          <i className="fa-star fas filled" />
                          <i className="fa-star fas filled" />
                          <i className="fa-star fas filled" />
                          <span>(5.0)</span>
                        </div>
                      </div>
                      <div className="listing-details-group">
                        <ul>
                          <li>
                            <span>
                              <ImageWithBasePath
                                src="assets/img/icons/car-parts-01.svg"
                                alt="Auto"
                              />
                            </span>
                            <p>Auto</p>
                          </li>
                          <li>
                            <span>
                              <ImageWithBasePath
                                src="assets/img/icons/car-parts-02.svg"
                                alt="22 miles"
                              />
                            </span>
                            <p>22 miles</p>
                          </li>
                          <li>
                            <span>
                              <ImageWithBasePath
                                src="assets/img/icons/car-parts-03.svg"
                                alt="Diesel"
                              />
                            </span>
                            <p>Diesel</p>
                          </li>
                        </ul>
                        <ul>
                          <li>
                            <span>
                              <ImageWithBasePath
                                src="assets/img/icons/car-parts-04.svg"
                                alt="Power"
                              />
                            </span>
                            <p>Fuerza</p>
                          </li>
                          <li>
                            <span>
                              <ImageWithBasePath
                                src="assets/img/icons/car-parts-05.svg"
                                alt=""
                              />
                            </span>
                            <p>2019</p>
                          </li>
                          <li>
                            <span>
                              <ImageWithBasePath
                                src="assets/img/icons/car-parts-06.svg"
                                alt="Personas"
                              />
                            </span>
                            <p>5 Personas</p>
                          </li>
                        </ul>
                      </div>
                      <div className="listing-location-details">
                        <div className="listing-price">
                          <span>
                            <i className="icon-map-pin feather" />
                          </span>
                          Dallas, USA
                        </div>
                        <div className="listing-price">
                          <h6>
                            $30 <span>/ Dia</span>
                          </h6>
                        </div>
                      </div>
                      <div className="listing-button">
                        <Link
                          to={routes.listingdetails}
                          className="btn btn-order"
                        >
                          <span>
                            <i className="feather icon-calendar me-2" />
                          </span>
                          Rentar Ahora
                        </Link>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-12 col-lg-4 col-md-6" data-aos="fade-down">
                  <div className="listing-item">
                    <div className="listing-img">
                      <Link to={routes.listingdetails}>
                        <ImageWithBasePath
                          src="assets/img/cars/car-09.jpg"
                          className="img-fluid"
                          alt="Toyota"
                        />
                      </Link>
                      <div
                        className="fav-item"
                        key={45}
                        onClick={() => handleItemClick(45)}
                      >
                        <span className="featured-text">Accura</span>
                        <Link
                          to="#"
                          className={`fav-icon ${
                            selectedItems[45] ? "selected" : ""
                          }`}
                        >
                          <i className="feather icon-heart" />
                        </Link>
                      </div>
                    </div>
                    <div className="listing-content">
                      <div className="listing-features">
                        <Link to="#" className="author-img">
                          <ImageWithBasePath
                            src="assets/img/profiles/avatar-10.jpg"
                            alt="author"
                          />
                        </Link>
                        <h3 className="listing-title">
                          <Link to={routes.listingdetails}>Acura RDX FWD</Link>
                        </h3>
                        <div className="list-rating">
                          <i className="fa-star fas filled" />
                          <i className="fa-star fas filled" />
                          <i className="fa-star fas filled" />
                          <i className="fa-star fas filled" />
                          <i className="fa-star fas filled" />
                          <span>(5.0)</span>
                        </div>
                      </div>
                      <div className="listing-details-group">
                        <ul>
                          <li>
                            <span>
                              <ImageWithBasePath
                                src="assets/img/icons/car-parts-01.svg"
                                alt="Auto"
                              />
                            </span>
                            <p>Auto</p>
                          </li>
                          <li>
                            <span>
                              <ImageWithBasePath
                                src="assets/img/icons/car-parts-02.svg"
                                alt="22 miles"
                              />
                            </span>
                            <p>42 miles</p>
                          </li>
                          <li>
                            <span>
                              <ImageWithBasePath
                                src="assets/img/icons/car-parts-03.svg"
                                alt="Gasolina"
                              />
                            </span>
                            <p>Gasolina</p>
                          </li>
                        </ul>
                        <ul>
                          <li>
                            <span>
                              <ImageWithBasePath
                                src="assets/img/icons/car-parts-04.svg"
                                alt="Power"
                              />
                            </span>
                            <p>Fuerza</p>
                          </li>
                          <li>
                            <span>
                              <ImageWithBasePath
                                src="assets/img/icons/car-parts-05.svg"
                                alt=""
                              />
                            </span>
                            <p>2021</p>
                          </li>
                          <li>
                            <span>
                              <ImageWithBasePath
                                src="assets/img/icons/car-parts-06.svg"
                                alt="Personas"
                              />
                            </span>
                            <p>5 Personas</p>
                          </li>
                        </ul>
                      </div>
                      <div className="listing-location-details">
                        <div className="listing-price">
                          <span>
                            <i className="icon-map-pin feather" />
                          </span>
                          Dallas, USA
                        </div>
                        <div className="listing-price">
                          <h6>
                            $80 <span>/ Dia</span>
                          </h6>
                        </div>
                      </div>
                      <div className="listing-button">
                        <Link
                          to={routes.listingdetails}
                          className="btn btn-order"
                        >
                          <span>
                            <i className="feather icon-calendar me-2" />
                          </span>
                          Rentar Ahora
                        </Link>
                      </div>
                    </div>
                  </div>
                </div>
                {/* /col */}
              </div>
            </div>
            <div className="fade tab-pane" id="Cartesla">
              <div className="row">
                {/* col */}
                <div className="col-12 col-lg-4 col-md-6" data-aos="fade-down">
                  <div className="listing-item">
                    <div className="listing-img">
                      <Link to={routes.listingdetails}>
                        <ImageWithBasePath
                          src="assets/img/cars/car-03.png"
                          className="img-fluid"
                          alt="Toyota"
                        />
                      </Link>
                      <div
                        className="fav-item"
                        key={46}
                        onClick={() => handleItemClick(46)}
                      >
                        <span className="featured-text">Toyota</span>
                        <Link
                          to="#"
                          className={`fav-icon ${
                            selectedItems[46] ? "selected" : ""
                          }`}
                        >
                          <i className="feather icon-heart" />
                        </Link>
                      </div>
                    </div>
                    <div className="listing-content">
                      <div className="listing-features">
                        <Link to="#" className="author-img">
                          <ImageWithBasePath
                            src="assets/img/profiles/avatar-08.jpg"
                            alt="author"
                          />
                        </Link>
                        <h3 className="listing-title">
                          <Link to={routes.listingdetails}>
                            Toyota Tacoma 4WD
                          </Link>
                        </h3>
                        <div className="list-rating">
                          <i className="fa-star fas filled" />
                          <i className="fa-star fas filled" />
                          <i className="fa-star fas filled" />
                          <i className="fa-star fas filled" />
                          <i className="fa-star fas filled" />
                          <span>(5.0)</span>
                        </div>
                      </div>
                      <div className="listing-details-group">
                        <ul>
                          <li>
                            <span>
                              <ImageWithBasePath
                                src="assets/img/icons/car-parts-01.svg"
                                alt="Auto"
                              />
                            </span>
                            <p>Auto</p>
                          </li>
                          <li>
                            <span>
                              <ImageWithBasePath
                                src="assets/img/icons/car-parts-02.svg"
                                alt="22 miles"
                              />
                            </span>
                            <p>22 miles</p>
                          </li>
                          <li>
                            <span>
                              <ImageWithBasePath
                                src="assets/img/icons/car-parts-03.svg"
                                alt="Diesel"
                              />
                            </span>
                            <p>Diesel</p>
                          </li>
                        </ul>
                        <ul>
                          <li>
                            <span>
                              <ImageWithBasePath
                                src="assets/img/icons/car-parts-04.svg"
                                alt="Power"
                              />
                            </span>
                            <p>Fuerza</p>
                          </li>
                          <li>
                            <span>
                              <ImageWithBasePath
                                src="assets/img/icons/car-parts-05.svg"
                                alt=""
                              />
                            </span>
                            <p>2019</p>
                          </li>
                          <li>
                            <span>
                              <ImageWithBasePath
                                src="assets/img/icons/car-parts-06.svg"
                                alt="Personas"
                              />
                            </span>
                            <p>5 Personas</p>
                          </li>
                        </ul>
                      </div>
                      <div className="listing-location-details">
                        <div className="listing-price">
                          <span>
                            <i className="icon-map-pin feather" />
                          </span>
                          Dallas, USA
                        </div>
                        <div className="listing-price">
                          <h6>
                            $30 <span>/ Dia</span>
                          </h6>
                        </div>
                      </div>
                      <div className="listing-button">
                        <Link
                          to={routes.listingdetails}
                          className="btn btn-order"
                        >
                          <span>
                            <i className="feather icon-calendar me-2" />
                          </span>
                          Rentar Ahora
                        </Link>
                      </div>
                    </div>
                  </div>
                </div>
                {/* /col */}
                {/* col */}
                <div className="col-12 col-lg-4 col-md-6" data-aos="fade-down">
                  <div className="listing-item">
                    <div className="listing-img">
                      <Link to={routes.listingdetails}>
                        <ImageWithBasePath
                          src="assets/img/cars/car-02.png"
                          className="img-fluid"
                          alt="Toyota"
                        />
                      </Link>
                      <div
                        className="fav-item"
                        key={47}
                        onClick={() => handleItemClick(47)}
                      >
                        <span className="featured-text">Toyota</span>
                        <Link
                          to="#"
                          className={`fav-icon ${
                            selectedItems[47] ? "selected" : ""
                          }`}
                        >
                          <i className="feather icon-heart" />
                        </Link>
                      </div>
                    </div>
                    <div className="listing-content">
                      <div className="listing-features">
                        <Link to="#" className="author-img">
                          <ImageWithBasePath
                            src="assets/img/profiles/avatar-0.jpg"
                            alt="author"
                          />
                        </Link>
                        <h3 className="listing-title">
                          <Link to={routes.listingdetails}>
                            Toyota Camry SE 350
                          </Link>
                        </h3>
                        <div className="list-rating">
                          <i className="fa-star fas filled" />
                          <i className="fa-star fas filled" />
                          <i className="fa-star fas filled" />
                          <i className="fa-star fas filled" />
                          <i className="fa-star fas filled" />
                          <span>(5.0)</span>
                        </div>
                      </div>
                      <div className="listing-details-group">
                        <ul>
                          <li>
                            <span>
                              <ImageWithBasePath
                                src="assets/img/icons/car-parts-01.svg"
                                alt="Auto"
                              />
                            </span>
                            <p>Auto</p>
                          </li>
                          <li>
                            <span>
                              <ImageWithBasePath
                                src="assets/img/icons/car-parts-02.svg"
                                alt="10 KM"
                              />
                            </span>
                            <p>10 KM</p>
                          </li>
                          <li>
                            <span>
                              <ImageWithBasePath
                                src="assets/img/icons/car-parts-03.svg"
                                alt="Gasolina"
                              />
                            </span>
                            <p>Gasolina</p>
                          </li>
                        </ul>
                        <ul>
                          <li>
                            <span>
                              <ImageWithBasePath
                                src="assets/img/icons/car-parts-04.svg"
                                alt="Power"
                              />
                            </span>
                            <p>Fuerza</p>
                          </li>
                          <li>
                            <span>
                              <ImageWithBasePath
                                src="assets/img/icons/car-parts-05.svg"
                                alt=""
                              />
                            </span>
                            <p>2018</p>
                          </li>
                          <li>
                            <span>
                              <ImageWithBasePath
                                src="assets/img/icons/car-parts-06.svg"
                                alt="Personas"
                              />
                            </span>
                            <p>5 Personas</p>
                          </li>
                        </ul>
                      </div>
                      <div className="listing-location-details">
                        <div className="listing-price">
                          <span>
                            <i className="icon-map-pin feather" />
                          </span>
                          Ecuador
                        </div>
                        <div className="listing-price">
                          <h6>
                            $400 <span>/ Dia</span>
                          </h6>
                        </div>
                      </div>
                      <div className="listing-button">
                        <Link
                          to={routes.listingdetails}
                          className="btn btn-order"
                        >
                          <span>
                            <i className="feather icon-calendar me-2" />
                          </span>
                          Rentar Ahora
                        </Link>
                      </div>
                    </div>
                  </div>
                </div>
                {/* /col */}
                {/* col */}
                <div className="col-12 col-lg-4 col-md-6" data-aos="fade-down">
                  <div className="listing-item">
                    <div className="listing-img">
                      <Link to={routes.listingdetails}>
                        <ImageWithBasePath
                          src="assets/img/cars/car-09.webp"
                          className="img-fluid"
                          alt="KIA"
                        />
                      </Link>
                      <div
                        className="fav-item"
                        key={48}
                        onClick={() => handleItemClick(48)}
                      >
                        <span className="featured-text">KIA</span>
                        <Link
                          to="#"
                          className={`fav-icon ${
                            selectedItems[48] ? "selected" : ""
                          }`}
                        >
                          <i className="feather icon-heart" />
                        </Link>
                      </div>
                    </div>
                    <div className="listing-content">
                      <div className="listing-features">
                        <Link to="#" className="author-img">
                          <ImageWithBasePath
                            src="assets/img/profiles/avatar-02.jpg"
                            alt="author"
                          />
                        </Link>
                        <h3 className="listing-title">
                          <Link to={routes.listingdetails}>Kia Soul 2016</Link>
                        </h3>
                        <div className="list-rating">
                          <i className="fa-star fas filled" />
                          <i className="fa-star fas filled" />
                          <i className="fa-star fas filled" />
                          <i className="fa-star fas filled" />
                          <i className="fa-star fas filled" />
                          <span>(5.0)</span>
                        </div>
                      </div>
                      <div className="listing-details-group">
                        <ul>
                          <li>
                            <span>
                              <ImageWithBasePath
                                src="assets/img/icons/car-parts-01.svg"
                                alt="Auto"
                              />
                            </span>
                            <p>Auto</p>
                          </li>
                          <li>
                            <span>
                              <ImageWithBasePath
                                src="assets/img/icons/car-parts-02.svg"
                                alt="22 KM"
                              />
                            </span>
                            <p>22 KM</p>
                          </li>
                          <li>
                            <span>
                              <ImageWithBasePath
                                src="assets/img/icons/car-parts-03.svg"
                                alt="Gasolina"
                              />
                            </span>
                            <p>Gasolina</p>
                          </li>
                        </ul>
                        <ul>
                          <li>
                            <span>
                              <ImageWithBasePath
                                src="assets/img/icons/car-parts-04.svg"
                                alt="Diesel"
                              />
                            </span>
                            <p>Diesel</p>
                          </li>
                          <li>
                            <span>
                              <ImageWithBasePath
                                src="assets/img/icons/car-parts-05.svg"
                                alt=""
                              />
                            </span>
                            <p>2016</p>
                          </li>
                          <li>
                            <span>
                              <ImageWithBasePath
                                src="assets/img/icons/car-parts-06.svg"
                                alt="Personas"
                              />
                            </span>
                            <p>5 Personas</p>
                          </li>
                        </ul>
                      </div>
                      <div className="listing-location-details">
                        <div className="listing-price">
                          <span>
                            <i className="icon-map-pin feather" />
                          </span>
                          Belgium
                        </div>
                        <div className="listing-price">
                          <h6>
                            $80 <span>/ Dia</span>
                          </h6>
                        </div>
                      </div>
                      <div className="listing-button">
                        <Link
                          to={routes.listingdetails}
                          className="btn btn-order"
                        >
                          <span>
                            <i className="feather icon-calendar me-2" />
                          </span>
                          Rentar Ahora
                        </Link>
                      </div>
                    </div>
                  </div>
                </div>
                {/* 
                <div className="col-12 col-lg-4 col-md-6" data-aos="fade-down">
                  <div className="listing-item">
                    <div className="listing-img">
                      <Link to={routes.listingdetails}>
                        <ImageWithBasePath
                          src="assets/img/cars/car-03.jpg"
                          className="img-fluid"
                          alt="Audi"
                        />
                      </Link>
                      <div
                        className="fav-item"
                        key={49}
                        onClick={() => handleItemClick(49)}
                      >
                        <span className="featured-text">Audi</span>
                        <Link
                          to="#"
                          className={`fav-icon ${
                            selectedItems[49] ? "selected" : ""
                          }`}
                        >
                          <i className="feather icon-heart" />
                        </Link>
                      </div>
                    </div>
                    <div className="listing-content">
                      <div className="listing-features">
                        <Link to="#" className="author-img">
                          <ImageWithBasePath
                            src="assets/img/profiles/avatar-03.jpg"
                            alt="author"
                          />
                        </Link>
                        <h3 className="listing-title">
                          <Link to={routes.listingdetails}>
                            Audi A3 2019 new
                          </Link>
                        </h3>
                        <div className="list-rating">
                          <i className="fa-star fas filled" />
                          <i className="fa-star fas filled" />
                          <i className="fa-star fas filled" />
                          <i className="fa-star fas filled" />
                          <i className="fa-star fas filled" />
                          <span>(5.0)</span>
                        </div>
                      </div>
                      <div className="listing-details-group">
                        <ul>
                          <li>
                            <span>
                              <ImageWithBasePath
                                src="assets/img/icons/car-parts-05.svg"
                                alt="Manual"
                              />
                            </span>
                            <p>Manual</p>
                          </li>
                          <li>
                            <span>
                              <ImageWithBasePath
                                src="assets/img/icons/car-parts-02.svg"
                                alt="10 KM"
                              />
                            </span>
                            <p>10 KM</p>
                          </li>
                          <li>
                            <span>
                              <ImageWithBasePath
                                src="assets/img/icons/car-parts-03.svg"
                                alt="Gasolina"
                              />
                            </span>
                            <p>Gasolina</p>
                          </li>
                        </ul>
                        <ul>
                          <li>
                            <span>
                              <ImageWithBasePath
                                src="assets/img/icons/car-parts-04.svg"
                                alt="Power"
                              />
                            </span>
                            <p>Fuerza</p>
                          </li>
                          <li>
                            <span>
                              <ImageWithBasePath
                                src="assets/img/icons/car-parts-05.svg"
                                alt=""
                              />
                            </span>
                            <p>2019</p>
                          </li>
                          <li>
                            <span>
                              <ImageWithBasePath
                                src="assets/img/icons/car-parts-06.svg"
                                alt="Personas"
                              />
                            </span>
                            <p>4 Personas</p>
                          </li>
                        </ul>
                      </div>
                      <div className="listing-location-details">
                        <div className="listing-price">
                          <span>
                            <i className="icon-map-pin feather" />
                          </span>
                          Quito, ECU
                        </div>
                        <div className="listing-price">
                          <h6>
                            $45 <span>/ Dia</span>
                          </h6>
                        </div>
                      </div>
                      <div className="listing-button">
                        <Link
                          to={routes.listingdetails}
                          className="btn btn-order"
                        >
                          <span>
                            <i className="feather icon-calendar me-2" />
                          </span>
                          Rentar Ahora
                        </Link>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-12 col-lg-4 col-md-6" data-aos="fade-down">
                  <div className="listing-item">
                    <div className="listing-img">
                      <Link to={routes.listingdetails}>
                        <ImageWithBasePath
                          src="assets/img/cars/car-04.jpg"
                          className="img-fluid"
                          alt="Audi"
                        />
                      </Link>
                      <div
                        className="fav-item"
                        key={50}
                        onClick={() => handleItemClick(50)}
                      >
                        <span className="featured-text">Ferrai</span>
                        <Link
                          to="#"
                          className={`fav-icon ${
                            selectedItems[50] ? "selected" : ""
                          }`}
                        >
                          <i className="feather icon-heart" />
                        </Link>
                      </div>
                    </div>
                    <div className="listing-content">
                      <div className="listing-features">
                        <Link to="#" className="author-img">
                          <ImageWithBasePath
                            src="assets/img/profiles/avatar-04.jpg"
                            alt="author"
                          />
                        </Link>
                        <h3 className="listing-title">
                          <Link to={routes.listingdetails}>
                            Ferrari 458 MM Special
                          </Link>
                        </h3>
                        <div className="list-rating">
                          <i className="fa-star fas filled" />
                          <i className="fa-star fas filled" />
                          <i className="fa-star fas filled" />
                          <i className="fa-star fas filled" />
                          <i className="fa-star fas filled" />
                          <span>(5.0)</span>
                        </div>
                      </div>
                      <div className="listing-details-group">
                        <ul>
                          <li>
                            <span>
                              <ImageWithBasePath
                                src="assets/img/icons/car-parts-05.svg"
                                alt="Manual"
                              />
                            </span>
                            <p>Manual</p>
                          </li>
                          <li>
                            <span>
                              <ImageWithBasePath
                                src="assets/img/icons/car-parts-02.svg"
                                alt="14 KM"
                              />
                            </span>
                            <p>14 KM</p>
                          </li>
                          <li>
                            <span>
                              <ImageWithBasePath
                                src="assets/img/icons/car-parts-03.svg"
                                alt="Diesel"
                              />
                            </span>
                            <p>Diesel</p>
                          </li>
                        </ul>
                        <ul>
                          <li>
                            <span>
                              <ImageWithBasePath
                                src="assets/img/icons/car-parts-04.svg"
                                alt="Básico"
                              />
                            </span>
                            <p>Básico</p>
                          </li>
                          <li>
                            <span>
                              <ImageWithBasePath
                                src="assets/img/icons/car-parts-05.svg"
                                alt=""
                              />
                            </span>
                            <p>2022</p>
                          </li>
                          <li>
                            <span>
                              <ImageWithBasePath
                                src="assets/img/icons/car-parts-06.svg"
                                alt="Personas"
                              />
                            </span>
                            <p>5 Personas</p>
                          </li>
                        </ul>
                      </div>
                      <div className="listing-location-details">
                        <div className="listing-price">
                          <span>
                            <i className="icon-map-pin feather" />
                          </span>
                          Quito, ECU
                        </div>
                        <div className="listing-price">
                          <h6>
                            $160 <span>/ Dia</span>
                          </h6>
                        </div>
                      </div>
                      <div className="listing-button">
                        <Link
                          to={routes.listingdetails}
                          className="btn btn-order"
                        >
                          <span>
                            <i className="feather icon-calendar me-2" />
                          </span>
                          Rentar Ahora
                        </Link>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-12 col-lg-4 col-md-6" data-aos="fade-down">
                  <div className="listing-item">
                    <div className="listing-img">
                      <Link to={routes.listingdetails}>
                        <ImageWithBasePath
                          src="assets/img/cars/car-05.jpg"
                          className="img-fluid"
                          alt="Audi"
                        />
                      </Link>
                      <div
                        className="fav-item"
                        key={51}
                        onClick={() => handleItemClick(51)}
                      >
                        <span className="featured-text">Chevrolet</span>
                        <Link
                          to="#"
                          className={`fav-icon ${
                            selectedItems[51] ? "selected" : ""
                          }`}
                        >
                          <i className="feather icon-heart" />
                        </Link>
                      </div>
                    </div>
                    <div className="listing-content">
                      <div className="listing-features">
                        <Link to="#" className="author-img">
                          <ImageWithBasePath
                            src="assets/img/profiles/avatar-05.jpg"
                            alt="author"
                          />
                        </Link>
                        <h3 className="listing-title">
                          <Link to={routes.listingdetails}>
                            2018 Chevrolet Camaro
                          </Link>
                        </h3>
                        <div className="list-rating">
                          <i className="fa-star fas filled" />
                          <i className="fa-star fas filled" />
                          <i className="fa-star fas filled" />
                          <i className="fa-star fas filled" />
                          <i className="fa-star fas filled" />
                          <span>(5.0)</span>
                        </div>
                      </div>
                      <div className="listing-details-group">
                        <ul>
                          <li>
                            <span>
                              <ImageWithBasePath
                                src="assets/img/icons/car-parts-05.svg"
                                alt="Manual"
                              />
                            </span>
                            <p>Manual</p>
                          </li>
                          <li>
                            <span>
                              <ImageWithBasePath
                                src="assets/img/icons/car-parts-02.svg"
                                alt="18 KM"
                              />
                            </span>
                            <p>18 KM</p>
                          </li>
                          <li>
                            <span>
                              <ImageWithBasePath
                                src="assets/img/icons/car-parts-03.svg"
                                alt="Diesel"
                              />
                            </span>
                            <p>Diesel</p>
                          </li>
                        </ul>
                        <ul>
                          <li>
                            <span>
                              <ImageWithBasePath
                                src="assets/img/icons/car-parts-04.svg"
                                alt="Power"
                              />
                            </span>
                            <p>Fuerza</p>
                          </li>
                          <li>
                            <span>
                              <ImageWithBasePath
                                src="assets/img/icons/car-parts-05.svg"
                                alt=""
                              />
                            </span>
                            <p>2018</p>
                          </li>
                          <li>
                            <span>
                              <ImageWithBasePath
                                src="assets/img/icons/car-parts-06.svg"
                                alt="Personas"
                              />
                            </span>
                            <p>4 Personas</p>
                          </li>
                        </ul>
                      </div>
                      <div className="listing-location-details">
                        <div className="listing-price">
                          <span>
                            <i className="icon-map-pin feather" />
                          </span>
                          Ecuador
                        </div>
                        <div className="listing-price">
                          <h6>
                            $36 <span>/ Dia</span>
                          </h6>
                        </div>
                      </div>
                      <div className="listing-button">
                        <Link
                          to={routes.listingdetails}
                          className="btn btn-order"
                        >
                          <span>
                            <i className="feather icon-calendar me-2" />
                          </span>
                          Rentar Ahora
                        </Link>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-12 col-lg-4 col-md-6" data-aos="fade-down">
                  <div className="listing-item">
                    <div className="listing-img">
                      <Link to={routes.listingdetails}>
                        <ImageWithBasePath
                          src="assets/img/cars/car-06.jpg"
                          className="img-fluid"
                          alt="Audi"
                        />
                      </Link>
                      <div
                        className="fav-item"
                        key={52}
                        onClick={() => handleItemClick(52)}
                      >
                        <span className="featured-text">Acura</span>
                        <Link
                          to="#"
                          className={`fav-icon ${
                            selectedItems[52] ? "selected" : ""
                          }`}
                        >
                          <i className="feather icon-heart" />
                        </Link>
                      </div>
                    </div>
                    <div className="listing-content">
                      <div className="listing-features">
                        <Link to="#" className="author-img">
                          <ImageWithBasePath
                            src="assets/img/profiles/avatar-06.jpg"
                            alt="author"
                          />
                        </Link>
                        <h3 className="listing-title">
                          <Link to={routes.listingdetails}>
                            Acura Sport Version
                          </Link>
                        </h3>
                        <div className="list-rating">
                          <i className="fa-star fas filled" />
                          <i className="fa-star fas filled" />
                          <i className="fa-star fas filled" />
                          <i className="fa-star fas filled" />
                          <i className="fa-star fas filled" />
                          <span>(5.0)</span>
                        </div>
                      </div>
                      <div className="listing-details-group">
                        <ul>
                          <li>
                            <span>
                              <ImageWithBasePath
                                src="assets/img/icons/car-parts-01.svg"
                                alt="Auto"
                              />
                            </span>
                            <p>Auto</p>
                          </li>
                          <li>
                            <span>
                              <ImageWithBasePath
                                src="assets/img/icons/car-parts-02.svg"
                                alt="12 KM"
                              />
                            </span>
                            <p>12 KM</p>
                          </li>
                          <li>
                            <span>
                              <ImageWithBasePath
                                src="assets/img/icons/car-parts-03.svg"
                                alt="Diesel"
                              />
                            </span>
                            <p>Diesel</p>
                          </li>
                        </ul>
                        <ul>
                          <li>
                            <span>
                              <ImageWithBasePath
                                src="assets/img/icons/car-parts-04.svg"
                                alt="Power"
                              />
                            </span>
                            <p>Fuerza</p>
                          </li>
                          <li>
                            <span>
                              <ImageWithBasePath
                                src="assets/img/icons/car-parts-05.svg"
                                alt=""
                              />
                            </span>
                            <p>2013</p>
                          </li>
                          <li>
                            <span>
                              <ImageWithBasePath
                                src="assets/img/icons/car-parts-06.svg"
                                alt="Personas"
                              />
                            </span>
                            <p>5 Personas</p>
                          </li>
                        </ul>
                      </div>
                      <div className="listing-location-details">
                        <div className="listing-price">
                          <span>
                            <i className="icon-map-pin feather" />
                          </span>
                          Quito, ECU
                        </div>
                        <div className="listing-price">
                          <h6>
                            $30 <span>/ Dia</span>
                          </h6>
                        </div>
                      </div>
                      <div className="listing-button">
                        <Link
                          to={routes.listingdetails}
                          className="btn btn-order"
                        >
                          <span>
                            <i className="feather icon-calendar me-2" />
                          </span>
                          Rentar Ahora
                        </Link>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-12 col-lg-4 col-md-6" data-aos="fade-down">
                  <div className="listing-item">
                    <div className="listing-img">
                      <Link to={routes.listingdetails}>
                        <ImageWithBasePath
                          src="assets/img/cars/car-07.jpg"
                          className="img-fluid"
                          alt="Audi"
                        />
                      </Link>
                      <div
                        className="fav-item"
                        key={53}
                        onClick={() => handleItemClick(53)}
                      >
                        <span className="featured-text">Chevrolet</span>
                        <Link
                          to="#"
                          className={`fav-icon ${
                            selectedItems[53] ? "selected" : ""
                          }`}
                        >
                          <i className="feather icon-heart" />
                        </Link>
                      </div>
                    </div>
                    <div className="listing-content">
                      <div className="listing-features">
                        <Link to="#" className="author-img">
                          <ImageWithBasePath
                            src="assets/img/profiles/avatar-07.jpg"
                            alt="author"
                          />
                        </Link>
                        <h3 className="listing-title">
                          <Link to={routes.listingdetails}>
                            Chevrolet Pick Truck 3.5L
                          </Link>
                        </h3>
                        <div className="list-rating">
                          <i className="fa-star fas filled" />
                          <i className="fa-star fas filled" />
                          <i className="fa-star fas filled" />
                          <i className="fa-star fas filled" />
                          <i className="fa-star fas filled" />
                          <span>(5.0)</span>
                        </div>
                      </div>
                      <div className="listing-details-group">
                        <ul>
                          <li>
                            <span>
                              <ImageWithBasePath
                                src="assets/img/icons/car-parts-05.svg"
                                alt="Manual"
                              />
                            </span>
                            <p>Manual</p>
                          </li>
                          <li>
                            <span>
                              <ImageWithBasePath
                                src="assets/img/icons/car-parts-02.svg"
                                alt="10 KM"
                              />
                            </span>
                            <p>10 KM</p>
                          </li>
                          <li>
                            <span>
                              <ImageWithBasePath
                                src="assets/img/icons/car-parts-03.svg"
                                alt="Gasolina"
                              />
                            </span>
                            <p>Gasolina</p>
                          </li>
                        </ul>
                        <ul>
                          <li>
                            <span>
                              <ImageWithBasePath
                                src="assets/img/icons/car-parts-04.svg"
                                alt="Power"
                              />
                            </span>
                            <p>Fuerza</p>
                          </li>
                          <li>
                            <span>
                              <ImageWithBasePath
                                src="assets/img/icons/car-parts-05.svg"
                                alt=""
                              />
                            </span>
                            <p>2012</p>
                          </li>
                          <li>
                            <span>
                              <ImageWithBasePath
                                src="assets/img/icons/car-parts-06.svg"
                                alt="Personas"
                              />
                            </span>
                            <p>5 Personas</p>
                          </li>
                        </ul>
                      </div>
                      <div className="listing-location-details">
                        <div className="listing-price">
                          <span>
                            <i className="icon-map-pin feather" />
                          </span>
                          Spain
                        </div>
                        <div className="listing-price">
                          <h6>
                            $77 <span>/ Dia</span>
                          </h6>
                        </div>
                      </div>
                      <div className="listing-button">
                        <Link
                          to={routes.listingdetails}
                          className="btn btn-order"
                        >
                          <span>
                            <i className="feather icon-calendar me-2" />
                          </span>
                          Rentar Ahora
                        </Link>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-12 col-lg-4 col-md-6" data-aos="fade-down">
                  <div className="listing-item">
                    <div className="listing-img">
                      <Link to={routes.listingdetails}>
                        <ImageWithBasePath
                          src="assets/img/cars/car-09.jpg"
                          className="img-fluid"
                          alt="Toyota"
                        />
                      </Link>
                      <div
                        className="fav-item"
                        key={54}
                        onClick={() => handleItemClick(54)}
                      >
                        <span className="featured-text">Accura</span>
                        <Link
                          to="#"
                          className={`fav-icon ${
                            selectedItems[54] ? "selected" : ""
                          }`}
                        >
                          <i className="feather icon-heart" />
                        </Link>
                      </div>
                    </div>
                    <div className="listing-content">
                      <div className="listing-features">
                        <Link to="#" className="author-img">
                          <ImageWithBasePath
                            src="assets/img/profiles/avatar-08.jpg"
                            alt="author"
                          />
                        </Link>
                        <h3 className="listing-title">
                          <Link to={routes.listingdetails}>Acura RDX FWD</Link>
                        </h3>
                        <div className="list-rating">
                          <i className="fa-star fas filled" />
                          <i className="fa-star fas filled" />
                          <i className="fa-star fas filled" />
                          <i className="fa-star fas filled" />
                          <i className="fa-star fas filled" />
                          <span>(5.0)</span>
                        </div>
                      </div>
                      <div className="listing-details-group">
                        <ul>
                          <li>
                            <span>
                              <ImageWithBasePath
                                src="assets/img/icons/car-parts-01.svg"
                                alt="Auto"
                              />
                            </span>
                            <p>Auto</p>
                          </li>
                          <li>
                            <span>
                              <ImageWithBasePath
                                src="assets/img/icons/car-parts-02.svg"
                                alt="22 miles"
                              />
                            </span>
                            <p>42 miles</p>
                          </li>
                          <li>
                            <span>
                              <ImageWithBasePath
                                src="assets/img/icons/car-parts-03.svg"
                                alt="Gasolina"
                              />
                            </span>
                            <p>Gasolina</p>
                          </li>
                        </ul>
                        <ul>
                          <li>
                            <span>
                              <ImageWithBasePath
                                src="assets/img/icons/car-parts-04.svg"
                                alt="Power"
                              />
                            </span>
                            <p>Fuerza</p>
                          </li>
                          <li>
                            <span>
                              <ImageWithBasePath
                                src="assets/img/icons/car-parts-05.svg"
                                alt=""
                              />
                            </span>
                            <p>2021</p>
                          </li>
                          <li>
                            <span>
                              <ImageWithBasePath
                                src="assets/img/icons/car-parts-06.svg"
                                alt="Personas"
                              />
                            </span>
                            <p>5 Personas</p>
                          </li>
                        </ul>
                      </div>
                      <div className="listing-location-details">
                        <div className="listing-price">
                          <span>
                            <i className="icon-map-pin feather" />
                          </span>
                          Dallas, USA
                        </div>
                        <div className="listing-price">
                          <h6>
                            $80 <span>/ Dia</span>
                          </h6>
                        </div>
                      </div>
                      <div className="listing-button">
                        <Link
                          to={routes.listingdetails}
                          className="btn btn-order"
                        >
                          <span>
                            <i className="feather icon-calendar me-2" />
                          </span>
                          Rentar Ahora
                        </Link>
                      </div>
                    </div>
                  </div>
                </div>
                {/* /col */}
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* /Popular Services
      <section className="popular-car-type section">
        <div className="container">
          <div className="section-heading" data-aos="fade-down">
            <h2>Tipo de autos mas populares</h2>
            <p>
              Los autos de nuestros clientes se muestran en la siguiente lista
            </p>
          </div>
          <div className="row">
            <div className="popular-slider-group">
              <Slider {...settings}>
                <div className="listing-owl-item">
                  <div className="listing-owl-group">
                    <div className="listing-owl-img">
                      <ImageWithBasePath
                        src="assets/img/cars/mp-vehicle-01.png"
                        className="img-fluid"
                        alt="Popular Cartypes"
                      />
                    </div>
                    <h6>Crossover</h6>
                    <p>35 Carros</p>
                  </div>
                </div>
                <div className="listing-owl-item">
                  <div className="listing-owl-group">
                    <div className="listing-owl-img">
                      <ImageWithBasePath
                        src="assets/img/cars/mp-vehicle-02.png"
                        className="img-fluid"
                        alt="Popular Cartypes"
                      />
                    </div>
                    <h6>Sports Coupe</h6>
                    <p>45 Carros</p>
                  </div>
                </div>
                <div className="listing-owl-item">
                  <div className="listing-owl-group">
                    <div className="listing-owl-img">
                      <ImageWithBasePath
                        src="assets/img/cars/mp-vehicle-03.png"
                        className="img-fluid"
                        alt="Popular Cartypes"
                      />
                    </div>
                    <h6>Sedan</h6>
                    <p>15 Carros</p>
                  </div>
                </div>
                <div className="listing-owl-item">
                  <div className="listing-owl-group">
                    <div className="listing-owl-img">
                      <ImageWithBasePath
                        src="assets/img/cars/mp-vehicle-04.png"
                        className="img-fluid"
                        alt="Popular Cartypes"
                      />
                    </div>
                    <h6>Pickup</h6>
                    <p>17 Carros</p>
                  </div>
                </div>
                <div className="listing-owl-item">
                  <div className="listing-owl-group">
                    <div className="listing-owl-img">
                      <ImageWithBasePath
                        src="assets/img/cars/mp-vehicle-05.png"
                        className="img-fluid"
                        alt="Popular Cartypes"
                      />
                    </div>
                    <h6>Family MPV</h6>
                    <p>24 Carros</p>
                  </div>
                </div>
              </Slider>
            </div>
          </div>
          <div className="view-all text-center" data-aos="fade-down">
            <Link
              to={routes.listinggrid}
              className="d-inline-flex align-items-center btn btn-view"
            >
              Ver todos los carros{" "}
              <span>
                <i className="icon-arrow-right feather ms-2" />
              </span>
            </Link>
          </div>
        </div>
      </section>
        */}
      {/* /Popular Cartypes */}
      {/* Facts By The Numbers */}
      <section className="facts-number section">
    {/* /Popular Cartypes
        <div className="facts-left">
          <ImageWithBasePath
            src="assets/img/bg/facts-left.png"
            className="img-fluid"
            alt="facts left"
          />
        </div>
    */}
        <div className="facts-right">
          <ImageWithBasePath
            src="assets/img/bg/facts-right.png"
            className="img-fluid"
            alt="facts right"
          />
        </div>
        <div className="container">
          {/* Heading title*/}
          <div className="section-heading" data-aos="fade-down">
            <h2 className="text-white title">Hechos en cifras</h2>
            <p className="text-white description">
              Las estadísticas muestran como hemos realizado los servicios a nuestros clientes
            </p>
          </div>
          {/* /Heading title */}
          <div className="counter-group">
            <div className="row">
              <div
                className="d-flex col-12 col-lg-3 col-md-6"
                data-aos="fade-down"
              >
                <div className="flex-fill count-group">
                  <div className="d-flex align-items-center customer-count">
                    <div className="count-img">
                      <ImageWithBasePath
                        src="assets/img/icons/bx-heart.svg"
                        alt=""
                      />
                    </div>
                    <div className="count-content">
                      <h4>
                        <CountUp
                          className="counterUp"
                          end={16000}
                          duration={3}
                          separator=","
                        />
                        K<br />
                      </h4>
                      <p> Clientes satisfechos </p>
                    </div>
                  </div>
                </div>
              </div>
              <div
                className="d-flex col-12 col-lg-3 col-md-6"
                data-aos="fade-down"
              >
                <div className="flex-fill count-group">
                  <div className="d-flex align-items-center customer-count">
                    <div className="count-img">
                      <ImageWithBasePath
                        src="assets/img/icons/bx-car.svg"
                        alt=""
                      />
                    </div>
                    <div className="count-content">
                      <h4>
                        <CountUp
                          className="counterUp"
                          end={2547}
                          duration={3}
                          separator=","
                        />
                        +<br />
                      </h4>
                      <p>Numero de carros</p>
                    </div>
                  </div>
                </div>
              </div>
              <div
                className="d-flex col-12 col-lg-3 col-md-6"
                data-aos="fade-down"
              >
                <div className="flex-fill count-group">
                  <div className="d-flex align-items-center customer-count">
                    <div className="count-img">
                      <ImageWithBasePath
                        src="assets/img/icons/bx-headphone.svg"
                        alt=""
                      />
                    </div>
                    <div className="count-content">
                      <h4>
                        <CountUp
                          className="counterUp"
                          end={625}
                          duration={3}
                          separator=","
                        />
                        K+
                        <br />
                      </h4>
                      <p>Centro de soluciones</p>
                    </div>
                  </div>
                </div>
              </div>
              <div
                className="d-flex col-12 col-lg-3 col-md-6"
                data-aos="fade-down"
              >
                <div className="flex-fill count-group">
                  <div className="d-flex align-items-center customer-count">
                    <div className="count-img">
                      <ImageWithBasePath
                        src="assets/img/icons/bx-history.svg"
                        alt=""
                      />
                    </div>
                    <div className="count-content">
                      <h4>
                        <CountUp
                          className="counterUp"
                          end={200}
                          duration={3}
                          separator=","
                        />
                        K+
                        <br />
                      </h4>
                      <p>Total de kilometros</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* /Facts By The Numbers */}
      {/* Rental deals 
      <section className="popular-services section">
        <div className="container">
          <div className="section-heading" data-aos="fade-down">
            <h2>Ofertas de alquiler de autos recomendadas</h2>
            <p>
              Nuestra recomendación para que pueda realizar el alquiler
              de su auto de preferencia
            </p>
          </div>
          <div className="row">
            <div className="popular-slider-group">
              <div className="owl-carousel owl-theme rental-deal-slider">
                <Slider {...one} className="service-slider">
                  <div className="rental-car-item">
                    <div className="mb-0 listing-item">
                      <div className="listing-img">
                        <Link to={routes.listingdetails}>
                          <ImageWithBasePath
                            src="assets/img/cars/car-01.jpg"
                            className="img-fluid"
                            alt="Toyota"
                          />
                        </Link>
                      </div>
                      <div className="listing-content">
                        <div className="listing-features">
                          <div className="fav-item-rental">
                            <span className="featured-text">$400/Dia</span>
                          </div>
                          <div className="list-rating">
                            <i className="fa-star fas filled" />
                            <i className="fa-star fas filled" />
                            <i className="fa-star fas filled" />
                            <i className="fa-star fas filled" />
                            <i className="fa-star fas filled" />
                            <span>(5.0)</span>
                          </div>
                          <h3 className="listing-title">
                            <Link to={routes.listingdetails}>
                              Toyota Camry SE 350
                            </Link>
                          </h3>
                          <h6>
                            Listed By : <span>Venis Darren</span>
                          </h6>
                        </div>
                        <div className="listing-details-group">
                          <ul>
                            <li>
                              <span>
                                <ImageWithBasePath
                                  src="assets/img/icons/car-parts-01.svg"
                                  alt="Auto"
                                />
                              </span>
                              <p>Auto</p>
                            </li>
                            <li>
                              <span>
                                <ImageWithBasePath
                                  src="assets/img/icons/car-parts-02.svg"
                                  alt="10 KM"
                                />
                              </span>
                              <p>10 KM</p>
                            </li>
                            <li>
                              <span>
                                <ImageWithBasePath
                                  src="assets/img/icons/car-parts-03.svg"
                                  alt="Gasolina"
                                />
                              </span>
                              <p>Gasolina</p>
                            </li>
                          </ul>
                          <ul>
                            <li>
                              <span>
                                <ImageWithBasePath
                                  src="assets/img/icons/car-parts-04.svg"
                                  alt="Power"
                                />
                              </span>
                              <p>Fuerza</p>
                            </li>
                            <li>
                              <span>
                                <ImageWithBasePath
                                  src="assets/img/icons/car-parts-05.svg"
                                  alt=""
                                />
                              </span>
                              <p>2018</p>
                            </li>
                            <li>
                              <span>
                                <ImageWithBasePath
                                  src="assets/img/icons/car-parts-06.svg"
                                  alt="Personas"
                                />
                              </span>
                              <p>5 Personas</p>
                            </li>
                          </ul>
                        </div>
                        <div className="listing-button">
                          <Link
                            to={routes.listingdetails}
                            className="btn btn-order"
                          >
                            <span>
                              <i className="feather icon-calendar me-2" />
                            </span>
                            Rentar Ahora
                          </Link>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="rental-car-item">
                    <div className="mb-0 listing-item">
                      <div className="listing-img">
                        <Link to={routes.listingdetails}>
                          <ImageWithBasePath
                            src="assets/img/cars/car-03.jpg"
                            className="img-fluid"
                            alt="Toyota"
                          />
                        </Link>
                      </div>
                      <div className="listing-content">
                        <div className="listing-features">
                          <div className="fav-item-rental">
                            <span className="featured-text">$400/Dia</span>
                          </div>
                          <div className="list-rating">
                            <i className="fa-star fas filled" />
                            <i className="fa-star fas filled" />
                            <i className="fa-star fas filled" />
                            <i className="fa-star fas filled" />
                            <i className="fa-star fas filled" />
                            <span>(5.0)</span>
                          </div>
                          <h3 className="listing-title">
                            <Link to={routes.listingdetails}>
                              Toyota Camry SE 350
                            </Link>
                          </h3>
                          <h6>
                            Listed By : <span>Venis Darren</span>
                          </h6>
                        </div>
                        <div className="listing-details-group">
                          <ul>
                            <li>
                              <span>
                                <ImageWithBasePath
                                  src="assets/img/icons/car-parts-01.svg"
                                  alt="Auto"
                                />
                              </span>
                              <p>Auto</p>
                            </li>
                            <li>
                              <span>
                                <ImageWithBasePath
                                  src="assets/img/icons/car-parts-02.svg"
                                  alt="10 KM"
                                />
                              </span>
                              <p>10 KM</p>
                            </li>
                            <li>
                              <span>
                                <ImageWithBasePath
                                  src="assets/img/icons/car-parts-03.svg"
                                  alt="Gasolina"
                                />
                              </span>
                              <p>Gasolina</p>
                            </li>
                          </ul>
                          <ul>
                            <li>
                              <span>
                                <ImageWithBasePath
                                  src="assets/img/icons/car-parts-04.svg"
                                  alt="Power"
                                />
                              </span>
                              <p>Fuerza</p>
                            </li>
                            <li>
                              <span>
                                <ImageWithBasePath
                                  src="assets/img/icons/car-parts-05.svg"
                                  alt=""
                                />
                              </span>
                              <p>2018</p>
                            </li>
                            <li>
                              <span>
                                <ImageWithBasePath
                                  src="assets/img/icons/car-parts-06.svg"
                                  alt="Personas"
                                />
                              </span>
                              <p>5 Personas</p>
                            </li>
                          </ul>
                        </div>
                        <div className="listing-button">
                          <Link
                            to={routes.listingdetails}
                            className="btn btn-order"
                          >
                            <span>
                              <i className="feather icon-calendar me-2" />
                            </span>
                            Rentar Ahora
                          </Link>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="rental-car-item">
                    <div className="mb-0 listing-item">
                      <div className="listing-img">
                        <Link to={routes.listingdetails}>
                          <ImageWithBasePath
                            src="assets/img/cars/car-04.jpg"
                            className="img-fluid"
                            alt="Toyota"
                          />
                        </Link>
                      </div>
                      <div className="listing-content">
                        <div className="listing-features">
                          <div className="fav-item-rental">
                            <span className="featured-text">$400/Dia</span>
                          </div>
                          <div className="list-rating">
                            <i className="fa-star fas filled" />
                            <i className="fa-star fas filled" />
                            <i className="fa-star fas filled" />
                            <i className="fa-star fas filled" />
                            <i className="fa-star fas filled" />
                            <span>(5.0)</span>
                          </div>
                          <h3 className="listing-title">
                            <Link to={routes.listingdetails}>
                              Toyota Camry SE 350
                            </Link>
                          </h3>
                          <h6>
                            Listed By : <span>Venis Darren</span>
                          </h6>
                        </div>
                        <div className="listing-details-group">
                          <ul>
                            <li>
                              <span>
                                <ImageWithBasePath
                                  src="assets/img/icons/car-parts-01.svg"
                                  alt="Auto"
                                />
                              </span>
                              <p>Auto</p>
                            </li>
                            <li>
                              <span>
                                <ImageWithBasePath
                                  src="assets/img/icons/car-parts-02.svg"
                                  alt="10 KM"
                                />
                              </span>
                              <p>10 KM</p>
                            </li>
                            <li>
                              <span>
                                <ImageWithBasePath
                                  src="assets/img/icons/car-parts-03.svg"
                                  alt="Gasolina"
                                />
                              </span>
                              <p>Gasolina</p>
                            </li>
                          </ul>
                          <ul>
                            <li>
                              <span>
                                <ImageWithBasePath
                                  src="assets/img/icons/car-parts-04.svg"
                                  alt="Power"
                                />
                              </span>
                              <p>Fuerza</p>
                            </li>
                            <li>
                              <span>
                                <ImageWithBasePath
                                  src="assets/img/icons/car-parts-05.svg"
                                  alt=""
                                />
                              </span>
                              <p>2018</p>
                            </li>
                            <li>
                              <span>
                                <ImageWithBasePath
                                  src="assets/img/icons/car-parts-06.svg"
                                  alt="Personas"
                                />
                              </span>
                              <p>5 Personas</p>
                            </li>
                          </ul>
                        </div>
                        <div className="listing-button">
                          <Link
                            to={routes.listingdetails}
                            className="btn btn-order"
                          >
                            <span>
                              <i className="feather icon-calendar me-2" />
                            </span>
                            Rentar Ahora
                          </Link>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="rental-car-item">
                    <div className="mb-0 listing-item">
                      <div className="listing-img">
                        <Link to={routes.listingdetails}>
                          <ImageWithBasePath
                            src="assets/img/cars/car-05.jpg"
                            className="img-fluid"
                            alt="Toyota"
                          />
                        </Link>
                      </div>
                      <div className="listing-content">
                        <div className="listing-features">
                          <div className="fav-item-rental">
                            <span className="featured-text">$400/Dia</span>
                          </div>
                          <div className="list-rating">
                            <i className="fa-star fas filled" />
                            <i className="fa-star fas filled" />
                            <i className="fa-star fas filled" />
                            <i className="fa-star fas filled" />
                            <i className="fa-star fas filled" />
                            <span>(5.0)</span>
                          </div>
                          <h3 className="listing-title">
                            <Link to={routes.listingdetails}>
                              Toyota Camry SE 350
                            </Link>
                          </h3>
                          <h6>
                            Listed By : <span>Venis Darren</span>
                          </h6>
                        </div>
                        <div className="listing-details-group">
                          <ul>
                            <li>
                              <span>
                                <ImageWithBasePath
                                  src="assets/img/icons/car-parts-01.svg"
                                  alt="Auto"
                                />
                              </span>
                              <p>Auto</p>
                            </li>
                            <li>
                              <span>
                                <ImageWithBasePath
                                  src="assets/img/icons/car-parts-02.svg"
                                  alt="10 KM"
                                />
                              </span>
                              <p>10 KM</p>
                            </li>
                            <li>
                              <span>
                                <ImageWithBasePath
                                  src="assets/img/icons/car-parts-03.svg"
                                  alt="Gasolina"
                                />
                              </span>
                              <p>Gasolina</p>
                            </li>
                          </ul>
                          <ul>
                            <li>
                              <span>
                                <ImageWithBasePath
                                  src="assets/img/icons/car-parts-04.svg"
                                  alt="Power"
                                />
                              </span>
                              <p>Fuerza</p>
                            </li>
                            <li>
                              <span>
                                <ImageWithBasePath
                                  src="assets/img/icons/car-parts-05.svg"
                                  alt=""
                                />
                              </span>
                              <p>2018</p>
                            </li>
                            <li>
                              <span>
                                <ImageWithBasePath
                                  src="assets/img/icons/car-parts-06.svg"
                                  alt="Personas"
                                />
                              </span>
                              <p>5 Personas</p>
                            </li>
                          </ul>
                        </div>
                        <div className="listing-button">
                          <Link
                            to={routes.listingdetails}
                            className="btn btn-order"
                          >
                            <span>
                              <i className="feather icon-calendar me-2" />
                            </span>
                            Rentar Ahora
                          </Link>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="rental-car-item">
                    <div className="mb-0 listing-item">
                      <div className="listing-img">
                        <Link to={routes.listingdetails}>
                          <ImageWithBasePath
                            src="assets/img/cars/car-03.jpg"
                            className="img-fluid"
                            alt="Toyota"
                          />
                        </Link>
                      </div>
                      <div className="listing-content">
                        <div className="listing-features">
                          <div className="fav-item-rental">
                            <span className="featured-text">$400/Dia</span>
                          </div>
                          <div className="list-rating">
                            <i className="fa-star fas filled" />
                            <i className="fa-star fas filled" />
                            <i className="fa-star fas filled" />
                            <i className="fa-star fas filled" />
                            <i className="fa-star fas filled" />
                            <span>(5.0)</span>
                          </div>
                          <h3 className="listing-title">
                            <Link to={routes.listingdetails}>
                              Toyota Camry SE 350
                            </Link>
                          </h3>
                          <h6>
                            Listed By : <span>Venis Darren</span>
                          </h6>
                        </div>
                        <div className="listing-details-group">
                          <ul>
                            <li>
                              <span>
                                <ImageWithBasePath
                                  src="assets/img/icons/car-parts-01.svg"
                                  alt="Auto"
                                />
                              </span>
                              <p>Auto</p>
                            </li>
                            <li>
                              <span>
                                <ImageWithBasePath
                                  src="assets/img/icons/car-parts-02.svg"
                                  alt="10 KM"
                                />
                              </span>
                              <p>10 KM</p>
                            </li>
                            <li>
                              <span>
                                <ImageWithBasePath
                                  src="assets/img/icons/car-parts-03.svg"
                                  alt="Gasolina"
                                />
                              </span>
                              <p>Gasolina</p>
                            </li>
                          </ul>
                          <ul>
                            <li>
                              <span>
                                <ImageWithBasePath
                                  src="assets/img/icons/car-parts-04.svg"
                                  alt="Power"
                                />
                              </span>
                              <p>Fuerza</p>
                            </li>
                            <li>
                              <span>
                                <ImageWithBasePath
                                  src="assets/img/icons/car-parts-05.svg"
                                  alt=""
                                />
                              </span>
                              <p>2018</p>
                            </li>
                            <li>
                              <span>
                                <ImageWithBasePath
                                  src="assets/img/icons/car-parts-06.svg"
                                  alt="Personas"
                                />
                              </span>
                              <p>5 Personas</p>
                            </li>
                          </ul>
                        </div>
                        <div className="listing-button">
                          <Link
                            to={routes.listingdetails}
                            className="btn btn-order"
                          >
                            <span>
                              <i className="feather icon-calendar me-2" />
                            </span>
                            Rentar Ahora
                          </Link>
                        </div>
                      </div>
                    </div>
                  </div>
                </Slider>
              </div>
            </div>
          </div>
          <div className="view-all text-center" data-aos="fade-down">
            <Link
              to={routes.listinggrid}
              className="d-inline-flex align-items-center btn btn-view"
            >
              Ir a todos los autos{" "}
              <span>
                <i className="icon-arrow-right feather ms-2" />
              </span>
            </Link>
          </div>
        </div>
      </section>
      */}
      {/* /Rental deals */}
      {/* Why Choose Us */}
      <section className="popular-explore section why-choose " style={{display:"flex",alignItems:'center'}}>
        {/* <div className="choose-left">
          <ImageWithBasePath
            src="assets/img/car.png"
            className="img-fluid"
            alt="Why Choose Us"
          />
        </div> */}
        <div>
          <img
            src="assets/img/experience-left.png"
            style={{objectFit: 'contain'}}
            alt="Why Choose Us"
          />
        </div>
      
        <div className="container">
          {/* Heading title*/}
          <div className="section-heading" data-aos="fade-down">
            <h2>Porque escogernos</h2>
            <p>
              Vamos a crear un círculo extenso de clientes con la membresía los cuales van a estar
visitando todos los establecimientos asociados.
            </p>
          </div>
          {/* /Heading title */}
          <div className="why-choose-group">
            <div className="row">
              <div
                className="d-flex col-12 col-lg-4 col-md-6"
                data-aos="fade-down"
              >
                <div className="flex-fill card">
                  <div className="card-body">
                    <div className="choose-black choose-img">
                      <ImageWithBasePath
                        src="assets/img/icons/bx-selection.svg"
                        alt=""
                      />
                    </div>
                    <div className="choose-content">
                      <h4>Facilidad &amp; Reserva Rápida</h4>
                      <p>
                        “Reserva tu auto en minutos”
                        “Arriendo de autos sin complicaciones”
                      </p>
                    </div>
                  </div>
                </div>
              </div>
              <div
                className="d-flex col-12 col-lg-4 col-md-6"
                data-aos="fade-down"
              >
                <div className="flex-fill card">
                  <div className="card-body">
                    <div className="choose-img choose-secondary">
                      <ImageWithBasePath
                        src="assets/img/icons/bx-crown.svg"
                        alt=""
                      />
                    </div>
                    <div className="choose-content">
                      <h4>Muchos lugares de recogida</h4>
                      <p>
                        “Amplia variedad de puntos de recogida” </p>
                        <p>“Numerosos lugares para retirar tu vehículo”</p>
                        <p>“Diversidad de opciones de entrega”</p>
                    </div>
                  </div>
                </div>
              </div>
              <div
                className="d-flex col-12 col-lg-4 col-md-6"
                data-aos="fade-down"
              >
                <div className="flex-fill card">
                  <div className="card-body">
                    <div className="choose-img choose-primary">
                      <ImageWithBasePath
                        src="assets/img/icons/bx-user-check.svg"
                        alt=""
                      />
                    </div>
                    <div className="choose-content">
                      <h4>Clientes satisfechos</h4>
                      <p>
                      “Clientes contentos y satisfechos”
                      “Nuestros clientes están felices”
                      “Satisfacción garantizada para nuestros clientes”
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* /Why Choose Us */}
      {/* About us Testimonials */}
      <section className="Testimonials">
        <div className="container">
          {/* Heading title*/}
          <div className="section-heading">
            <h2 className="text-dark title">
              TESTIMONIOS
            </h2>
            <p className="text-dark description" style={{ color: '#666666', opacity: 0.8 }}>
            ¿Qué dice la gente de nosotros?
            </p>
          </div>
          {/* /Heading title */}
          <div className="mb-0 about-testimonials owl-carousel owl-theme testimonial-group">
            {/* /Carousel Item  */}
            {/* Carousel Item */}
            <Slider {...setting}>
  {testimonials.map((testimonial) => (
    <div key={testimonial.id} className="testimonial-item">
      <div className="card">
        <div className="row no-gutters">
          <div className="col-md-4">
          <img
                  src="/assets/img/profiles/perfil1.png"
                  className="img-fluid review-img"
                  alt="img"
                />
          </div>
          <div className="col-md-8">
            <div className="card-body">
              <div className="rating-section">
                <div className="rating-container">
                  <h1 className="rating-text">{testimonial.rating.toFixed(1)} 
                  <span className="rating-stars-text"> estrellas </span>
                  </h1>
               </div>
                <div className="list-rating">
                  <div className="list-rating-star">
                    {[...Array(Math.floor(testimonial.rating))].map((_, i) => (
                      <i key={i} className="fa-star fas filled" />
                    ))}
                  </div>
                </div>
              </div>
              <p className="testimonial-review">{testimonial.review}</p>
              <h6 className="testimonial-name">{testimonial.name}</h6>
            </div>
          </div>
        </div>
      </div>
    </div>
  ))}
</Slider>


            {/* /Carousel Item  */}
            {/* Carousel Item */}

            {/* /Carousel Item  */}
          </div>
        </div>
      </section>
      {/* About us Testimonials */}
      {/* FAQ  */}
      <section className="bg-light-primary faq-section section">
        <div className="container">
           {/* 
          <div className="section-heading" data-aos="fade-down">
            <h2>Preguntas frecuentes </h2>
            <p>
            Descubre todo lo que necesitas saber sobre la renta de autos 
            en nuestras Preguntas Frecuentes! Desde información sobre 
            reservas y requisitos hasta consejos útiles para tu viaje, 
            nuestras FAQ están diseñadas para brindarte respuestas claras
            y resolver cualquier duda que puedas tener. ¡No te pierdas esta
            guía completa para una experiencia de alquiler de autos sin complicaciones
            </p>
          </div>
          */}
          {/* 
          <div className="faq-info">
            <div className="bg-white faq-card" data-aos="fade-down">
              <h4 className="faq-title">
                <Link
                  className="collapsed"
                  data-bs-toggle="collapse"
                  to="#faqOne"
                  aria-expanded="false"
                >
                  ¿Qué pasa con las ofertas de coches de alquiler?
                </Link>
              </h4>
              <div id="faqOne" className="card-collapse collapse">
                <p>
                ¡Las ofertas de autos de alquiler son una oportunidad increíble 
                para disfrutar de una aventura sin preocupaciones y a un precio 
                inigualable! Con Wolf, encontrarás ofertas que 
                se adaptan perfectamente a tus necesidades de viaje. ¡No te pierdas 
                la oportunidad de explorar nuevos destinos con nuestras increíbles 
                ofertas de alquiler de autos!
                </p>
              </div>
            </div>
            <div className="bg-white faq-card" data-aos="fade-down">
              <h4 className="faq-title">
                <Link
                  className="collapsed"
                  data-bs-toggle="collapse"
                  to="#faqTwo"
                  aria-expanded="false"
                >
                  ¿En que areas operan?
                </Link>
              </h4>
              <div id="faqTwo" className="card-collapse collapse">
                <p>
                Estamos en todas partes donde necesites estar! Desde 
                las vibrantes ciudades hasta los pintorescos rincones del país,
                nuestra red de servicio abarca una amplia gama de áreas, 
                garantizando que tu aventura esté respaldada por la comodidad 
                y la confiabilidad de nuestros autos de alquiler.
                </p>
              </div>
            </div>
            <div className="bg-white faq-card" data-aos="fade-down">
              <h4 className="faq-title">
                <Link
                  className="collapsed"
                  data-bs-toggle="collapse"
                  to="#faqSeven"
                  aria-expanded="false"
                >
                   ¿los requisitos básico para rentar un auto? 
                </Link>
              </h4>
              <div id="faqSeven" className="card-collapse collapse">
                <p>
                Los requisitos básicos para rentar un auto con nosotros 
                son simples y fáciles de cumplir! Con una identificación 
                válida, licencia de conducir vigente y una reserva previa, 
                estarás listo para disfrutar de la libertad de explorar tu 
                destino a tu propio ritmo. ¡Confía en nosotros para hacer 
                de tu experiencia de alquiler de autos una experiencia sin 
                complicaciones y llena de diversión
                </p>
              </div>
            </div>
          </div>
           */}
        </div>
      </section>
      {/* /FAQ */}
      {/* Pricing Plan */}
      {/* /Pricing Plan */}
    </>
  );
};

export default Home;
