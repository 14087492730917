import React from "react";
import AllRoutes from "./router/router";

const Feature = () => {
  return (
    <>
      <div className="main-wrapper">
        <AllRoutes />
      </div>
    </>
  );
};

export default Feature;
