import React, { useEffect } from "react";
import ImageWithBasePath from "../../core/data/img/ImageWithBasePath";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Breadcrumbs from "../common/Breadcrumbs";
import Aos from "aos";
import { FaFacebookF, FaInstagram, FaYoutube, FaTiktok } from 'react-icons/fa';
import { Link } from "react-router-dom";

const AboutUs = () => {

  const iconStyle = {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    width: '40px',
    height: '40px',
    borderRadius: '50%',
    color: '#fff',
    fontSize: '20px',
    transition: 'background-color 0.3s',
  };

  const facebookStyle = {
    ...iconStyle,
    backgroundColor: '#3b5998',
  };

  const instagramStyle = {
    ...iconStyle,
    background: 'radial-gradient(circle at 30% 107%, #fdf497 0%, #fdf497 5%, #fd5949 45%, #d6249f 60%, #285aeb 90%)',
  };

  const youtubeStyle = {
    ...iconStyle,
    backgroundColor: '#ff0000',
  };

  const tiktokStyle = {
    ...iconStyle,
    backgroundColor: '#010101',
  };

  useEffect(() => {
    Aos.init({ duration: 1200, once: true });
  }, []);
  return (
    <>
      {/* Breadscrumb Section */}
      <Breadcrumbs title="Info" subtitle="Acerca de nosotros" maintitle={undefined} />
      {/* /Breadscrumb Section */}
      {/* About */}
      <section className="section about-sec">
        <div className="container">
          <div className="row align-items-center">
            <div className="col-lg-6" data-aos="fade-down">
              <div className="about-img">
                <div className="about-exp">
                  <span>10+ años de experiencia </span>
                </div>
                <div className="abt-img">
                  <ImageWithBasePath
                    src="assets/img/WolfCorporation.jpg"
                    className="img-fluid"
                    alt="About us"
                  />
                </div>
              </div>
            </div>
            <div className="col-lg-6" data-aos="fade-down">
              <div className="about-content">
                <h6>WOLF COMPANY</h6>
                <h2>La mejor solución para servicios con renta de autos</h2>
                <p>
                  Lorem ipsum dolor sit amet, consectetur adipiscing elit,
                  eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut
                  enim minim veniam, quis nostrud exercitation ullamco laboris
                  nisi esse cillum dolore eu fugiat nulla pariatur. Excepteur
                  sint occaecat cupidatat non proident, sunt in culpa qui
                  officia deserunt mollit anim id est laborum.
                </p>
                <p>
                  Sed ut perspiciatis unde omnis iste natus error sit voluptatem
                  accusantium doloremque laudantium, totam rem aperiam, eaque
                  ipsa quae ab illo inventore veritatis et quasi architecto
                  beatae vitae dicta sunt explicabo.
                </p>
                <div className="row">
                  <div className="col-md-6">
                    <ul>
                      <li>At vero et accusamus iusto dignissimos</li>
                      <li>At vero et accusamus iusto dignissimos</li>
                    </ul>
                  </div>
                  <div className="col-md-6">
                    <ul>
                      <li>Nam libero tempore, cum soluta nobis</li>
                      <li>Nam libero tempore, cum soluta nobis</li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="footer-social-widget" style={{marginLeft:'120px'}}>
        <ul style={{ listStyle: 'none', padding: 0, display: 'flex', gap: '10px' }}>
      <li>
        <Link to="#" style={facebookStyle}>
          <FaFacebookF />
        </Link>
      </li>
      <li>
        <Link to="#" style={instagramStyle}>
          <FaInstagram />
        </Link>
      </li>
      <li>
        <Link to="#" style={youtubeStyle}>
          <FaYoutube />
        </Link>
      </li>
      <li>
        <Link to="#" style={tiktokStyle}>
          <FaTiktok />
        </Link>
      </li>
    </ul>
                  </div>
      </section>
      <>
        {/* FAQ  */}
        <section className="section faq-section bg-light-primary">
          <div className="container">
            {/* Heading title*/}
            <div className="section-heading" data-aos="fade-down">
            <h2>Preguntas frecuentes </h2>
            <p>
            Descubre todo lo que necesitas saber sobre la renta de autos en nuestras Preguntas Frecuentes! Desde información sobre 
            reservas y requisitos hasta consejos útiles para tu viaje, 
            nuestras FAQ están diseñadas para brindarte respuestas claras
            y resolver cualquier duda que puedas tener. ¡No te pierdas esta
            guía completa para una experiencia de alquiler de autos sin complicaciones
            </p>
          </div>
          {/* /Heading title */}
          <div className="faq-info">
            <div className="bg-white faq-card" data-aos="fade-down">
              <h4 className="faq-title">
                <Link
                  className="collapsed"
                  data-bs-toggle="collapse"
                  to="#faqOne"
                  aria-expanded="false"
                >
                  ¿Qué pasa con las ofertas de coches de alquiler?
                </Link>
              </h4>
              <div id="faqOne" className="card-collapse collapse">
                <p>
                ¡Las ofertas de autos de alquiler son una oportunidad increíble 
                para disfrutar de una aventura sin preocupaciones y a un precio 
                inigualable! Con Wolf, encontrarás ofertas que 
                se adaptan perfectamente a tus necesidades de viaje. ¡No te pierdas 
                la oportunidad de explorar nuevos destinos con nuestras increíbles 
                ofertas de alquiler de autos!
                </p>
              </div>
            </div>
            <div className="bg-white faq-card" data-aos="fade-down">
              <h4 className="faq-title">
                <Link
                  className="collapsed"
                  data-bs-toggle="collapse"
                  to="#faqTwo"
                  aria-expanded="false"
                >
                  ¿En que areas operan?
                </Link>
              </h4>
              <div id="faqTwo" className="card-collapse collapse">
                <p>
                Estamos en todas partes donde necesites estar! Desde 
                las vibrantes ciudades hasta los pintorescos rincones del país,
                nuestra red de servicio abarca una amplia gama de áreas, 
                garantizando que tu aventura esté respaldada por la comodidad 
                y la confiabilidad de nuestros autos de alquiler.
                </p>
              </div>
            </div>
            <div className="bg-white faq-card" data-aos="fade-down">
              <h4 className="faq-title">
                <Link
                  className="collapsed"
                  data-bs-toggle="collapse"
                  to="#faqSeven"
                  aria-expanded="false"
                >
                   ¿los requisitos básico para rentar un auto? 
                </Link>
              </h4>
              <div id="faqSeven" className="card-collapse collapse">
                <p>
                Los requisitos básicos para rentar un auto con nosotros 
                son simples y fáciles de cumplir! Con una identificación 
                válida, licencia de conducir vigente y una reserva previa, 
                estarás listo para disfrutar de la libertad de explorar tu 
                destino a tu propio ritmo. ¡Confía en nosotros para hacer 
                de tu experiencia de alquiler de autos una experiencia sin 
                complicaciones y llena de diversión
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* /FAQ */}
      {/* Pricing Plan */}
      {/* /Pricing Plan */}
    </>
    </>
  );
};

export default AboutUs;
