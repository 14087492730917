import React, { useEffect } from "react";
import Breadcrumbs from "../../common/Breadcrumbs";
import AOS from "aos";
import "aos/dist/aos.css";

const TermsCondition = () => {
  useEffect(() => {
    AOS.init({ duration: 1200, once: true });
  }, []);
  return (
    <div className="main-wrapper">
      <Breadcrumbs title="Términos y condiciones" subtitle="Info" />

      <div className="privacy-section">
        <div className="container">
          <div className="row">
            <div className="col-lg-12">
              <div className="terms-policy">
                <p data-aos="fade-down">
                <h2>1. Introducción</h2>
                <p>Estos términos y condiciones rigen el uso de los servicios proporcionados por Wolf Rent-a-Car. Al utilizar nuestros servicios, usted acepta cumplir con estos términos y condiciones en su totalidad. Si no está de acuerdo con alguno de estos términos, no utilice nuestros servicios.</p>
                <h2>2. Reservas</h2>
                <p>Las reservas están sujetas a disponibilidad y pueden estar sujetas a confirmación. Nos reservamos el derecho de cancelar o rechazar reservas en cualquier momento y por cualquier motivo.</p>
                <h2>3. Pagos</h2>
                <p>Los pagos se deben realizar de acuerdo con las tarifas y políticas de pago establecidas por Wolf Rent-a-Car. Nos reservamos el derecho de cobrar tarifas adicionales por servicios adicionales, daños o violaciones de los términos y condiciones.</p>
                </p>
                <p data-aos="fade-down">
                <h2>4. Responsabilidades del Cliente</h2>
                <p>El cliente es responsable de devolver el vehículo en las mismas condiciones en las que se recibió, con excepción del desgaste normal. El cliente es responsable de cualquier daño causado al vehículo durante el período de alquiler.</p>
                <h2>5. Seguros</h2>
                <p>Los clientes pueden optar por contratar un seguro adicional para cubrir daños al vehículo. La cobertura y los términos del seguro se especificarán en el momento de la reserva.</p>
                <h2>6. Cancelaciones y Reembolsos</h2>
                <p>Las cancelaciones están sujetas a las políticas de cancelación establecidas por Wolf Rent-a-Car. No se realizarán reembolsos por cancelaciones realizadas dentro de un cierto período de tiempo antes de la fecha de alquiler.</p>
                <h2>7. Modificaciones</h2>.
                <p>Nos reservamos el derecho de modificar estos términos y condiciones en cualquier momento. Los cambios entrarán en vigencia inmediatamente después de su publicación en nuestro sitio web.</p>
                {" "}
                </p>
                <p data-aos="fade-down">
                <h2>8. Contacto</h2>
                <p>Si tiene alguna pregunta o inquietud sobre estos términos y condiciones, comuníquese con nosotros a través de los siguientes datos de contacto:</p>
                <p>Wolf </p>
                </p>
                
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default TermsCondition;
