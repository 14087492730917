export const ourteamdata = [
  {
    name: "Matt Fierce",
    designation: "CEO",
    image: "assets/img/our-team/team-01.jpg",
  },
  {
    name: "May Daniel",
    designation: "COO",
    image: "assets/img/our-team/team-02.jpg",
  },
  {
    name: "Alberrt Hendrenz",
    designation: "Business head",
    image: "assets/img/our-team/team-03.jpg",
  },
  {
    name: "Roseline",
    designation: "Business Analyst",
    image: "assets/img/our-team/team-04.jpg",
  },
  {
    name: "Hendriques",
    designation: "Designer",
    image: "assets/img/our-team/team-05.jpg",
  },
  {
    name: "Maria",
    designation: "Lead Developer",
    image: "assets/img/our-team/team-06.jpg",
  },
];
