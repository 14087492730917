import React from "react";
import { Link } from "react-router-dom";
import ImageWithBasePath from "../../core/data/img/ImageWithBasePath";
import { all_routes } from "../router/all_routes";
import { Mail, PhoneCall, Send } from "react-feather";

const Footer = () => {

  const route = all_routes
  return (
    <>
      {/* Footer */}
      <div className="footer">
        {/* Footer Top */}
        <div className="footer-top aos" data-aos="fade-up">
          <div className="container">
            <div className="row">
              <div className="col-lg-7">
                <div className="row">
                  <div className="col-lg-4 col-md-6">
                    {/* Footer Widget */}
                    <div className="footer-widget footer-menu">
                      <h5 className="footer-title">Acerca de la empresa</h5>
                      <ul>
                        <li>
                          <Link to={route.home}>Nuestra Companía</Link>
                        </li>
                        <li>
                          <Link to="#">Shop Toyota</Link>
                        </li>
                        <li>
                          <Link to="#">Wolf</Link>
                        </li>
                        <li>
                          <Link to="#">
                           Wolf Company
                          </Link>
                        </li>
                        <li>
                          <Link to="#">Servicios de alquiler</Link>
                        </li>
                      </ul>
                    </div>
                    {/* /Footer Widget */}
                  </div>
                  <div className="col-lg-4 col-md-6">
                    {/* Footer Widget */}
                    <div className="footer-widget footer-menu">
                      <h5 className="footer-title">Tipo de autos</h5>
                      <ul>
                        <li>
                          <Link to="#">Todos los autos</Link>
                        </li>
                        <li>
                          <Link to="#">SUVs</Link>
                        </li>
                        <li>
                          <Link to="#">Camiones</Link>
                        </li>
                        <li>
                          <Link to="#">Autos</Link>
                        </li>
                      </ul>
                    </div>
                    {/* /Footer Widget */}
                  </div>
                  <div className="col-lg-4 col-md-6">
                    {/* Footer Widget */}
                    <div className="footer-widget footer-menu">
                      <h5 className="footer-title">Quick links</h5>
                      <ul>
                        <li>
                          <Link to="#">Mi cuenta</Link>
                        </li>
                        <li>
                          <Link to="#">
                            Wolf
                          </Link>
                        </li>
                        <li>
                          <Link to="#">Wolf</Link>
                        </li>
                      </ul>
                    </div>
                    {/* /Footer Widget */}
                  </div>
                </div>
              </div>
              <div className="col-lg-5">
                <div className="footer-contact footer-widget">
                  <h5 className="footer-title">Información de contacto</h5>
                  <div className="footer-contact-info">
                    <div className="footer-address">
                      <span>
                        <PhoneCall />

                      </span>
                      <div className="addr-info">
                        <Link to="tel:+1(888)7601940">+ 593 (888) 760 1940</Link>
                      </div>
                    </div>
                    <div className="footer-address">
                      <span>
                      <Mail/>
                      </span>
                      <div className="addr-info">
                        <Link to="mailto:info@wolfcompanysas.com">
                          info@wolfcompanysas.com
                        </Link>
                      </div>
                    </div>
                    <div className="update-form">
                      <form action="#">
                        <span>
                          <Mail/>
                        </span>
                        <input
                          type="email"
                          className="form-control"
                          placeholder="Ingrese correo electrónico"
                        />
                        <button type="submit" className="btn btn-subscribe">
                          <span>
                            <Send />
                          </span>
                        </button>
                      </form>
                    </div>
                  </div>
                  <div className="footer-social-widget">
                    <h6>Conéctate con nosotros</h6>
                    <ul className="nav-social">
                      <li>
                        <Link to="#">
                          <i className="fa-brands fa-facebook-f fa-facebook fi-icon" />
                        </Link>
                      </li>
                      <li>
                        <Link to="#">
                          <i className="fab fa-instagram fi-icon" />
                        </Link>
                      </li>
                      <li>
                        <Link to="#">
                          <i className="fab fa-behance fi-icon" />
                        </Link>
                      </li>
                      <li>
                        <Link to="#">
                          <i className="fab fa-twitter fi-icon" />{" "}
                        </Link>
                      </li>
                      <li>
                        <Link to="#">
                          <i className="fab fa-linkedin fi-icon" />
                        </Link>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* /Footer Top */}
        {/* Footer Bottom */}
        <div className="footer-bottom">
          <div className="container">
            {/* Copyright */}
            <div className="copyright">
              <div className="row align-items-center">
                <div className="col-md-6">
                  <div className="copyright-text">
                    <p>© 2023 Wolf. Todos los derechos reservados.</p>
                  </div>
                </div>
                <div className="col-md-6">
                  {/* Copyright Menu */}
                  <div className="copyright-menu">
                    <div className="vistors-details">
                      <ul className="d-flex">
                        <li>
                          <Link to="#">
                            <ImageWithBasePath
                              className="img-fluid"
                              src="assets/img/icons/paypal.svg"
                              alt="Paypal"
                            />
                          </Link>
                        </li>
                        <li>
                          <Link to="#">
                            <ImageWithBasePath
                              className="img-fluid"
                              src="assets/img/icons/visa.svg"
                              alt="Visa"
                            />
                          </Link>
                        </li>
                        <li>
                          <Link to="#">
                            <ImageWithBasePath
                              className="img-fluid"
                              src="assets/img/icons/master.svg"
                              alt="Master"
                            />
                          </Link>
                        </li>
                        <li>
                          <Link to="#">
                            <ImageWithBasePath
                              className="img-fluid"
                              src="assets/img/icons/applegpay.svg"
                              alt="applegpay"
                            />
                          </Link>
                        </li>
                      </ul>
                    </div>
                  </div>
                  {/* /Copyright Menu */}
                </div>
              </div>
            </div>
            {/* /Copyright */}
          </div>
        </div>
        {/* /Footer Bottom */}
      </div>
      {/* /Footer */}
    </>
  );
};

export default Footer;
