import React, { useEffect } from "react";
import Breadcrumbs from "../../common/Breadcrumbs";
import AOS from "aos";
import "aos/dist/aos.css";

const Privacypolicy = () => {
  useEffect(() => {
    AOS.init({ duration: 1200, once: true });
  }, []);
  return (
    <div className="main-wrapper">
      <Breadcrumbs title="Política de privacidad" subtitle="Info" />
      <div className="privacy-section">
        <div className="container">
          <div className="row">
            <div className="col-lg-12">
              <div className="terms-policy">
                <p data-aos="fade-down">
                <h1>Política de Privacidad</h1>
                <p>Fecha de vigencia: año 2024</p>
                <p>En Wolf Company, nos tomamos en serio la privacidad de nuestros clientes. Esta política de privacidad describe cómo recopilamos, utilizamos y protegemos la información personal que recopilamos de los usuarios de nuestro sitio web, aplicación móvil y otros servicios. Al utilizar nuestros servicios, usted acepta las prácticas descritas en esta política de privacidad.</p>
 
                <h2>Divulgación de información</h2>
                <p>No vendemos, alquilamos ni compartimos su información personal con terceros, excepto cuando sea necesario para proporcionar nuestros servicios, cumplir con la ley o proteger nuestros derechos.</p>
                </p>
                <p data-aos="fade-down">
                <h2>Información que recopilamos</h2>
                <p>Recopilamos información personal que usted nos proporciona voluntariamente cuando utiliza nuestros servicios, incluidos su nombre, dirección de correo electrónico, número de teléfono, dirección postal y detalles de pago.</p>
                <p>También recopilamos información automáticamente a través del uso de cookies y tecnologías similares cuando visita nuestro sitio web o utiliza nuestra aplicación móvil. Esta información puede incluir su dirección IP, tipo de navegador, sistema operativo, páginas visitadas y actividades realizadas mientras utiliza nuestros servicios.</p>
                .{" "}
                </p>
                <p data-aos="fade-down">
                <h2>Cómo utilizamos la información</h2>
                <p>Utilizamos la información personal que recopilamos para proporcionar y mejorar nuestros servicios, procesar reservas, comunicarnos con usted, personalizar su experiencia y cumplir con nuestras obligaciones legales y regulatorias.</p>
                <p>También podemos utilizar información no personal para fines de análisis y mejora de nuestros servicios.</p>
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Privacypolicy;
